// deprecated - use ch_header_light

define('ch_welcome_overlay',[
    "jquery",
    "jquery.validate.min",
    "ch_form_validation",
    "ch_selectors",
    "ch_modal_window",
    "ch_category_preferences",
], function($, welcomeOverlayGroupEvents) {
    var welcomeOverlayWindowOptions = {
        onSuccess: function(res) {
            CH.showCategoryPreferencesWindow();
            $._('welcome_overlay').each(function(el) {
                var postSubmitText = $(this).data('post-submit-text');
                var postSubmitLink = $(this).data('post-submit-link');
                if (postSubmitText) {
                    $(this).text(postSubmitText);
                }
                if (postSubmitLink) {
                    $(this).attr('href', postSubmitLink);
                    $(this).unbind('click');
                }
            });
        },
        onShow: function() {
            olark('api.box.hide');
            $._('close-welcome-overlay').on('click', function(e) {
                e.preventDefault();
            });
        },
        onHide: function() {
            // reveal a minimized olark chat window
            olark('api.box.shrink');
            CH.setCookie('ch_wo', 1);
        }
    };

    $._('welcome_overlay').click(function(e) {
        if (e) e.preventDefault();

        var ModalWindowWelcomeOverlayObj = new ModalWindowWelcomeOverlay();
        ModalWindowWelcomeOverlayObj.gaEventAction = 'Welcome Overlay';
        ModalWindowWelcomeOverlayObj.show(welcomeOverlayWindowOptions);
    });

    $._('welcome_overlay_nano_bar').click(function(e) {
        if (e) {
            e.preventDefault();
        }

        var ModalWindowWelcomeOverlayObj = new ModalWindowWelcomeOverlay();
        ModalWindowWelcomeOverlayObj.gaEventAction = 'Welcome Overlay - nano bar';
        ModalWindowWelcomeOverlayObj.show(welcomeOverlayWindowOptions);

        ga('send', 'event', 'Email Capture', 'Mobile nanobar', 'click - mobile nanobar', {
            nonInteraction: false,
        });
    });

    if (
        CH.welcomeOverlay &&
        !CH.getCookie('ch_wo') &&
        window.location.hash !== '#wishlist' &&
        !window.location.pathname.match(new RegExp("/referral/.{16}"))
    ) {
        CH.setCookie('ch_wo', 1);

        if (CH.welcomeOverlaySem) {
                CH.isAdWordWelcomeModal = true;
                var ModalWindowWelcomeAdWordOverlayObj = new ModalWindowWelcomeOverlayAdWord();
                ModalWindowWelcomeAdWordOverlayObj.show(welcomeOverlayWindowOptions);
        } else if (CH.isWelcomeOverlayGroupEvents) {
            var ModalWindowWelcomeOverlayGroupEventsObj =
                new ModalWindowWelcomeOverlayGroupEvents();
            ModalWindowWelcomeOverlayGroupEventsObj.show({
                autofocus: false,
                onShow: welcomeOverlayGroupEvents.initialize,
                onHide: function () {
                    welcomeOverlayGroupEvents.destroy();
                }
            });

        } else {
            var ModalWindowWelcomeOverlayObj = new ModalWindowWelcomeOverlay();
            CH.isAdWordWelcomeModal = false;
            ModalWindowWelcomeOverlayObj.show(welcomeOverlayWindowOptions);
        }
    }

    /*
     * registerExitIntentModal
     *
     * Registers an event listener on mouseleave and displays a modal if the user's
     * mouse leaves the viewport
     *
     * This is leftover from the exit_intent_modal_2019 test. It's being left here in case another
     * exit intent modal is needed.
    */
    function registerExitIntentModal() {
        $(document).on('mouseleave', function(evt) {
            if (
                !CH.getCookie('ch_wo') &&
                mouseDidLeaveViewport(evt)
            ) {
                ModalWindowWelcomeOverlayObj.show(welcomeOverlayWindowOptions);
                $(document).off('mouseleave');
            }
        });
    }

    /*
     * mouseDidLeaveViewport
     *
     * Returns true if the user's cursor currently resides outside of the viewport
     * Note: this works in both X & Y directions, postive and negative. This might be overkill.
     *
     * This is leftover from the exit_intent_modal_2019 test. It's being left here in case another
     * exit intent modal is needed.
    */
    function mouseDidLeaveViewport(e) {
        var viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        var viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        var mouseY = e.clientY;
        var mouseX = e.clientX;
        return ( mouseY <= 0 || mouseX <= 0 || mouseY >= viewportHeight || mouseX >= viewportWidth);
    }

    if (window.location.hash === '#interests') {
        var title = "Classes for you in subjects you love!";
        var description = "Choose topics below that inspire you and we'll send you classes you'll love.";

        if (!CH.getCookie('ch_i')) {
            var ModalWindowWelcomeOverlayObj = new ModalWindowWelcomeOverlay();
            ModalWindowWelcomeOverlayObj.show({
                onSuccess: function(res) {
                    CH.showCategoryPreferencesWindow(
                        false,
                        title,
                        description
                    );
                }
            });
        }
        else {
            CH.showCategoryPreferencesWindow(
                false,
                title,
                description
            );
        }
    }
});

