define('ch_form_validation',[
    'jquery',
    'jquery.validate.min',
    'jquery_validator_add',
    'ch_selectors',
    'ch_scrollto',
    'ch_utils',
    'ch_load_mask',
], function ($) {
    var nameRule = {
        required: true,
        humanNameFormat: true,
        rangelength: [2, 128],
    };

    var multipleNamesRule = {
        required: true,
        multipleHumanNameFormat: true,
        // Ensure that the humanNameFormat function is not run
        // when this rule is in effect
        humanNameFormat: false,
        rangelength: [2, 128],
    };

    var emailRule = {
        required: true,
        email: true,
        rangelength: [0, 128]
    };

    var addressRule = {
        required: true,
        addressLineFormat: true,
        rangelength: [2, 100],
    };

    var dateRule = {
        required: true,
        usDateFormat: true,
        date: true,
    };

    var digitsRule = {
        required: true,
        digits: true,
        min: 0,
    };

    var commentRule = {
        maxlength: 500,
    };

    CH.validationRules = {
        // Email
        email: emailRule,
        emergency_email: emailRule,
        emails: {
            required: true,
            multiemail: true,
            rangelength: [0, 500],
        },

        // Password
        code: {
            required: true,
        },
        password: {
            required: true,
        },
        password_new: {
            required: true,
            rangelength: [8, 16],
        },
        password_confirm: {
            required: true,
            rangelength: [8, 16],
            equalTo: '[data-js="password_new"]',
        },

        // Names
        name: nameRule,
        multipleNames: multipleNamesRule,
        from: nameRule,
        fromMultiple: multipleNamesRule,
        first_name: nameRule,
        last_name: nameRule,
        company_name: nameRule,
        reviewer_name: nameRule,
        emergency_name: nameRule,
        school_name: {
            required: true,
            rangelength: [2, 128],
        },

        // Address
        country: {
            required: true,
            notZero: true,
        },
        address1: addressRule,
        address2: Object.assign({}, addressRule, { required: false }),
        city: addressRule,
        state: {
            required: true,
            stateUS: true,
        },
        zip: {
            required: true,
            zipcodeUS: true,
        },
        phone: {
            required: true,
            phoneFormat: true,
        },

        emergency_phone: {
            phoneFormat: true,
            required: true,
        },

        emergency_relation: {
            required: true,
        },

        // Bank Accounts
        account_name: {
            required: true,
            humanNameFormat: true,
        },
        account_number: {
            required: true,
            digits: true,
        },
        tax_id: {
            required: true,
            digits: true,
        },
        account_type: {
            required: true,
            notZero: true,
        },
        routing_number: {
            required: true,
            digits: true,
        },
        check_payable_to: {
            required: true,
            humanNameFormat: true,
        },

        // Dates
        dates: {
            eitherOr: 'any_dates',
            usDateFormat: true,
            date: true,
        },
        delivery_date: dateRule,
        birth_date: dateRule,
        start_date: dateRule,

        // Quantities
        people_attending: digitsRule,
        amount: {
            required: true,
            number: true,
            min: 5,
        },

        // Comments
        review: commentRule,
        message: commentRule,
        description: commentRule,
        special_requests: commentRule,
        review_text: {
            maxlength: 2500,
        },

        // Misc

        number_of_stars: {
            required: true,
            range: [1, 5],
        },
        include_name: {
            required: true,
            range: [0, 1],
        },

        // Enums
        hub: {
            required: true,
            notZero: true,
        },
        budget: {
            required: true,
            notZero: true,
        },
        class_type: {
            required: true,
            notZero: true,
        },
        contact_description: {
            required: true,
            maxlength: 2500,
        },
        type: {
            required: true,
            notZero: true,
        },
        set_amount: {
            required: true,
            notZero: true,
        },
        gender: {
            required: true,
            notZero: true,
        },
        topic: {
            required: true,
            notZero: true,
        },
        payment_status: {
            required: true,
        },
        organization_type: {
            required: true,
        },
        subtopic: {
            required: true,
            notZero: true,
        },
        class_schedule_url: {
            required: true,
        },
    };

    CH.validationMessages = {

    };

    CH.validate = function (elem, options) {
        options = options || {};
        var groupings = {};

        $.each(options.groupings || [], function (group, fields) {
            $.each(fields, function (i, field) {
                groupings[field] = group;
            });
        });

        var defaultOptions = {
            ignore: [],
            onkeyup: false,
            focusInvalid: false,
            onfocusout: function (element) {
                var form = $(element).parents('form');
                if (form.length > 0 && form.is(':visible')) {
                    if (
                        element.tagName === "TEXTAREA" ||
                        (element.tagName === "INPUT" && element.type !== "password")
                    ) {
                        element.value = $.trim(element.value);
                    }
                    this.element(element);
                }
            },

            submitHandler: function (form) {
                $('body').maskElement('');
                form.submit();
            },

            highlight: function (element, errorClass, validClass) {
                // required to select like this to support selectboxit
                // but we no longer use selectboxit so we may change
                var $this = $._($(element).data('js'), $(element).parents('form'));
                if (CH.semantic_form_validation) {
                    $this.parent('.field').addClass(errorClass);
                    $this.parent('.field').removeClass(validClass);
                } else {
                    $this.removeClass(validClass);
                    $this.addClass(errorClass);
                }

                CH.repositionError($this);
            },

            unhighlight: function (element, errorClass, validClass) {
                // required to select like this to support selectboxit
                // but we no longer use selectboxit so we may change
                var $this = $._($(element).data('js'), $(element).parents('form'));
                if (CH.semantic_form_validation) {
                    $this.parent('.field').removeClass(errorClass);
                    $this.parent('.field').addClass(validClass);
                    $this.parent('.field').find('[data-js=' + ($this.data('js') + '-group') + ']').remove();
                } else {
                    $this.removeClass(errorClass);
                    $this.addClass(validClass);
                }
            },

            errorPlacement: function (error, element) {
                CH.repositionError(element, error);
            },

            showErrors: function (errorMap, errorlist) {
                var formName = this.currentForm.id;
                $.each(errorMap, function (field, error) {
                    ga('send', 'event', 'Form Error', formName, field.toHuman() + ': ' + error, {
                        nonInteraction: false,
                    });
                });

                this.defaultShowErrors();
            },

            invalidHandler: function(event, validator) {
                var $firstError;
                if (validator && validator.errorList && validator.errorList.length > 0) {
                    // scroll user to the first invalid field in the form
                    $firstError = $(validator.errorList[0].element);
                    CH.scrollToElement($firstError);
                }
                return false;
            }
        };

        options = options || {};
        elem = elem instanceof jQuery ? elem : $._(elem);

        // The order of the object in extend here is important.
        // The options passed in should override the default option
        // with the exception of rules and messages which should be merged
        var validator = elem.validate(
            $.extend(
                defaultOptions,
                options, {
                    rules: $.extend(true, {}, CH.validationRules, (options.rules || {})),
                    messages: $.extend({}, CH.validationMessages, (options.messages || {})),
                }
            )
        );

        // Validated browser auto filled fields
        var email = $._('email', elem);
        if (email.val()) {
            email.valid();
        }

        return validator;
    };

    CH.repositionError = function (element, error) {
        var name = element.attr('name'),
            errorPlacement = element.parents('form').data('errors'),
            container = $(!CH.isMobile ? (element.parents('.justify')[0] || element.parents('.section')[0] || element.parents('.field')) : (element.parents('.section')[0] || element.parents('.field'))),
            groupName = (container.attr('id') || name) + '-group',
            target = $._(groupName, container),
            label = container.find('label').first();

        container = $(!CH.isMobile ? (element.parents('.section_content')[0] || element.parents('.justify')[0] || element.parents('.section')[0] || element.parents('.field')) : (element.parents('.section')[0] || element.parents('.field')));

        // Create the target
        if (!target.length) {
            if (CH.semantic_form_validation) {
                target = $('<div class="ui basic red label"></div>');
                target.attr('data-js', groupName);
                container.append(target);
            } else {
                target = $('<div class="error_group"></div>');
                target.attr('data-js', groupName);

                // For sign-in form modals on non adele's style pages to keep same error message location
                if (CH.new_style_error_display) {
                    container.append(target);
                } else {
                    label.length && !CH.isMobile ? target.insertAfter(label) : container.append(target);
                }
            }
        }

        error ? target.append(error) : null;
        target.find('label[for="' + name + '"]').show();

        // Mobile always positions errors below the element
        if (CH.isMobile) {
            target.css({
                display:'block',
            });
        } else if (CH.semantic_form_validation) {
            // do nothing
        } else if (errorPlacement == 'right') {
            target.css({
                float: 'right',
                padding: '0 0 0 10px',
            });
        } else if (errorPlacement == 'side' && container.find('input:enabled').length > 0) {
            var lastElement = container.find('input:enabled').last();
            var leftValue = lastElement.position().left + lastElement.outerWidth() + 10;
            var topValue = lastElement.position().top + (lastElement.outerHeight(true) / 2) -
                (target.outerHeight() / 2);
            target.css({
                position:'absolute',
                left: leftValue,
                top: topValue,
            });
        } else if (label.length) {
            container.css({ position:'relative' });
            target.css({
                'margin-left': label.outerWidth() + 3,
                'margin-top': '-22px',
                'min-height': 22,
                position:'relative',
                'text-align': 'right',
                width: container.width() - label.outerWidth() - 3,
            });
        } else {
            container.css({ position:'relative' });
            target.css({
                position:'absolute',
                right: 0,
                top: 0,
            });
        }
    };

    CH.resetForm = function (form) {
        var $this = $(form).validate();
        $this.resetForm();
    };

    $.validator.addMethod('eitherOr', function (value, element, dependentElement) {
        var dependentElement = $('#' + dependentElement);
        if (!dependentElement.length) {
            return true;
        }

        var required = !((dependentElement.attr('type') == 'checkbox')
            ? dependentElement.is(':checked')
            : !!dependentElement.val());

        dependentElement
            .off('click')
            .on('click', function () {
                $(element).valid();
            });

        return !required || !!$(element).val();
    }, 'This field is required');

    $.validator.addMethod('group', function (value, element, arg) {
        return true;
    }, 'This field is required');

    $.validator.addMethod('notZero', function (value, element, params) {
        return (value != 0) && (value != '');
    }, 'This field is required');

    $.validator.addMethod('phoneFormat', function (value, element) {
        var copy = value.replace(/[^0-9]/g, '').trim();
        return value.trim().length == 0 || /^\d{10,15}?$/.test(copy);
    }, 'This phone number format is invalid');

    $.validator.addMethod('usDateFormat', function (value, element) {
        return /^\_{2}\u002F\_{2}\u002F\_{4}?$/.test(value) || /^\d{2}\u002F\d{2}\u002F\d{4}?$/.test(value);
    }, 'This date format is invalid');

    $.validator.addMethod('humanNameFormat', function (value, element) {
        var re = new RegExp("^[-.\'a-z0-9 ]+$", 'i');
        return this.optional(element) || re.test(value);
    }, function (params, element) {

        return element.name.toHuman() + ' contains invalid characters';
    });

    $.validator.addMethod('multipleHumanNameFormat', function (value, element) {
        var re = new RegExp("^[-.\'a-z0-9 ,&\(\)]+$", 'i');
        return this.optional(element) || re.test(value);
    }, function (params, element) {

        return element.name.toHuman() + ' contains invalid characters';
    });

    $.validator.addMethod('addressLineFormat', function (value, element) {
        var re = new RegExp("^[-.,;:\'a-z0-9 ]+$", 'i');
        return this.optional(element) || re.test(value);
    }, function (params, element) {

        return element.name.toHuman() + ' contains invalid characters';
    });

    $.validator.addMethod('multiemail', function (value, element) {
        if (this.optional(element)) return true; // return true on optional element

        var emails = value.trim().split(new RegExp('\\s*[,\\n]\\s*', 'gi'));
        var valid = true;
        for (var i in emails) {
            value = emails[i];
            valid = valid && $.validator.methods.email.call(this, value, element);
        }

        return valid;
    }, 'The format for one or more emails is invalid');

    $.validator.addMethod('uniqueStudentEmail', function (value, currentElement) {
        var studentEmailRegExp = new RegExp('^student_email_[0-9]{1,}$');
        var result = true;
        $('input').each(function (id, element) {
            if (currentElement != element && value == element.value && studentEmailRegExp.test($(element).attr('name'))) {
                result = false;
            }
        });

        return result;
    }, 'Student email should be unique');

    $.validator.addMethod('stateUS', function (value, element, options) {
        var isDefault = typeof options === 'undefined',
            caseSensitive = (isDefault || typeof options.caseSensitive === 'undefined') ? false : options.caseSensitive,
            includeTerritories = (isDefault || typeof options.includeTerritories === 'undefined') ? false : options.includeTerritories,
            includeMilitary = (isDefault || typeof options.includeMilitary === 'undefined') ? false : options.includeMilitary,
            regex;

        if (!includeTerritories && !includeMilitary) {
            regex = '^(A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|PA|RI|S[CD]|T[NX]|UT|V[AT]|W[AIVY])$';
        } else if (includeTerritories && includeMilitary) {
            regex = '^(A[AEKLPRSZ]|C[AOT]|D[CE]|FL|G[AU]|HI|I[ADLN]|K[SY]|LA|M[ADEINOPST]|N[CDEHJMVY]|O[HKR]|P[AR]|RI|S[CD]|T[NX]|UT|V[AIT]|W[AIVY])$';
        } else if (includeTerritories) {
            regex = '^(A[KLRSZ]|C[AOT]|D[CE]|FL|G[AU]|HI|I[ADLN]|K[SY]|LA|M[ADEINOPST]|N[CDEHJMVY]|O[HKR]|P[AR]|RI|S[CD]|T[NX]|UT|V[AIT]|W[AIVY])$';
        } else {
            regex = '^(A[AEKLPRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|PA|RI|S[CD]|T[NX]|UT|V[AT]|W[AIVY])$';
        }

        regex = caseSensitive ? new RegExp(regex) : new RegExp(regex, 'i');
        return this.optional(element) || regex.test(value);
    }, 'Please select a valid state');

    // Validation method for US currency
    $.validator.addMethod("currency", function (value, element) {
        return this.optional(element) || /^(\d{1,3}(\,\d{3})*|(\d+))(\.\d{1,2})?$/.test(value);
    }, "Please specify a valid amount");
});


