/**
 * ch_account_page
 *
 * A temporary module for bundling up shared "account page" functionality
 *
 * These are old/junky things and shouldn't be relied upon for long...
 */
define('ch_account_page',['jquery'], function ($) {
    return {
        initPaginatedTables: function () {
            var $currentPage = 0;
            var $tables = $('#paginated-list .js-pages');
            var $paginator = $('#pagination-container .item').not('.icon');
            var $paginatorUpDownNavigation = $('#pagination-container .icon.item');

            $('#pagination-container .item').unbind('click').on('click', function () {
                var newActiveItemNumber;
                var pageNumber = $(this).data('value');

                switch (pageNumber) {
                case 'up':
                    newActiveItemNumber = $currentPage + 1;
                    break;
                case 'down':
                    newActiveItemNumber = $currentPage - 1;
                    break;
                default:
                    newActiveItemNumber = parseInt(pageNumber);
                    break;
                }

                var $newActiveTable = $tables[newActiveItemNumber];
                var $newActivePaginatorItem = $paginator[newActiveItemNumber];

                if ($newActiveTable && $newActivePaginatorItem) {
                    // Update table
                    $tables.hide();
                    $($newActiveTable).show();

                    // Update paginator
                    $paginator.removeClass('active');
                    $($newActivePaginatorItem).addClass('active');

                    // Update current page number
                    $currentPage = newActiveItemNumber;

                    // Update navigation icons
                    $paginatorUpDownNavigation.show();
                    if ($currentPage === 0) {
                        $($paginatorUpDownNavigation[0]).hide();
                    } else if ($currentPage === ($paginator.length - 1)) {
                        $($paginatorUpDownNavigation[1]).hide();
                    }
                }
            });
        },
    };
});

