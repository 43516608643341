require(['ch_search'], function () {
    const searchForm = document.getElementById('search-form');
    const hubSelectors = Array.from(document.querySelectorAll('.js-hub-select'));
    const searchInput = document.querySelector('.js-search-by-keyword input[type=text]');

    let lastHubSelected = CH.Hub.uri;

    if (hubSelectors.length) {
        hubSelectors.forEach(function (hubSelector) {
            hubSelector.addEventListener('change', onChange);
            hubSelector.addEventListener('click', onClick);
        });
    }

    function onChange() {
        const hub = this.value.toLowerCase();

        window.Filters.hub = hub;
        window.Filters.kw = searchInput && searchInput.value
            ? encodeURIComponent(searchInput.value)
            : null;

        ga('send', 'event', 'Hub Selection', 'Hub Select Box Changed', 'Hub changed - ' + window.Filters.hub);

        Filters.suggest = null;

        // make it so search form submission respects the newly selected hub
        if (searchForm) {
            searchForm.action = searchForm.action.replace(lastHubSelected, hub);
        }
        lastHubSelected = hub;

        // when going to "all" classes...
        if (hub === 'all') {
            if (window.Filters.category) {
                window.location.href = '/' + hub + '/classes/' + window.Filters.category;
            } else {
                window.location.href = '/' + hub + '/classes';
            }
            return;
        }

        // when leaving "all" classes
        if (window.location.pathname.indexOf('/all/') === 0) {
            window.location.href = window.location.href.replace('/all/', `/${hub}/`);
            return;
        }

        if (!window.Filters.kw) {
            // If you're not on a hub-specific page (eg: /contact-us), go to the hub page
            if (window.location.pathname.indexOf('/' + CH.Hub.uri + '/') === -1) {
                window.location.href = '/' + hub;
            } else if (CH.is_course_page) {
                window.location.href = '/' + window.Filters.hub + '/classes';
            } else if (CH.Hub.uri) {
                window.location.href = window.location.href.replace(CH.Hub.uri, hub);
            } else {
                window.location.href = '/' + hub;
            }
        }
    }

    function onClick() {
        ga('send', 'event', 'Hub Selection', 'Hub Select Box Clicked', 'Hub selection box clicked');
    }
});

define("ch_semantic_search", function(){});

