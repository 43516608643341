define('ch_menu',[
    'jquery',
    'ch_popup',
    'ch_modal_window',
    'ch_category_preferences',
], function ($) {
    if (CH.requireLogin) {
        var ModalWindowLoginObj = new ModalWindowLogin();
        ModalWindowLoginObj.show({
            onHide: function() {
                CH.requireLogin = false;
            },
            onShow: function() {
                if (CH.isGuest) {
                    $('.window_error', '#' + ModalWindowLoginObj.id)
                        .html('You already have an account with us but you need to set a password. ' +
                            'We just sent you an email with instructions for how to do that.')
                        .show();
                }
            }
        });
    } else if (
        CH.requireRegister ||
        (window.location.hash == '#register-window-show' && !CH.getCookie('ch_i',  { path: '/' }))
    ) {
        var ModalWindowRegisterObj = new ModalWindowRegister();
        ModalWindowRegisterObj.show({
            onHide: function() {
                CH.requireRegister = false;
            }
        });
    } else if (
        CH.requireWishlistSignup ||
        (
            window.location.hash === '#wishlist' &&
            !CH.is_course_page &&
            !CH.getCookie('ch_i',  { path: '/' })
        )
    ) {
        var ModalWindowWishlistSignupObj = new ModalWindowWishlistSignup({
            linkedInstanceOptions: {
                onSuccess: function() {
                    location.reload();
                }
            }
        });
        ModalWindowWishlistSignupObj.show({
            onSuccess: function() {
                $("body").maskElement(''); // Don't know why we weren't already, but should totally show loading...
                CH.showCategoryPreferencesWindow(true);
            },
            location: '/wishlist',
            onHide: function() {
                CH.requireWishlistSignup = false;
            }
        });
    }


    $('.js-register').click(function (e) {
        if (e) e.preventDefault();
        var ModalWindowRegisterObj = new ModalWindowRegister();
        ModalWindowRegisterObj.show();
    });

    $('.js-wishlist').click(function (e) {
        if (!CH.getCookie('ch_i',  { path: '/' })) {
            if (e) e.preventDefault();
            var ModalWindowWishlistSignupObj = new ModalWindowWishlistSignup({
                linkedInstanceOptions: {
                    onSuccess: function() {
                        location.reload();
                    }
                }
            });
            ModalWindowWishlistSignupObj.show({
                onShow: function () {
                    $('.modal-title', '#' + this.id).html('Create your wishlist');
                },
                onSuccess: function() {
                    $("body").maskElement(''); // Don't know why we weren't already, but should totally show loading...
                    CH.showCategoryPreferencesWindow(true);
                },
                onHide: function() {
                    $('.modal-title', '#' + this.id).html('Save this class to your wishlist.');
                },
                location: $(e.target).parent().attr('data-location'),
            });
        }
    });
});

