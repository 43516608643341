/**
 * sitewide js services
 *
 * - load all critical, global dependencies
 * - extend jquery
 */
define('services',[
    "jquery",
    "ch_utils",

    // Remove bugsnag for now
    // "ch_bugsnag",
    "ch_selectors",
], function($) {

    $.urlParamIsSet = function(name) {
        var result = new RegExp('[\\?&]' + name + '[=^&#]*').exec(window.location.href);
        return result ? true : false;
    };

    $.urlParam = function(name) {
        var results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(window.location.href);
        if (results == null) {
            return null;
        }
        else {
            return decodeURIComponent(results[1]) || 0;
        }
    };

    // when enabled and available, have Bugsnag track jQuery Ajax errors
    if (typeof bugsnagClient !== 'undefined') {
        $(document).ajaxError(function(event, jqxhr, settings, thrownError) {
            bugsnagClient.metaData = {
                'ajax info': {
                    event: event,
                    settings: settings
                },
            };
            bugsnagClient.notify(thrownError);
        });
    }

    // $.ajaxSetup({
    //     crossDomain: false
    // });

    /**
     * onWindowLoad
     *
     * helper function to run things on window load (not document ready) event
     * ie: once all images + styles are downloaded and computed
     *
     * Useful for when you need to do js "on load" but after elements have height, padding computed
     *
     * This handler will run once - in ios/safari it will not be run consistently via .on() (unlike the doc ready handlers)
     * So we check readyState and immediately execute if window load has already happened
     *
     * @param fn
     */
    window.onWindowLoad = function(fn) {
        if (document.readyState === 'complete') {
            fn();
        } else {
            $(window).on('load', function () {
                fn();
            });
        }
    }


    // Conditionally load Sailthru SDK
    // if (CH.Sailthru) {
    //     require(["ch_sailthru"]);
    // }
    //
    // $(function() {
    //     var $images = $('.lazy-load-img');
    //
    //     if ($images.length > 0) {
    //         $images.unveil(200, function() {
    //             $(this).on('load', function() {
    //                 $(this).addClass('lazy-loaded');
    //             });
    //         });
    //     }
    // });

    //
    // $._('made-in-nyc').click(function(e) {
    //     e.preventDefault();
    //
    //     if (CH.getCookie("admindebug") == "1") {
    //         CH.setCookie("admindebug", "0", "session");
    //         $('.admindebug').hide();
    //     } else {
    //         CH.setCookie("admindebug", "1", "session");
    //         $('.admindebug').show();
    //     }
    // });
    //
    // if (CH.getCookie("admindebug") == "1") {
    //    $('.admindebug').show();
    // }
});

