define('ch_footer',[
    'jquery',
    'jquery.validate.min',
    'ch_form_validation',
    'ch_modal_window',
    'ch_category_preferences',
    'ch_load_mask',
], function ($) {
    var $emailCaptureInFooter;

    // @todo - multiple pages use this, we should move it into a global lib
    CH.thankYouSubmitEmail = function () {
        swal('Thank you', 'Check your email for exclusive offers!', 'success');
    };

    // @todo
    // get the footer form
    // on submit...
    // validate it is an email
    // then.. open category pref modal

    // newsletter email capture
    CH.initFooterEmailForm = () => {
        $emailCaptureInFooter = $('[data-js=form_CaptureEmail_footer]');

        $emailCaptureInFooter.each(function () {
            CH.validate($(this), {
                submitHandler: function (form) {
                    $(form).find('input').blur();
                    $('body').maskElement('');


                    fetch(form.action, {
                        method: 'POST',
                        body: new FormData(form),
                        headers: {
                            'Accept': 'application/json',
                        },
                    }).then((response) => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            throw new Error('response not ok')
                        }
                    }).then(function (data) {
                        // handle success
                        $('body').unmaskElement('');
                        if (!data.success && data.error) {
                            new ModalMessage({ message: data.error });
                            return;
                        }

                        // Send GA Event
                        ga('send', 'event', 'Email Capture', 'Subscribe', $('.js-funnel').val());

                        $emailCaptureInFooter.each(function (index, form) {
                            $(form).get(0).reset();
                        });
                        CH.thankYouSubmitEmail();
                    }).catch(function (error) {
                        // handle error
                        console.log(error);
                        alert('Sorry, an error occurred.');
                    });
                },
            });
        });
    }


    CH.captureEmailSubmit = (token) => {
        document.getElementById('g_response').value = token;
        CH.initFooterEmailForm();
        document.querySelector('[data-js=form_CaptureEmail_footer]').requestSubmit();
    };
    window.captureEmailSubmit = (token) => CH.captureEmailSubmit(token);

});

