/**
 * jQuery plugin for creation popups like tooltips, windows and modal windows
 *
 * @param options
 */

define('ch_popup',["jquery"], function ($) {
    // Options for ToolTip
    var options_tooltip = {};
    var options_tooltip_default = {
        // Content options
        title: '',
        content: '',
        closeButton: true,
        closeByClickOutside: false,
        closeByMouseout: false,
        attribute: 'title',
        cssClass: '',
        hoverDelayTimeout: 500,

        // Behavior
        on: "hover",
        keepAlive: false,
        keepPopupHover: true,
        fadeIn: 200,
        fadeOut: 200,
        single: false,

        // Positioning
        maxWidth: '350px',
        position: 'bottom',
        showCorner: true,
        cornerLeftOffset: 60,
        cornerTopOffset: 0,
        tooltipTopOffset: 3,
        tooltipLeftOffset: 0,
        modal: false,

        // Functions on open and close
        enter: function() {},
        afterRender: function() {},
        exit: function() {},

        // Tooltip template
        tpl: '<div class="tooltip-container">' +
                '<div class="tooltip-content-holder-01">' +
                    '<div class="tooltip-content-holder-02">' +
                        '<div class="tooltip-corner-above"></div>' +
                            '<div class="button-close" data-js="button-close">&nbsp;</div>' +
                            '<div class="content-title"></div>' +
                        '<div class="content-body"></div>' +
                    '</div>' +
                '</div>' +
            '</div>'
    };

    // Options for window
    var options_window = {};
    var options_window_default = {
        // Content options
        title: '',
        content: '',
        closeButton: true,
        attribute: 'title',
        cssClass: '',

        // Behavior
        on: "click",
        keepAlive: true,
        fadeIn: 200,
        fadeOut: 200,

        // Positioning
        maxWidth: '600px',
        modal: true,

        // Functions on open and close
        enter: function() {},
        afterRender: function() {},
        exit: function() {},

        // Tooltip template
        tpl: '<div class="window-container">' +
                '<div class="window-content-holder-01">' +
                    '<div class="window-content-holder-02">' +
                        '<div class="button-close" data-js="button-close">&nbsp;</div>' +
                            '<div class="content-title"></div>' +
                        '<div class="content-body"></div>' +
                    '</div>' +
                '</div>' +
            '</div>'
    };

    var methods = {
        // Tooltip method
        tooltip: function(options) {
            var this_options = {};

            if (options) {
                $.extend(this_options, options_tooltip_default, options);
            }
            else {
                $.extend(this_options, options_tooltip_default);
            }

            if (options_tooltip_default.tooltipTopOffset == this_options.tooltipTopOffset &&
                (this_options.position == 'left' || this_options.position == 'right')) {
                this_options.tooltipTopOffset = 0;
            }

            this_options.tpl_default = this_options.tpl;

            return this.each(function() {
                var tooltip_id = "popup-tooltip-container-" + Math.ceil(Math.random() * 10000);

                this_options.tpl = '<div id="' + tooltip_id + '" class="popup-common popup-tooltip-container ' + this_options.cssClass + '">' + this_options.tpl_default + '</div>';

                var target_element = $(this);
                var tooltip_container = $('#' + tooltip_id);

                if (this_options.cssClass != '') {
                    tooltip_container.addClass(this_options.cssClass);
                }

                if (!this_options.content) {
                    this_options.content = target_element.attr(this_options.attribute);

                    if (this_options.content != '') {
                        target_element.removeAttr(this_options.attribute);
                    }
                }

                if (this_options.content != '') {
                    target_element.attr('popup_id', tooltip_id);
                    $('body').append(this_options.tpl);

                    var this_popup = $('#' + tooltip_id);

                    options_tooltip[tooltip_id] = this_options;

                    // On Hover
                    if (this_options.on == "hover") {
                        var hovered = false;
                        var intervalID = 0;
                        target_element.hover(
                            function() {
                                var current = $(this);
                                intervalID = setTimeout(
                                    function() {
                                        current.popUp().show_tooltip();
                                    }, this_options.hoverDelayTimeout);
                            },
                            function () {
                                clearInterval(intervalID);
                                var current = $(this);
                                if (!this_options.keepAlive) {
                                    setTimeout(function() {
                                        if (!hovered) {
                                            current.popUp().hide_tooltip();
                                        }
                                    }, 200);
                                }

                                if (this_options.closeByMouseout) {
                                    var popup_id = current.attr('popup_id');
                                    $('#' + popup_id + ' .tooltip-container').mouseleave(function(e) {
                                        current.popUp().hide_tooltip();
                                    });
                                }
                                if (this_options.closeByClickOutside) {
                                    $(document).click(function(e) {
                                        var tooltip = $('#' + popup_id + ' .tooltip-container');
                                        if (!tooltip.is(e.target) && tooltip.has(e.target).length == 0) {
                                            current.popUp().hide_tooltip();
                                        }
                                    });
                                }
                            }
                        );
                        if (this_options.keepAlive) {
                            this_popup.hover(function() {
                            }, function() {
                                $(this).popUp().hide_tooltip();
                            });
                        }
                        else {
                            this_popup.hover(function() {
                                hovered = true;
                            }, function() {
                                hovered = false;
                            });
                        }

                        // OnFocus
                    }
                    else if (this_options.on == "focus") {
                        target_element.focus(
                            function() {
                                $(this).popUp().show_tooltip();
                            }).blur(function() {
                                $(this).popUp().hide_tooltip();
                            });

                        // OnClick
                    }
                    else if (this_options.on == "click") {
                        target_element.click(function(event) {
                            event.preventDefault();
                            var current = $(this);
                            current.popUp().show_tooltip();

                            if (this_options.closeByClickOutside) {
                                $(document).click(function(e) {
                                    var tooltip = $('#' + tooltip_id + ' .tooltip-container');
                                    if (!tooltip.is(e.target) && tooltip.has(e.target).length == 0) {
                                        current.popUp().hide_tooltip();
                                    }
                                });
                                current.click(function(e) {
                                    e.stopPropagation();
                                });
                            }

                            return false;
                        }).hover(function() {
                        }, function() {
                            var current = $(this);
                            if (!this_options.keepAlive) {
                                current.popUp().hide_tooltip();
                            }
                        });
                    }
                }
            });
        },

        // Window method
        window: function(options) {
            var this_options = {};

            if (options) {
                $.extend(this_options, options_window_default, options);
            }
            else {
                $.extend(this_options, options_window_default);
            }

            this_options.tpl_default = this_options.tpl;

            return this.each(function() {
                var window_id = "popup-window-container-" + Math.ceil(Math.random() * 10000);
                this_options.tpl = '<div id="' + window_id + '" class="popup-common popup-window-container ' + this_options.cssClass + '">' + this_options.tpl_default + '</div>';

                if (this_options.modal) {
                    this_options.tpl = '<div class="popup-window-modal-background"></div>' + this_options.tpl;
                }

                var target_element = $(this);
                var window_container = $('#' + window_id);

                if (this_options.cssClass != '') {
                    window_container.addClass(this_options.cssClass);
                }

                if (!this_options.content) {
                    this_options.content = target_element.attr(this_options.attribute);

                    if (this_options.content != '') {
                        target_element.removeAttr(this_options.attribute);
                    }
                }

                if (this_options.content != '') {
                    target_element.attr('popup_id', window_id);
                    $('body').append(this_options.tpl);

                    var this_window = $('#' + window_id);

                    options_window[window_id] = this_options;

                    // On Hover
                    if (this_options.on == "hover") {
                        target_element.hover(function() {
                            $(this).popUp().show_window();
                        }, function() {
                            if (!this_options.keepAlive) {
                                $(this).popUp().hide_window();
                            }
                        });

                        if (this_options.keepAlive) {
                            this_window.hover(function() {
                            }, function() {
                                $(this).popUp().hide_window();
                            });
                        }
                    }
                    // OnFocus
                    else if (this_options.on == "focus") {
                        target_element.focus(
                            function() {
                                $(this).popUp().show_window();
                            }).blur(function() {
                                $(this).popUp().hide_window();
                            });
                    } // OnClick
                    else if (this_options.on == "click") {
                        target_element.click(
                            function(event) {
                                event.preventDefault();
                                $(this).popUp().show_window();
                                return false;
                            }).hover(function() {
                            }, function() {
                                if (!this_options.keepAlive) {
                                    $(this).popUp().hide_window();
                                }
                            });
                    }
                }
            });
        },

        // Service method for operations
        operations: function() {
            var target_element = $(this);
            return {
                // For Modal
                show_modal: function() {
                    if (!$('div').is('.popup-modal-overall-background')) {
                        $('body').append('<div class="popup-modal-overall-background"></div>');
                    }

                    $('.popup-modal-overall-background').show();
                },

                hide_modal: function() {
                    $('.popup-modal-overall-background').hide();
                },

                // For ToolTip
                show_tooltip: function() {
                    var tooltip_id = target_element.attr('popup_id');
                    if (tooltip_id != '') {
                        var this_options = options_tooltip[tooltip_id];
                        this_options.enter(target_element);

                        if (this_options.single) {
                            $('.popup-tooltip-container').hide();
                        }

                        var this_popup = $('#' + tooltip_id);
                        this_popup.removeAttr("style");

                        var this_popup_close = $('#' + tooltip_id + ' .button-close');
                        var this_popup_title = $('#' + tooltip_id + ' .content-title');
                        var this_popup_content = $('#' + tooltip_id + ' .content-body');

                        this_popup_close.attr('popup_id', tooltip_id);
                        if (this_options.closeButton) {
                            this_popup_close.show();
                        }
                        this_popup_close.click(function(event) {
                            event.preventDefault();
                            $(this).popUp().hide_tooltip();
                            return false;
                        });

                        if (this_options.title != '') {
                            this_popup_title.html(this_options.title);
                            this_popup_title.show();
                        }
                        else {
                            this_popup_title.hide();
                        }

                        this_popup_content.html(this_options.content);

                        if (this_options.modal) {
                            $(this).popUp().show_modal();
                        }

                        if (this_options.position == 'center') {
                            $("body").addClass("popup-open");
                            this_popup.wrap("<div class='popup-modal-outer-wrapper'></div>");

                            var keepOnScreen = function(el) {
                                if ($(window).height() < el.outerHeight()) {
                                    el.css({
                                        'top': 0,
                                        'margin-top': 0
                                    });
                                }
                                else {
                                    el.css({
                                        'top': '',
                                        'margin-top': ''
                                    });
                                }
                            };

                            keepOnScreen(this_popup);
                            $(window).resize(function() {
                                keepOnScreen(this_popup);
                            });

                            $(document).keyup(function(event) {
                                if (event.which == 27) {
                                    event.preventDefault();
                                    target_element.popUp().hide_tooltip();
                                    return false;
                                }
                            });
                        }

                        this_popup.fadeIn(this_options.fadeIn);

                        this.calculate_tooltip_position();
                        this_options.afterRender(target_element, this);
                    }
                },

                hide_tooltip: function() {
                    var tooltip_id = target_element.attr('popup_id');
                    if (tooltip_id != undefined) {
                        var this_options = options_tooltip[tooltip_id];
                        this_options.exit(target_element);
                        if (this_options.modal) {
                            $(this).popUp().hide_modal();
                        }

                        var this_popup = $('#' + tooltip_id);
                        this_popup.fadeOut(this_options.fadeOut);

                        if (this_options.position == 'center') {
                            $("body").removeClass("popup-open");
                            this_popup.unwrap();
                        }
                    }
                },

                calculate_tooltip_position: function() {
                    var tooltip_id = target_element.attr('popup_id');
                    var this_options = options_tooltip[tooltip_id];
                    if (tooltip_id != '') {
                        var this_popup = $('#' + tooltip_id);
                        this_popup.css('max-width', this_options.maxWidth);

                        var this_popup_min_width = this_popup.css('min-width');
                        if (this_popup_min_width != undefined) {
                            this_popup_min_width = this_popup_min_width.replace('px', '');
                            if (this_options.maxWidth < this_popup_min_width) {
                                this_popup.css('min-width', this_options.maxWidth);
                            }
                        }

                        var this_popup_corner = $('#' + tooltip_id + ' .tooltip-corner-above');
                        var this_popup_holder_01 = $('#' + tooltip_id + ' .tooltip-content-holder-01');

                        if (this_options.showCorner == false) {
                            this_popup_corner.hide();
                        }

                        if (this_options.position == 'bottom') {
                            if (this_options.showCorner == true) {
                                this_popup_holder_01.addClass('tooltip-content-holder-01-bottom');
                            }
                            else {
                                this_popup_holder_01.addClass('tooltip-content-holder-01-bottom-nocorner');
                            }

                            this_popup_corner.addClass('tooltip-corner-above-bottom');

                            var target_left_center_coord = Math.ceil(target_element.offset().left + target_element.width() / 2);

                            var top_coord;
                            var left_coord;
                            if (this_options.showCorner == true) {
                                top_coord = target_element.offset().top + target_element.height() - this_options.tooltipTopOffset;
                            }
                            else {
                                top_coord = target_element.offset().top + target_element.height() + this_options.tooltipTopOffset;
                            }
                            left_coord = Math.ceil(target_left_center_coord - this_popup.width() / 2 + this_options.cornerLeftOffset) - this_options.tooltipLeftOffset;

                            var corner_offset = Math.ceil($('#' + tooltip_id).width() / 2 - this_popup_corner.width() / 2 - this_options.cornerLeftOffset);

                            this_popup_corner.css('margin-left', corner_offset + 'px');

                            this_popup.css('top', top_coord + 'px');
                            this_popup.css('left', left_coord + 'px');
                        }
                        else if (this_options.position == 'left') {
                            this_popup_corner.addClass('tooltip-corner-above-left');
                            this_popup_holder_01.addClass('tooltip-content-holder-01-left');

                            if (!this_popup_corner.attr('increased') == 1) {
                                this_popup_corner.attr('increased', 1);
                                this_popup_corner.css('margin-top', this_options.tooltipTopOffset + 'px');
                                this_popup_corner.css('margin-bottom', (this_popup_corner.css('margin-bottom').replace('px', '') - this_options.tooltipTopOffset) + 'px');
                            }

                            top_coord = Math.ceil(target_element.offset().top - this_popup_corner.height() / 2 - (this_popup_corner.offset().top - this_options.tooltipTopOffset) + target_element.height() / 2 - this_options.tooltipTopOffset);
                            left_coord = target_element.offset().left - this_popup.width() - this_popup_corner.width() - this_options.tooltipLeftOffset;

                            var corner_left_margin = Math.ceil($('#' + tooltip_id + ' .tooltip-content-holder-02').width() + ($('#' + tooltip_id + ' .tooltip-content-holder-02').outerWidth() - $('#' + tooltip_id + ' .tooltip-content-holder-02').width()) / 2 - $('#' + tooltip_id + ' .tooltip-content-holder-02').css("border-left-width").replace('px', ''));
                            this_popup_corner.css('margin-left', corner_left_margin + 'px');

                            this_popup.css('top', top_coord + 'px');
                            this_popup.css('left', left_coord + 'px');
                        }
                        else if (this_options.position == 'center') {
                            this_popup_corner.addClass('tooltip-corner-above-center');
                            this_popup_holder_01.addClass('tooltip-content-holder-01-center');
                        }
                        else if (this_options.position == 'right') {
                            this_popup_corner.addClass('tooltip-corner-above-right');
                            this_popup_holder_01.addClass('tooltip-content-holder-01-right');

                            if (!this_popup_corner.attr('increased') == 1) {
                                this_popup_corner.attr('increased', 1);
                                this_popup_corner.css('margin-top', this_options.tooltipTopOffset + 'px');
                                this_popup_corner.css('margin-bottom', (this_popup_corner.css('margin-bottom').replace('px', '') - this_options.tooltipTopOffset) + 'px');
                            }

                            top_coord = Math.ceil(target_element.offset().top - this_popup_corner.height() / 2 - (this_popup_corner.offset().top - this_options.tooltipTopOffset) + target_element.height() / 2 - this_options.tooltipTopOffset);
                            left_coord = target_element.offset().left + target_element.width() + this_popup_corner.width() + this_options.tooltipLeftOffset;

                            this_popup.css('top', top_coord + 'px');
                            this_popup.css('left', left_coord + 'px');
                        }
                    }
                },

                // For Window
                show_window: function() {
                    var window_id = target_element.attr('popup_id');
                    var this_options = options_window[window_id];
                    if (window_id != '') {
                        this_options.enter(target_element);
                        var this_popup = $('#' + window_id);
                        this_popup.removeAttr("style");

                        var this_popup_close = $('#' + window_id + ' .button-close');
                        var this_popup_title = $('#' + window_id + ' .content-title');
                        var this_popup_content = $('#' + window_id + ' .content-body');

                        this_popup_close.attr('popup_id', window_id);
                        if (this_options.closeButton) {
                            this_popup_close.show();
                        }
                        this_popup_close.click(function(event) {
                            event.preventDefault();
                            $(this).popUp().hide_window();
                            return false;
                        });

                        if (this_options.title != '') {
                            this_popup_title.html(this_options.title);
                            this_popup_title.show();
                        }
                        else {
                            this_popup_title.hide();
                        }

                        this_popup_content.html(this_options.content);

                        if (this_options.modal) {
                            $(this).popUp().show_modal();
                        }

                        this_popup.fadeIn(this_options.fadeIn);

                        this.calculate_window_position();
                        this_options.afterRender(target_element, this);
                        this.calculate_window_position();
                    }
                },

                hide_window: function() {
                    var window_id = target_element.attr('popup_id');
                    var this_options = options_window[window_id];
                    if (window_id != '') {
                        this_options.exit(target_element);
                        if (this_options.modal) {
                            $(this).popUp().hide_modal();
                        }
                        var this_popup = $('#' + window_id);
                        this_popup.fadeOut(this_options.fadeOut);
                    }
                },

                calculate_window_position: function() {
                    var window_id = target_element.attr('popup_id');
                    var this_options = options_window[window_id];
                    if (window_id != '') {
                        var this_popup = $('#' + window_id);
                        this_popup.css('max-width', this_options.maxWidth);

                        var top_coord = Math.ceil(($(window).height() - this_popup.height()) / 2);
                        var left_coord = Math.ceil(($(window).width() - this_popup.width()) / 2);

                        this_popup.css('top', top_coord + 'px');
                        this_popup.css('left', left_coord + 'px');
                    }
                }
            };
        }
    };

    $.fn.popUp = function(options) {
        if (options == undefined) options = {};
        if (options.type == undefined) options.type = 'operations';

        if (methods[options.type]) {
            return methods[ options.type ].apply(this, arguments);
        }
        else {
            $.error('Unknown popup type "' + options.type + '" in jQuery.popUp');
        }
    };
});

