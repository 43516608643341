define('ch_welcome_overlay_group_events',[
    'jquery',
    'semantic'
], function ($) {
    let $modal = null;
    let $form = null;
    let $thankYouSmallGroup = null;
    let $thankYouConciergeRequest = null;
    let steps = [];
    let isSubmitted = false;

    const Step = function (id, $element) {
        this.$element = $element;
        this.id = id;
        this.isInitialized = false;
    };

    Step.prototype = {
        show: function () {
            this.$element.show();
        },
        hide: function () {
            this.$element.hide();
        },
    };

    /**
     * showThankYou
     *
     * Reveal a given thank you message element and display for
     * 3 seconds
     *
     * @param jQuerySelector $thankYouMessage - The thank you message element to reveal
     */
    function showThankYou($thankYouMessage) {
        hideAllSteps();

        $thankYouMessage.show();

        setTimeout(function () {
            $modal.modal('hide');
        }, 3000);
    }

    /**
     * hideAllSteps
     *
     * Hides all step elements within this modal
     */
    function hideAllSteps() {
        steps.forEach(function (step) {
            step.hide();
        });
    }


    /**
     * postData
     *
     * Posts the current state of the form to the server and then executes
     * any given callbacks.
     *
     * @param function doneCallback - The callback to execute when the request is done
     * @param function alwaysCallback - The callback to always execute, regardles of outcome
     *
     */
    function postData(doneCallback, alwaysCallback) {
        if (isSubmitted) {
            return;
        }

        const formData = $form.form('get values');

        $.ajax('/user/subscription/welcome-overlay-group-events-post', {
            method: 'POST',
            data: {
                group_size: formData.group_size,
                event_type: formData.event_type,
                event_frequency: formData.event_frequency,
                email: formData.email,
            },
        }).done(function () {
            isSubmitted = true;
            if (doneCallback) {
                doneCallback();
            }
        }).always(function () {
            if (alwaysCallback) {
                alwaysCallback();
            }
        });
    }

    /**
     * setCookieAndPostDataBeforeUnload
     *
     * An event handler intended for use on the beforeunload event.
     * This is registered to make sure we capture form data from users you are presented
     * the modal and leave the page before completing the form.
     *
     * We prevent the default event behavior because handling this event can cause browsers
     * to prompt the user to not leave the page because they have unsaved changes.
     *
     */
    function setCookieAndPostDataBeforeUnload(event) {
        event.preventDefault();
        setCookieAndPostData();
    }


    /**
     * setCookieAndPostData
     *
     * Sets the correct cookie to prevent the user from seeing this modal on a return visits
     * And saves the form data to the server, with no other effects.
     */
    function setCookieAndPostData() {
        CH.setCookie('ch_wo', 1);
        postData();
    }

    /**
     * submitForm
     *
     * Submits the form and displays a thank you message to the user, assumes the scenario
     * that the user requested concierge.
     * Also submits correct ga event.
     */
    function submitForm(event) {
        event.stopImmediatePropagation();
        event.preventDefault();

        if (!$form.form('validate form')) {
            return;
        }

        $('.dimmer', $modal).addClass('active');

        postData(
            function () {
                $('.dimmer', $modal).removeClass('active');
            },
            function () {
                showThankYou($thankYouConciergeRequest);
                ga(
                    'send',
                    'event',
                    'Grouper',
                    'requested concierge',
                    window.location.pathname,
                    { nonInteraction: false }
                );
            }
        );
    }


    /**
     * showStep1
     *
     * Initialize and Reveal the first step.
     */
    function showStep1() {
        hideAllSteps();
        initializeStep1();
        steps[0].show();
    }

    /**
     * showStep2
     *
     * Initialize and Reveal the second step.
     */
    function showStep2() {
        hideAllSteps();
        initializeStep2();
        steps[1].show();
    }

    /**
     * showStep3
     *
     * Initialize and Reveal the third step.
     */
    function showStep3() {
        hideAllSteps();
        initializeStep3();
        steps[2].show();
    }

    /**
     * initializeStep1
     *
     * Prepares the first step of the modal & form for interaction
     */
    function initializeStep1() {
        const step1 = steps[0];

        if (step1.isInitialized) {
            return;
        }

        const $stepElement = step1.$element;
        const $groupSizeSelection = $('.dropdown', $stepElement);
        $groupSizeSelection.dropdown();

        const $submitButton = $('input[type=submit]', $stepElement);

        $submitButton.on('click', function (event) {
            event.preventDefault();

            $form.form('validate form');

            if (!$form.form('is valid')) {
                return;
            }

            if ($form.form('get value', 'group_size') >= 6) {
                showStep2();
            } else {
                hideAllSteps();

                showThankYou($thankYouSmallGroup);

                postData(function () {
                    ga(
                        'send',
                        'event',
                        'Grouper',
                        'group size below 6',
                        window.location.pathname,
                        { nonInteraction: false }
                    );
                });

            }
        });

        step1.isInitialized = true;
    }

    /**
     * initializeStep2
     *
     * Prepares the second step of the modal for interaction.
     * Removes user's option to dismiss the modal.
     */
    function initializeStep2() {
        const step2 = steps[1];

        if (step2.isInitialized) {
            return;
        }

        $('.dropdown', step2.$element).dropdown();

        $form.form('add rule', 'event_type', {
            rules: [
                {
                    type: 'empty',
                    prompt: 'Please select a reason.',
                },
            ],
        });

        $form.form('add rule', 'event_frequency', {
            rules: [
                {
                    type: 'empty',
                    prompt: 'Please select a frequency.',
                },
            ],
        });

        $('.modal-close', $modal).hide();
        $modal.modal('setting', 'closable', false);

        $('input[type=submit]', step2.$element).on('click', function (event) {
            event.preventDefault();

            $form.form('validate form');

            if ($form.form('is valid')) {
                showStep3();
            }
        });
        step2.isInitialized = true;
    }

    /**
     * initializeStep3
     *
     * Prepares the third step for interaction.
     */
    function initializeStep3() {
        const step3 = steps[2];

        if (step3.isInitialized) {
            return;
        }

        $form.form('add rule', 'email', {
            rules: [
                {
                    type: 'empty',
                    prompt: 'Please provide your email address',
                },
                {
                    type: 'email',
                    prompt: 'Please enter a valid email address',
                },
            ],
        });

        $('.js-button-go-to-grouper', step3.$element).on('click', function (event) {
            event.preventDefault();

            // In the rare case where a user has filled in their email address to request
            // concierge, but then change their mind and click the "go to grouper" button,
            // we strip out that email address. We don't want it, and in fact it would be treated as
            // a false positive request for concierge.
            $form.form('set value', 'email', null);

            // Don't trigger the beforeunload callback which would cause
            // the data to be posted twice
            $(window).unbind('beforeunload', setCookieAndPostDataBeforeUnload);

            CH.setCookie('ch_wo', 1);

            postData(function () {
                ga(
                    'send',
                    'event',
                    'Grouper',
                    'navigated to grouper',
                    window.location.pathname,
                    { nonInteraction: false }
                );
                window.location.assign(CH.grouperBrowseUrlFromGroupEventsModal);
            }
            );
        });

        step3.isInitialized = true;
    }

    /**
     * initialize
     *
     * Module wide initialization.
     */
    function initialize() {
        $modal = $('#welcome-overlay-group-events-window');

        $modal.modal('setting', 'onHidden', function () {
            setCookieAndPostData();
        });

        $form = $('form', $modal);
        $form.on('submit', submitForm);

        $thankYouSmallGroup = $('#thank-you-small-group', $modal);
        $thankYouSmallGroup.hide();
        $thankYouConciergeRequest = $('#thank-you-concierge-request', $modal);
        $thankYouConciergeRequest.hide();

        $form.form({
            on: 'blur',
            inline: true,
            fields: {
                group_size: {
                    rules: [
                        {
                            type: 'empty',
                            prompt: 'Please select your group\'s size.',
                        },
                    ],
                },
            },
        });

        ['#step-1', '#step-2', '#step-3'].forEach(function (id) {
            steps.push(new Step(
                id,
                $(id, $form)
            ));
        });

        hideAllSteps();

        $(window).on('beforeunload', setCookieAndPostDataBeforeUnload);

        ga(
            'send',
            'event',
            'Grouper',
            'view Group Events Modal',
            window.location.pathname,
            { nonInteraction: false }
        );

        showStep1();
    }

    /**
     * destroy
     *
     * Unbind events, remove custom validation rules.
     * Clean up after ourselves.
     */
    function destroy() {
        $modal.unbind();
        $form.unbind();

        $(window).unbind('beforeunload', setCookieAndPostDataBeforeUnload);

        $form.form('reset');

        if (steps[1].isInitialized) {
            $form.form('remove fields', ['event_type', 'event_frequency']);
        }

        if (steps[2].isInitialized) {
            $form.form('remove fields', ['email']);
        }
    }

    return {
        initialize: initialize,
        destroy: destroy,
    };
});

