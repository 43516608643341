// @todo - refactor this away, no need to wrap jquery selection
define('ch_selectors',["jquery"], function($) {
    $._ = function(selector, parent) {
        return $(parent ? parent : 'body').find('[data-js="' + selector + '"]');
    };

    $.fn.closest_ = function(selector) {
        return this.closest('[data-js="' + selector + '"]');
    };

    $.fn.disable = function() {
        return this.prop('disabled', true);
    };

    $.fn.enable = function() {
        return this.prop('disabled', false);
    };
});

