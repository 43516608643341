define('ch_bugsnag',[], function () {
    // conditionally load bugsnag only when needed
    if (CH && CH.bugsnagConfig && CH.bugsnagConfig.isEnabled) {
        require(['bugsnag'], function(bugsnag) {
            window.bugsnagClient = bugsnag(CH.bugsnagConfig);
            if (CH && CH.User) {
                window.bugsnagClient.user = CH.User;
            }
        });
    }
});

