define('checkout/AmountManager',['jquery', 'ch_utils'], function ($, utils) {
    var rewardsPointsDiscountAmountApplied = 0;

    var PRODUCT_TYPE = {
        CLASS: 'enrollment',
        GIFTCARD: 'giftcard',
    };

    var $form;
    var $productIdField;
    var $priceField;
    var $quantityField;
    var $productTypeField;

    var $dealDiscount;
    var $dealDiscountValue;

    var $summaryCost;
    var $summaryCostValue;

    var $promoCodeDiscount;
    var $promoCodeDiscountValue;

    var $giftCardDiscount;
    var $giftCardDiscountValue;

    var $creditDiscount;
    var $creditDiscountValue;

    var $shippingCosts;
    var $shippingCostsValue;

    var $primeField;

    var $primeDiscount;
    var $primeDiscountValue;
    var $primeDiscountPotential;

    var $primeCharge;
    var $primeChargeValue;

    var $promoCodeField;
    var $giftCardCodeField;
    var $rewardPointsField;

    var $rewardPoints;
    var $rewardPointsValue;

    var $willEarnPointsAmount;
    var $willEarnPointsDollarValue;

    var $totalPriceWithoutDiscount;
    var $totalPriceRaw;
    var $totalPriceWithDiscount;

    var $paymentMethodField;
    var $allPaymentFields;

    var chStripe;

    // "initialize"
    // @todo I'd rather not have this module define the elements
    $(function () {
        $form = $('#form-checkout-semantic');
        $productIdField = $('#product_id', $form);
        $priceField = $('input[name=price]', $form);
        $quantityField = $('#quantity', $form);
        $productTypeField = $('#product_type', $form);

        $dealDiscount = $('.js-checkout-summary-deal-discount');
        $dealDiscountValue = $dealDiscount.find('.js-value');

        $summaryCost = $('.js-checkout-summary-cost');
        $summaryCostValue = $summaryCost.find('.js-value');

        $promoCodeDiscount = $('.js-checkout-summary-promo-code-discount');
        $promoCodeDiscountValue = $promoCodeDiscount.find('.js-value');

        $giftCardDiscount = $('.js-checkout-summary-gift-card-discount');
        $giftCardDiscountValue = $giftCardDiscount.find('.js-value');

        $creditDiscount = $('.js-checkout-summary-credit-discount');
        $creditDiscountValue = $creditDiscount.find('.js-value');

        $shippingCosts = $('.js-checkout-summary-shipping-amount');
        $shippingCostsValue = $shippingCosts.find('.js-value');

        $primeField = $(':input[name=prime_membership]');

        $primeDiscount = $('.js-checkout-summary-prime-discount');
        $primeDiscountValue = $primeDiscount.find('.js-value');
        $primeDiscountPotential = $('.js-prime-discount-value');

        $primeCharge = $('.js-checkout-summary-prime-membership-amount');
        $primeChargeValue = $primeCharge.find('.js-value');

        $promoCodeField = $('#promo_code');
        $rewardPointsField = $('#reward_points_dollars');
        $giftCardCodeField = $('#gift_card_code');

        $rewardPoints = $('.js-checkout-summary-rewards-points');
        $rewardPointsValue = $rewardPoints.find('.js-value');

        $willEarnPointsAmount = $('.js-will-earn-points-amount');
        $willEarnPointsDollarValue = $('.js-will-earn-points-dollar-value');

        // the value of the purchase, does not include discounts
        $totalPriceWithoutDiscount = $('.js-checkout-summary-total-value');

        // a way to track what discounts are applied
        $debugDiscountsField = $('#debug-discounts');

        // the actual price paid, includes all discounts
        $totalPriceRaw = $('#total-price-raw');
        $totalPriceWithDiscount = $('.js-checkout-summary-total-value-with-discount');

        $paymentMethodField = $('.js-checkout-payment-details input[name=payment_method]');

        $allPaymentFields = $('.js-all-payment-fields');
    });

    /**
     * the potential dollar amount discounted via prime
     *
     * @param totalPrice
     * @returns {number}
     */
    function getPrimeDiscountAmount(totalPrice) {
        return Math.min(
            (totalPrice * CH.primeMembershipDiscount),
            CH.primeMembershipMaxDiscount
        );
    }

    var AmountManager = {
        lastValidatorError: null,

        validateGiftCard: function (code) {
            return $.ajax({
                url: '/course/checkout/check-gift-card-code',
                type: 'POST',
                dataType: 'json',
                data: {
                    product_id: $productIdField.val(),
                    product_type: $productTypeField.val(),
                    gift_card_code: code,
                    quantity: $quantityField.val(),
                },
            }).then(function (res) {
                if (res.success) {
                    window.CH.checkout.gift_card_code_discount_amount = res.data;
                    window.CH.checkout.giftCardCodeApplied = code;
                    AmountManager.redrawPrices();
                } else {
                    AmountManager.resetGiftCard();
                }

                return {
                    status: res.success,
                    message: res.error || null,
                };
            });
        },

        resetGiftCard: function () {
            window.CH.checkout.gift_card_code_discount_amount = 0;
            window.CH.checkout.giftCardCodeApplied = false;
            AmountManager.redrawPrices();
        },

        getCurrentGiftCardCode: function () {
            return window.CH.checkout.giftCardCodeApplied;
        },

        // returns a Promise for the amount
        validatePromoCode: function (code) {
            return $.ajax({
                url: '/course/checkout/check-promo-code',
                type: 'POST',
                dataType: 'json',
                data: {
                    product_id: $productIdField.val(),
                    promo_code: code,
                    quantity: $quantityField.val(),
                    product_type: $productTypeField.val(),
                },
            }).then(function (res) {
                if (res.success && res.data > 0) {
                    window.CH.checkout.promo_code_discount_amount = res.data;
                    window.CH.checkout.promoCodeApplied = code;
                    AmountManager.redrawPrices();
                } else {
                    AmountManager.resetPromoCode();
                }

                return {
                    status: res.success,
                    message: res.error || null,
                };
            });
        },

        resetPromoCode: function () {
            window.CH.checkout.promo_code_discount_amount = 0;
            window.CH.checkout.promoCodeApplied = false;
            AmountManager.redrawPrices();
        },

        getCurrentPromoCode: function () {
            return window.CH.checkout.promoCodeApplied;
        },

        isRewardsPointsValid: function (amount) {
            var user = window.CH.checkout.user;

            if (amount > 0) {
                if (
                    // user has no points
                    !user.points_discount_amount ||
                    // user is trying to use more points than they have
                    amount > user.points_discount_amount ||
                    // user is trying to use less than the minimum
                    amount < 5
                ) {
                    return false;
                }
            }

            return true;
        },

        setRewardsPoints: function (amount) {
            if (this.isRewardsPointsValid(amount)) {
                rewardsPointsDiscountAmountApplied = amount;
            }
        },

        getCurrentRewardsPoints: function () {
            return rewardsPointsDiscountAmountApplied;
        },

        setChStripe: function (chStripeInstance) {
            chStripe = chStripeInstance;
        },

        redrawPrices: function () {
            var quantity = $quantityField.val();
            var price = 0;
            var totalPrice = 0;
            var discountAmount;
            var productType = $productTypeField.val();
            var user = window.CH.checkout.user;
            var debugDiscounts = [];

            $rewardPoints.attr('hidden', '');
            $rewardPointsValue.html(formatPrice(0));
            if ($rewardPointsField.length) {
                $form.form('validate field', $rewardPointsField.prop('name'));
            }

            $dealDiscount.attr('hidden', '');
            $dealDiscountValue.html(formatPrice(0));

            $giftCardDiscount.attr('hidden', '');
            $giftCardDiscountValue.html(formatPrice(0));

            $creditDiscount.attr('hidden', '');
            $creditDiscountValue.html(formatPrice(0));

            $shippingCosts.attr('hidden', '');
            $shippingCostsValue.html(formatPrice(0));

            $primeDiscount.attr('hidden', '');
            $primeDiscountValue.html(formatPrice(0));

            $primeCharge.attr('hidden', '');
            $primeChargeValue.html(formatPrice(0));
            $primeDiscountPotential.html('');

            $promoCodeDiscount.attr('hidden', '');
            $promoCodeDiscountValue.html(formatPrice(0));

            $summaryCostValue.html(formatPrice(0));

            $totalPriceWithoutDiscount.html(formatPrice(0));
            $totalPriceWithDiscount.html(formatPrice(0));
            $totalPriceRaw.val(formatPrice(0));

            // For classes
            if (productType == PRODUCT_TYPE.CLASS) {
                price = window.CH.checkout.classList[$productIdField.val()].price;
                $summaryCostValue.html(formatPrice(price));
                totalPrice = price * quantity;
                $totalPriceWithoutDiscount.html(formatPrice(totalPrice));
            } else if (productType == PRODUCT_TYPE.GIFTCARD) {
                price = window.CH.checkout.price;
                totalPrice = price;
                $summaryCostValue.html(formatPrice(price));
                $totalPriceWithoutDiscount.html(formatPrice(totalPrice));
            }

            // Deduct last minute deal discount
            if (productType == PRODUCT_TYPE.CLASS && totalPrice > 0) {
                var class_discount = window.CH.checkout.classList[$productIdField.val()].class_discount;

                if (class_discount) {
                    discountAmount = totalPrice * (class_discount / 100);
                    totalPrice -= discountAmount;
                    $dealDiscount.removeAttr('hidden');
                    $dealDiscountValue.html('-' + formatPrice(discountAmount));
                    debugDiscounts.push(`deal:${discountAmount}`);
                }
            }

            // Deduct gift card deal discount
            if (productType == PRODUCT_TYPE.GIFTCARD && totalPrice > 0) {
                discountAmount = window.CH.checkout.giftCardDeal;

                if (discountAmount) {
                    totalPrice -= discountAmount;
                    $dealDiscount.removeAttr('hidden');
                    $dealDiscountValue.html('-' + formatPrice(discountAmount));
                    debugDiscounts.push(`gcdeal:${discountAmount}`);
                }
            }

            var primeAmount = 0;

            if (productType == PRODUCT_TYPE.CLASS) {
                primeAmount = getPrimeDiscountAmount(totalPrice);

                if (
                    ($primeField.is(':checked') || user.is_prime)
                    && primeAmount
                ) {
                    totalPrice -= primeAmount;
                    $primeDiscount.removeAttr('hidden');
                    $primeDiscountValue.html('-' + formatPrice(primeAmount));
                }

                debugDiscounts.push(`prime:${primeAmount}`);
                $primeDiscountPotential.html(formatPrice(primeAmount));
            }

            // Deduct credit discount
            if (
                user.credit_discount_amount > 0
                && totalPrice > 0
            ) {
                var creditDiscountAmount = user.credit_discount_amount;

                if (creditDiscountAmount > totalPrice) {
                    creditDiscountAmount = totalPrice;
                    totalPrice = 0;
                } else {
                    totalPrice -= creditDiscountAmount;
                }

                debugDiscounts.push(`cred:${creditDiscountAmount}`);
                $creditDiscount.removeAttr('hidden');
                $creditDiscountValue.html('-' + formatPrice(creditDiscountAmount));
            }

            // Deduct points discount
            if (
                productType == PRODUCT_TYPE.CLASS
                && rewardsPointsDiscountAmountApplied > 0
                && totalPrice > 0
            ) {
                var pointsDiscountAmount = rewardsPointsDiscountAmountApplied;

                if (pointsDiscountAmount > totalPrice) {
                    pointsDiscountAmount = totalPrice;
                    totalPrice = 0;
                } else {
                    totalPrice -= pointsDiscountAmount;
                }

                debugDiscounts.push(`points:${pointsDiscountAmount}`);
                $rewardPoints.removeAttr('hidden');
                $rewardPointsValue.html('-' + formatPrice(pointsDiscountAmount));
            }

            // Deduct gift card discount
            if (window.CH.checkout.gift_card_code_discount_amount > 0 && totalPrice > 0) {
                if (window.CH.checkout.gift_card_code_discount_amount > totalPrice) {
                    window.CH.checkout.gift_card_code_discount_amount = totalPrice;
                    totalPrice = 0;
                } else {
                    totalPrice -= window.CH.checkout.gift_card_code_discount_amount;
                }

                debugDiscounts.push(`gc:${window.CH.checkout.gift_card_code_discount_amount}`);
                $giftCardDiscount.removeAttr('hidden');
                $giftCardDiscountValue.html('-' + formatPrice(window.CH.checkout.gift_card_code_discount_amount));
            }

            // Deduct promo code discount
            if (window.CH.checkout.promo_code_discount_amount > 0 && totalPrice > 0) {
                if (window.CH.checkout.promo_code_discount_amount > totalPrice) {
                    window.CH.checkout.promo_code_discount_amount = totalPrice;
                    totalPrice = 0;
                } else {
                    totalPrice -= window.CH.checkout.promo_code_discount_amount;
                }

                debugDiscounts.push(`promo_code:${window.CH.checkout.promoCodeApplied}:${window.CH.checkout.promo_code_discount_amount}`);
                $promoCodeDiscount.removeAttr('hidden');
                $promoCodeDiscountValue.html('-' + formatPrice(window.CH.checkout.promo_code_discount_amount));
            }

            // Add shipping and handling
            if (window.CH.checkout.shipping_costs_amount > 0) {
                totalPrice += window.CH.checkout.shipping_costs_amount;
                $shippingCosts.show();
                $shippingCostsValue.html('+' + formatPrice(window.CH.checkout.shipping_costs_amount));
            }

            // User is purchasing prime: add cost of Prime Membership
            if ($primeField.is(':checked') && !user.is_prime) {
                totalPrice += CH.primeMembershipFee;
                $primeChargeValue.html('+' + formatPrice(CH.primeMembershipFee));
                $primeCharge.show();
            }

            // Show the number of points and value they will earn with this purchase
            if (productType == PRODUCT_TYPE.CLASS) {
                const pointsEarnedPerDollarSpent = window.CH.checkout.pointsEarnedPerDollarSpent;
                const pointsPricePerDollar = window.CH.checkout.pointsPricePerDollar;

                // Make sure these calculations match the calculations in PointsService.php
                const pointsWillEarn = utils.roundHalfDown(totalPrice * pointsEarnedPerDollarSpent);
                const dollarValueOfPointsWillEarn = utils.roundHalfDown(pointsWillEarn / pointsPricePerDollar, 2);

                $willEarnPointsAmount.html(pointsWillEarn.toLocaleString());
                $willEarnPointsDollarValue.html(formatPrice(dollarValueOfPointsWillEarn));
            }

            $debugDiscountsField.val(debugDiscounts.concat(','));

            AmountManager.renderPrice(price, totalPrice);

            if (chStripe && totalPrice > 0) {
                chStripe.updateAmount(totalPrice);
            }

            return totalPrice;
        },

        isFree: function () {
            return parseInt($priceField.val(), 10) === 0 || parseInt($totalPriceRaw.val().replace(/[$.]/g, ''), 10) === 0;
        },

        renderPrice: function (unitPrice, totalPrice) {
            var formattedTotalPrice;
            //var quantity = $quantityField.length ? parseInt($quantityField.val()) : 1;

            // reset stuff
            $totalPriceWithDiscount.removeClass('green ui label');

            [
                $promoCodeField,
                $giftCardCodeField,
                $rewardPointsField,
            ].forEach(function($el) {
                $el
                    .enable()
                    .siblings('.js-apply-code')
                    .removeClass('disabled');
            });

            $paymentMethodField
                .enable();

            if (totalPrice == 0) {
                $totalPriceWithDiscount.html('FREE!');
                $totalPriceWithDiscount.addClass('green ui label');

                $paymentMethodField
                    .disable()
                    .prop('checked', false)
                    .trigger('change');

                // disable discount fields not in use
                if (!AmountManager.getCurrentPromoCode()) {
                    $promoCodeField
                        .disable()
                        .siblings('.js-apply-code')
                        .addClass('disabled');
                }
                if (!AmountManager.getCurrentGiftCardCode()) {
                    $giftCardCodeField
                        .disable()
                        .siblings('.js-apply-code')
                        .addClass('disabled');
                }
                if (!AmountManager.getCurrentRewardsPoints()) {
                    $rewardPointsField
                        .disable()
                        .siblings('.js-apply-rewards')
                        .addClass('disabled');
                }

                $allPaymentFields.hide();
            } else {
                formattedTotalPrice = formatPrice(totalPrice);
                $allPaymentFields.show();
                $totalPriceWithDiscount.html(formattedTotalPrice);
                $totalPriceRaw.val(totalPrice);
            }
        },
    };

    return AmountManager;
});

