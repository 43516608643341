define('ch_points',[
    'jquery',
    'semantic',
    'ch_modal_window',
], function ($) {
    $('.points-nav').popup({
        popup: $('.points-nav-popup'),
        position: 'bottom center',
        on: 'hover',
        transition: 'vertical flip'
    });

    $('.profile-icon').popup({
        popup: $('.profile-popup'),
        position: 'bottom right',
        on: 'hover',
        hoverable: true
    });

});

