define('ch_category_preferences',[
    "jquery",
    "ch_selectors",
    "ch_modal_window",
    "sweetalert",
    "ch_referral",
], function($) {
    var onHideThankYouModal = function () {
        if (
            CH.showReferralModal &&
            !CH.isMobile &&
            !CH.isSemantic
        ) {
            CH.referral.show('Wish-List Referral');
        }
    };

    var categoryPreferencesWindowOptions = {
        onSuccess: function (res) {
            CH.GA.userIntentData = res.userIntentSelections;

            ga(
                'send',
                'event',
                'Email Capture',
                'User Intent - Overlay',
                CH.GA.userIntentData,
                {
                    nonInteraction: false,
                }
            );

            // On beta version of the site we stop the welcome series chain modals
            if (CH.isBeta) {
                return;
            }

            if (CH.isAdWordWelcomeModal) {
                var ModalWindowWelcomeOverlayThankYouAdWordObj = new ModalWindowWelcomeOverlayThankYouAdWord();
                ModalWindowWelcomeOverlayThankYouAdWordObj.show({
                    autofocus: false
                });
            } else if (CH.preferenceSuccessCallback) {
                CH.preferenceSuccessCallback();
            } else if (CH.isWelcomeDiscountEnabled) {
                var ModalWindowWelcomeOverlayThankYouObj = new ModalWindowWelcomeOverlayThankYou();
                ModalWindowWelcomeOverlayThankYouObj.show({
                    onHide: onHideThankYouModal,
                    autofocus: false
                });
            }
        },
        onShow: function() {
            var self = this;
            $('body').unmaskElement(''); //remove any loading that may have come before this
            var modalId = '#' + self.id;
            var $categorySelectionsField = $('#category-selections');

            $('#intent-hobby, #intent-fun').on('click', function(e) {
                $('.professional', modalId).hide();

                $('.intent-list', modalId).fadeOut(100, function() {
                    $('.recreational', modalId).fadeIn(100);
                });
            });

            $('#intent-skill, #intent-career, #intent-degree').on('click', function(e) {
                $('.recreational', modalId).hide();

                $('.intent-list', modalId).fadeOut(100, function() {
                    $('.professional', modalId).fadeIn(100);
                });
            });

            $('.category-list .item', modalId).on('click', function(e) {
                var $category = $(this);

                $category.toggleClass('selected');

                var selectedCategories = $('.category-list .item.selected', modalId).map(function() {
                    return $(this).text();
                }).get().join(',');
                $categorySelectionsField.val(selectedCategories);
            });

            if (self.instanceOptions.title) {
                $('.window_title', modalId).html(self.instanceOptions.title);
            }

            self.instanceOptions.description ?
                $('.window_description', modalId).html(self.instanceOptions.description) :
                $('.window_description', modalId).hide();

            $.each($(
                '[data-js="category1"], ' +
                '[data-js="category2"], ' +
                '[data-js="category3"], ' +
                '[data-js="user_intent_section_list1"], ' +
                '[data-js="user_intent_section_list2"], ' +
                '[data-js="user_intent_section_list3"] '
            ), function() {
                var category = $(this);

                if (category.is(':checked')) {
                    category.parent().addClass('selected');
                }

                category.on('click', function(e) {
                    if (!category.attr('checked')) {
                        category.attr('checked', 'checked');
                        category.parent().addClass('selected');
                    }
                    else {
                        category.removeAttr('checked');
                        category.parent().removeClass('selected');
                    }
                });
            });

            $('.skip-category-preferences').on('click', function(e) {
                e.preventDefault();
                var ModalWindowCategoryPreferencesObj = new ModalWindowCategoryPreferences();
                ModalWindowCategoryPreferencesObj.hide();

                if (CH.isAdWordWelcomeModal) {
                    var ModalWindowWelcomeOverlayThankYouAdWordObj = new ModalWindowWelcomeOverlayThankYouAdWord();
                    ModalWindowWelcomeOverlayThankYouAdWordObj.show({
                        autofocus: false
                    });
                } else if (CH.preferenceSuccessCallback) {
                    CH.preferenceSuccessCallback();
                } else if (CH.isWelcomeDiscountEnabled) {
                    var ModalWindowWelcomeOverlayThankYouObj = new ModalWindowWelcomeOverlayThankYou();
                    ModalWindowWelcomeOverlayThankYouObj.show({
                        onHide: onHideThankYouModal,
                        autofocus: false
                    });
                }
            });
        },
        onHide: function() {
            CH.setCookie('ch_cp', 1);
            this.remove();
        }
    };

    CH.showCategoryPreferencesWindow = function(
        showReferralModal,
        title,
        description
        ) {
        fadeSignUpBar();

        if (!CH.getCookie('ch_cp')) {
            CH.showReferralModal = showReferralModal ? showReferralModal : false;

            var ModalWindowCategoryPreferencesObj = new ModalWindowCategoryPreferences();
            if (CH.isAdWordWelcomeModal) {
                ModalWindowCategoryPreferencesObj.show(Object.assign(categoryPreferencesWindowOptions, {
                    autofocus: false
                }));
            } else {
                ModalWindowCategoryPreferencesObj.show(Object.assign(categoryPreferencesWindowOptions, {
                    title: title,
                    description: description,
                    autofocus: false
                }));
            }
        }
        else {
            return false;
        }
    };

    function fadeSignUpBar() {
        var signUpBar = $('.sign-up-bar');

        // After email-capture from welcome-overlay, wish-list, footer or sign-up an identity associated with the
        // entered email is created and we email at some point the WELCOME10 coupon. Therefore it is safe to hide the
        // nano bar
        if (
            signUpBar.length &&
            signUpBar.is(':visible') &&
            CH.getCookie('ch_i')
        ) {
            signUpBar.fadeOut('slow');
        }
    }
});

