/*! a11y-dialog 8.1.1 — © Kitty Giraudel */

define('ch_dialog',[], function () {
    const not = {
        inert: ':not([inert]):not([inert] *)',
        negTabIndex: ':not([tabindex^="-"])',
        disabled: ':not(:disabled)',
    };

    var focusableSelectors = [
        `a[href]${not.inert}${not.negTabIndex}`,
        `area[href]${not.inert}${not.negTabIndex}`,
        `input:not([type="hidden"]):not([type="radio"])${not.inert}${not.negTabIndex}${not.disabled}`,
        `input[type="radio"]${not.inert}${not.negTabIndex}${not.disabled}`,
        `select${not.inert}${not.negTabIndex}${not.disabled}`,
        `textarea${not.inert}${not.negTabIndex}${not.disabled}`,
        `button${not.inert}${not.negTabIndex}${not.disabled}`,
        `details${not.inert} > summary:first-of-type${not.negTabIndex}`,
        `iframe${not.inert}${not.negTabIndex}`,
        `audio[controls]${not.inert}${not.negTabIndex}`,
        `video[controls]${not.inert}${not.negTabIndex}`,
        `[contenteditable]${not.inert}${not.negTabIndex}`,
        `[tabindex]${not.inert}${not.negTabIndex}`,
    ];

    function focus(el) {
        (el.querySelector('[autofocus]') || el).focus();
    }

    function getFocusableEdges(el) {
        const firstEl = findFocusableEl(el, true);
        const lastEl = firstEl ? findFocusableEl(el, false) || firstEl : null;
        return [firstEl, lastEl];
    }

    function findFocusableEl(el, forward) {
        if (forward && isFocusable(el)) return el;
        if (canHaveFocusableChildren(el)) {
            if (el.shadowRoot) {
                let next = getNextChildEl(el.shadowRoot, forward);
                while (next) {
                    const focusableEl = findFocusableEl(next, forward);
                    if (focusableEl) return focusableEl;
                    next = getNextSiblingEl(next, forward);
                }
            } else if (el.localName === 'slot') {
                const assignedElements = el.assignedElements({ flatten: true });
                if (!forward) assignedElements.reverse();
                for (const assignedElement of assignedElements) {
                    const focusableEl = findFocusableEl(assignedElement, forward);
                    if (focusableEl) return focusableEl;
                }
            } else {
                let next = getNextChildEl(el, forward);
                while (next) {
                    const focusableEl = findFocusableEl(next, forward);
                    if (focusableEl) return focusableEl;
                    next = getNextSiblingEl(next, forward);
                }
            }
        }
        if (!forward && isFocusable(el)) return el;
        return null;
    }

    function getNextChildEl(el, forward) {
        return forward ? el.firstElementChild : el.lastElementChild;
    }

    function getNextSiblingEl(el, forward) {
        return forward ? el.nextElementSibling : el.previousElementSibling;
    }

    function isHidden(el) {
        if (el.matches('details:not([open]) *') && !el.matches('details>summary:first-of-type')) return true;
        return !(el.offsetWidth || el.offsetHeight || el.getClientRects().length);
    }

    function isFocusable(el) {
        if (el.shadowRoot && el.shadowRoot.delegatesFocus) return false;
        return el.matches(focusableSelectors.join(',')) && !isHidden(el);
    }

    function canHaveFocusableChildren(el) {
        if (el.shadowRoot && el.getAttribute('tabindex') === '-1') return false;
        return !el.matches(':disabled,[hidden],[inert]');
    }

    function getActiveEl(root = document) {
        const activeEl = root.activeElement;
        if (!activeEl) return null;
        if (activeEl.shadowRoot) return getActiveEl(activeEl.shadowRoot) || document.activeElement;
        return activeEl;
    }

    function trapTabKey(el, event) {
        const [firstFocusableEl, lastFocusableEl] = getFocusableEdges(el);
        if (!firstFocusableEl) return event.preventDefault();
        const activeEl = getActiveEl();
        if (event.shiftKey && activeEl === firstFocusableEl) {
            lastFocusableEl.focus();
            event.preventDefault();
        } else if (!event.shiftKey && activeEl === lastFocusableEl) {
            firstFocusableEl.focus();
            event.preventDefault();
        }
    }

    function closest(selector, base) {
        function from(el) {
            if (!el || el === document || el === window) return null;
            if (el.assignedSlot) el = el.assignedSlot;
            return (el.closest(selector) || from(el.getRootNode().host));
        }
        return from(base);
    }

    const SCOPE = 'data-a11y-dialog';

    function A11yDialog(element) {
        const dialog = {
            $el: element,
            id: element.getAttribute(SCOPE) || element.id,
            previouslyFocused: null,
            shown: false,
        };

        dialog.maintainFocus = function (event) {
            const target = event.target;
            if (!target.closest(`[aria-modal="true"], [${SCOPE}-ignore-focus-trap]`)) {
                focus(dialog.$el);
            }
        };

        dialog.bindKeypress = function (event) {
            if (closest('[aria-modal="true"]', getActiveEl()) !== dialog.$el) return;
            let hasOpenPopover = false;
            try {
                hasOpenPopover = !!dialog.$el.querySelector('[popover]:not([popover="manual"]):popover-open');
            } catch (error) {
                console.warn(error)
            }
            if (event.key === 'Escape' && dialog.$el.getAttribute('role') !== 'alertdialog' && !hasOpenPopover) {
                event.preventDefault();
                dialog.hide(event);
            }
            if (event.key === 'Tab') {
                trapTabKey(dialog.$el, event);
            }
        };

        dialog.handleTriggerClicks = function (event) {
            const target = event.composedPath()[0];
            const opener = closest(`[${SCOPE}-show="${dialog.id}"]`, target);
            const explicitCloser = closest(`[${SCOPE}-hide="${dialog.id}"]`, target);
            const implicitCloser = closest(`[${SCOPE}-hide]`, target) && closest('[aria-modal="true"]', target) === dialog.$el;
            if (opener) dialog.show(event);
            if (explicitCloser || implicitCloser) dialog.hide(event);
        };

        dialog.show = function (event) {
            if (dialog.shown) return dialog;
            const showEvent = dialog.fire('show', event);
            if (showEvent.defaultPrevented) return dialog;
            dialog.shown = true;
            dialog.$el.removeAttribute('aria-hidden');
            dialog.previouslyFocused = getActiveEl();
            if (dialog.previouslyFocused && dialog.previouslyFocused.tagName === 'BODY' && event && event.target) {
                dialog.previouslyFocused = event.target;
            }
            focus(dialog.$el);
            document.body.addEventListener('focus', dialog.maintainFocus, true);
            dialog.$el.addEventListener('keydown', dialog.bindKeypress, true);
            return dialog;
        };

        dialog.hide = function (event) {
            if (!dialog.shown) return dialog;
            const hideEvent = dialog.fire('hide', event);
            if (hideEvent.defaultPrevented) return dialog;
            dialog.shown = false;
            dialog.$el.setAttribute('aria-hidden', 'true');
            dialog.previouslyFocused && dialog.previouslyFocused.focus && dialog.previouslyFocused.focus();
            document.body.removeEventListener('focus', dialog.maintainFocus, true);
            dialog.$el.removeEventListener('keydown', dialog.bindKeypress, true);
            return dialog;
        };

        dialog.on = function (type, handler, options) {
            dialog.$el.addEventListener(type, handler, options);
            return dialog;
        };

        dialog.off = function (type, handler, options) {
            dialog.$el.removeEventListener(type, handler, options);
            return dialog;
        };

        dialog.fire = function (type, event) {
            const customEvent = new CustomEvent(type, {
                detail: event,
                cancelable: true,
            });
            dialog.$el.dispatchEvent(customEvent);
            return customEvent;
        };

        dialog.$el.setAttribute('aria-hidden', 'true');
        dialog.$el.setAttribute('aria-modal', 'true');
        dialog.$el.setAttribute('tabindex', '-1');
        if (!dialog.$el.hasAttribute('role')) {
            dialog.$el.setAttribute('role', 'dialog');
        }

        document.addEventListener('click', dialog.handleTriggerClicks, true);

        return dialog;
    }

    // // AUTOMATIC INSTANTIATION DISABLED
    // 
    // function instantiateDialogs() {
    //     for (const el of document.querySelectorAll('[data-a11y-dialog]')) {
    //         A11yDialog(el);
    //     }
    // }

    // if (typeof document !== 'undefined') {
    //     if (document.readyState === 'loading') {
    //         document.addEventListener('DOMContentLoaded', instantiateDialogs);
    //     } else {
    //         instantiateDialogs();
    //     }
    // }

    return A11yDialog;
})
;
