define('ch_modal_window',[
    'jquery',
    'dayjs',
    'ch_semantic_responsive',
    'jquery.validate.min',
    'sweetalert',
    'ch_selectors',
    'ch_form_validation',
    'ch_form_values',
    'ch_load_mask',
    'ch_utils'
], function ($, dayjs, chResponsive, chGrouper) {
    var GA_CATEGORIES = {
        EMAIL_CAPTURE: 'Email Capture',
        LOGIN: 'Login',
        CATEGORY_PREFERENCE: 'Category Preference',
        USER_INTENT: 'User Intent',
        LEAD_GEN: 'Leadgen'
    };
    var loading = {};
    var cache = {};

    // Avoid doing centering logic for responsive layout.
    if (!CH.isResponsiveLayout) {
        if (
            CH.isMobile || (
                !CH.isSemantic &&
                !CH.isNewSemanticStyle
            )
        ) {
            var centeringModal = function (modal) {
                var height = $(window).height();
                var width = $(window).width();
                modal.css({
                    position: 'fixed',
                    top: (
                        height > modal.outerHeight() ?
                            (height - modal.outerHeight()) / 2 :
                            0
                    ),
                    left: (
                        width > modal.outerWidth() ?
                            (width - modal.outerWidth()) / 2 :
                            0
                    ),
                });
            };
        }
    }

    window.ModalManager = {
        get: function (type) {
            if (cache[type]) return cache[type];

            return new window['ModalWindow' + type.toCamel().upperCaseFirstChar()];
        },
    };

    window.ModalMessage = function (options) {
        swal({
            title: options.message,
            timer: 2500,
            showConfirmButton: false,
        }, function () {
            swal.close();
            if (typeof options.callback === 'function') {
                options.callback.call(this);
            }
        });
    };

    // Base class for Modal Windows
    function ModalWindow(type, options, initHook) {
        $.extend(this, {
            id: type + '-window',
            type: type,
            window: null,
            partnerWindow: null,
            gaEventCategory: null,
            gaEventAction: null,
            inAjax: true,
            validateForms: true,
            instanceOptions: {
                onShow: null,
                onSuccess: null,
                onHide: null,
                location: CH.GA.page,
                additionalData: null,
            },
            queryParams: {}
        }, options);

        var self = this;

        //Do we really need/care/want this?
        if (cache[type]) return cache[type];
        cache[type] = this;

        var _init = function () {
            // Normal Form
            if (self.validateForms) {
                CH.validate($('#' + self.id + ' form'), {
                    submitHandler: $.proxy(self.normalHandler, self),
                });
            }

            self.window = $('#' + self.id);

            // Facebook Login
            var facebookButton = $._('facebook-button', self.window);
            facebookButton.click($.proxy(self.facebookLogin, self));

            // Google Login
            $._('google-button', self.window).click($.proxy(self.googleLogin, self));

            if (initHook) initHook.call(self);
        };

        // Window exists on DOM
        if ($('#' + this.id).length) {
            // Add events to elements
            _init();
        }

        // Load window in AJAX
        else if (this.inAjax) {
            var suffix = '-window';
            if (CH.isNewSemanticStyle) {
                suffix = '-window-new-semantic';
            }

            loading[type] = true;

            $.ajax({
                url: '/window/' + type + suffix,
                dataType: 'html',
                data: this.queryParams,
                success: function (html) {
                    // Add modal window
                    var win = $(html).attr('id', self.id);
                    $('body').append(win);

                    // Add events to elements
                    _init();

                    loading[type] = false;

                    $(self).trigger(self.id + '-loaded', self);
                },
            });
        }

        return this;
    }

    ModalWindow.prototype.normalHandler = function (form) {
        $('#' + this.id).maskElement(''); //show loader
        var $form = $(form);
        $.ajax({
            url: $form.attr('action'),
            data: $.extend({}, $form.formValues(), this.instanceOptions.additionalData),
            type: 'POST',
            success: $.proxy(this.handleResponse, this),
            xhrFields: {
                withCredentials: true,
            },
        });
        if (this.gaEventCategory && this.gaEventAction) {
            ga(
                'send',
                'event',
                this.gaEventCategory,
                this.gaEventAction,
                'Email Submitted - ' + this.instanceOptions.location,
                { nonInteraction: false }
            );
        }
    };

    ModalWindow.prototype.facebookLogin = function (e) {
        if (e) e.preventDefault();
        var self = this;
        if (typeof FB === 'undefined') {
            swal('Sorry, Facebook login is not available at this time.', 'Please use your email address.', 'error');
            return;
        }

        FB.login(function (response) {
            if (response.authResponse) {
                var accessToken = response.authResponse.accessToken;
                $.post(
                    '/user/authentication/login-facebook',
                    $.extend(
                        {},
                        {token: accessToken},
                        self.instanceOptions.additionalData
                    ),
                    $.proxy(self.handleResponse, self)
                );
            }
        }, { scope:'email' });

        if (this.gaEventCategory && this.gaEventAction) {
            ga(
                'send',
                'event',
                this.gaEventCategory,
                this.gaEventAction,
                'Email Submitted - ' + this.instanceOptions.location,
                { nonInteraction: false }
            );
        }
    };

    ModalWindow.prototype.googleLogin = function (e) {
        if (e) e.preventDefault();
        var self = this;
        googleLogin(function (accessToken) {
            $.post(
                '/user/authentication/login-google',
                $.extend({}, { token: accessToken }, self.instanceOptions.additionalData),
                $.proxy(self.handleResponse, self)
            );
        });

        if (this.gaEventCategory && this.gaEventAction) {
            ga(
                'send',
                'event',
                this.gaEventCategory,
                this.gaEventAction,
                'Email Submitted - ' + this.instanceOptions.location,
                { nonInteraction: false }
            );
        }
    };

    ModalWindow.prototype.handleResponse = function (response) {
        $('#' + this.id).unmaskElement('');

        let res = response;

        // Response should be an object, but just in case it's a string, we try to parse it as JSON
        if (res !== undefined && typeof res === 'string') {
            try {
                res = JSON.parse(res);
            } catch (e) {
                // isn't json string
            }
        }

        if (res.success) {
            if (CH.getCookie('ch_i')) {
                ga('set', 'userId', CH.getCookie('ch_i').replace(/"(\w+)"\|.+/, "$1"));
            }

            if (this.gaEventCategory && this.gaEventAction) {
                ga(
                    'send',
                    'event',
                    this.gaEventCategory,
                    this.gaEventAction,
                    'Email Captured - ' + this.instanceOptions.location,
                    { nonInteraction: false }
                );
            }

            // Execute onSuccess (if provided by client)
            if (this.instanceOptions.onSuccess) {
                this.hide();
                this.instanceOptions.onSuccess.call(this, res);
            }

            // Execute redirect (if provided by controller)
            else if (res.redirect) {
                window.location = res.redirect;
            }

            // Show message (if provided by controller)
            else if (res.message) {
                this.hide();
                new ModalMessage({ message: res.message });
            } else {
                window.location.reload();
            }
        } else {
            $._('window_error', '#' + this.id).html(res.error).show();
        }
    };

    ModalWindow.prototype.show = function (instanceOptions) {
        var self = this;
        $.extend(true, self, {
            instanceOptions: {
                onShow: null,
                onSuccess: null,
                onHide: null,
                location: CH.GA.page,
                additionalData: null,
            },
        }, {
            instanceOptions: (instanceOptions || {}),
        });

        var _show = function () {
            var win = $('#' + self.id);

            if (
                CH.isResponsiveLayout ||
                (!CH.isMobile && (CH.isSemantic || CH.isNewSemanticStyle))
            ) {
                win.modal('setting', {
                    onHidden: function () {
                        self.hide();
                    },

                    autofocus: instanceOptions && instanceOptions.hasOwnProperty('autofocus')
                        ? instanceOptions.autofocus
                        : true,
                });

                if (CH.isMobile) {
                    chResponsive.hideSidebar();
                }

                win.find("[data-js='close-modal']")
                    .unbind('mousedown click')
                    .on('mousedown click', function (e) {
                        // Prevent default in mousedown b/c it gets called before onfocusout event.
                        // We want to prevent the onfocusout event from validating and showing form errors
                        // when we're close the modal.
                        e.preventDefault();
                        self.hide();
                    });

                win.modal('show'); // Show the modal
            } else {
                // Bootstrap modal bug that prevents ESC key from working
                win.attr('tabindex', -1);

                // Prevent the body from scrolling
                $('body').addClass('modal-open');
                win.unbind('hidden').on('hidden', function () {
                    $('body').removeClass('modal-open');
                });

                // Center the modal on the screen
                centeringModal(win);

                $(window).resize(function () {
                    centeringModal(win);
                });

                win.bootstrapModal('show'); //unsure why but requires 'show' now

                win.bootstrapModal().on('hidden', function () {
                    self.hide();
                });
            }

            // Execute onShow (if proviced by client)
            if (self.instanceOptions.onShow) {
                self.instanceOptions.onShow.call(self);
            }

            // Send the GA event to indicate the modal was opened
            if (self.gaEventCategory && self.gaEventAction) {
                ga('send', 'event', self.gaEventCategory, self.gaEventAction, 'Window Opened - ' + self.instanceOptions.location, {
                    nonInteraction: true,
                });
            }

        };

        // Window DOM still loading
        if (loading[this.type]) {
            $(this).on(this.id + '-loaded', _show);
        }

        // Window DOM loaded
        else {
            this.removeErrors();
            _show();
        }
    };

    ModalWindow.prototype.hide = function (options) {
        var win = $('#' + this.id);
        options = (options == null) ? {} : options;

        if (
            !CH.isMobile && (
                CH.isSemantic ||
                CH.isNewSemanticStyle
            )
        ) {
            // Since we're calling hide() we don't need to call it again when the modal becomes hidden
            win.modal('setting', {
                onHidden: function () {},
            });
        }

        if (!options['noHide']) {
            (CH.isSemantic && !CH.isMobile) || CH.isNewSemanticStyle
                ?  win.modal('hide')
                : $('#' + this.id).bootstrapModal('hide');
        }

        if (this.instanceOptions.onHide) {
            this.instanceOptions.onHide.call(this);
        }
    };

    // the optional options object applies options to the partnerWindow
    ModalWindow.prototype.link = function (partnerWindow, options) {
        $.extend(partnerWindow, options); //NOTE: this mutates partnerWindow
        if (!partnerWindow) {
            this.partnerWindow = null;
            return;
        }

        // Wire switch links
        $._('switcher', '#' + this.id).off('click').on('click', $.proxy(this.switchWindow, this));

        this.partnerWindow = partnerWindow;
    };

    ModalWindow.prototype.switchWindow = function (e) {
        if (e) e.preventDefault();

        // Need to do history state stuff but already hiding modal in semantic by calling .show for new modal
        (!CH.isMobile && CH.isSemantic) || CH.isNewSemanticStyle
            ?  this.hide({ noHide: true })
            : this.hide();
        // if there is a partner window apply that window's linked options over orignal
        if (this.partnerWindow) {
            this.partnerWindow.show(
                $.extend({}, this.instanceOptions, this.partnerWindow.instanceOptions)
            );
        }
    };

    ModalWindow.prototype.removeErrors = function () {
        $._('window_error', '#' + this.id).html('').hide();
    };

    ModalWindow.prototype.remove = function () {
        this.window.remove();
        delete cache[this.type];
    };

    // Register Modal Window subclass
    window.ModalWindowRegister = function (options) {
        var self = this;

        return ModalWindow.call(this, 'register', $.extend({
            gaEventCategory: GA_CATEGORIES.EMAIL_CAPTURE,
            gaEventAction: 'Register',
        }, options), function () {
            // Link Login Window
            self.link(ModalManager.get('login'));
        });
    };
    $.extend(window.ModalWindowRegister.prototype, ModalWindow.prototype);

    // WishListSignup Modal Window subclass
    window.ModalWindowWishlistSignup = function (options) {
        var self = this;
        return ModalWindow.call(this, 'wish-list-signup', $.extend({
            gaEventCategory: GA_CATEGORIES.EMAIL_CAPTURE,
            gaEventAction: 'Register & Wish List',
        }, options), function () {
            // Link Login Window
            self.link(ModalManager.get('login'), {
                instanceOptions: options.linkedInstanceOptions
            });
        });
    };
    $.extend(ModalWindowWishlistSignup.prototype, ModalWindow.prototype);

    // UpdateProfile Modal Window subclass
    window.ModalWindowUpdateProfile = function (options) {
        return ModalWindow.call(this, 'update-profile', $.extend({}, options));
    };
    $.extend(ModalWindowUpdateProfile.prototype, ModalWindow.prototype);

    // ChangePassword Modal Window subclass
    window.ModalWindowChangePassword = function (options) {
        return ModalWindow.call(this, 'change-password', $.extend({}, options));
    };
    $.extend(ModalWindowChangePassword.prototype, ModalWindow.prototype);

    // DisableAccount Modal Window subclass
    window.ModalWindowDisableAccount = function (options) {
        return ModalWindow.call(this, 'disable-account', $.extend({}, options));
    };
    $.extend(ModalWindowDisableAccount.prototype, ModalWindow.prototype);

    // CancelPrime Modal Window subclass
    window.ModalWindowCancelPrime = function (options) {
        return ModalWindow.call(this, 'cancel-prime', $.extend({}, options));
    };
    $.extend(ModalWindowCancelPrime.prototype, ModalWindow.prototype);

    // ForgotPassword Modal Window subclass
    window.ModalWindowForgotPassword = function (options) {
        var self = this;

        return ModalWindow.call(this, 'forgot-password', $.extend({}, options), function () {
            // Link Register Window
            self.link(ModalManager.get('register'));
        });
    };
    $.extend(ModalWindowForgotPassword.prototype, ModalWindow.prototype);

    // Login Modal Window subclass
    window.ModalWindowLogin = function (options) {
        var self = this;

        return ModalWindow.call(this, 'login', $.extend({
            gaEventCategory: GA_CATEGORIES.LOGIN,
            gaEventAction: 'Login',
        }, options), function () {
            // Link Register Window
            self.link(ModalManager.get('register'));

            // Link Forgot Password
            var ModalWindowForgotPasswordObj = new ModalWindowForgotPassword();
            $._('recover', '#' + self.id).click(function () {
                self.hide();
                ModalWindowForgotPasswordObj.show();
            });
        });
    };
    $.extend(ModalWindowLogin.prototype, ModalWindow.prototype);

    // ShareWishList Modal Window subclass
    window.ModalWindowShareWishList = function (options) {
        return ModalWindow.call(this, 'share-wish-list', $.extend({ inAjax: false }, options));
    };
    $.extend(ModalWindowShareWishList.prototype, ModalWindow.prototype);

    // EmailWishList Modal Window subclass
    window.ModalWindowEmailWishList = function (options) {
        return ModalWindow.call(this, 'email-wish-list', $.extend({}, options));
    };
    $.extend(ModalWindowEmailWishList.prototype, ModalWindow.prototype);

    // Generic Confirmation Modal Window
    window.ModalWindowConfirmation = function (options) {
        return ModalWindow.call(this, 'confirmation', $.extend({
            setTitle: function (text) {
                $._('window_title', '[data-js="confirmation-window"]').html(text);
            },

            setContent: function (message) {
                $._('confirmation-message').html(message);
            },
        }, options));
    };
    $.extend(ModalWindowConfirmation.prototype, ModalWindow.prototype);

    // Add/Edit Review Modal Window
    window.ModalWindowReview = function (options) {
        return ModalWindow.call(this, 'review', $.extend({}, options));
    };
    $.extend(ModalWindowReview.prototype, ModalWindow.prototype);

    // Reward Points Modal Window
    window.ModalWindowRewardPoints = function (options) {
        return ModalWindow.call(this, 'reward-points', $.extend({}, options));
    };
    $.extend(ModalWindowRewardPoints.prototype, ModalWindow.prototype);

    // Change Rating Modal Window
    window.ModalWindowChangeRating = function (options) {
        return ModalWindow.call(this, 'change-rating', $.extend({}, options));
    };
    $.extend(ModalWindowChangeRating.prototype, ModalWindow.prototype);

    // Section Details Modal Window
    window.ModalWindowSectionDetails = function (options, callback) {
        return ModalWindow.call(this, 'section-details', $.extend({}, options), callback);
    };
    $.extend(ModalWindowSectionDetails.prototype, ModalWindow.prototype);

    // Send Enrollment Confirmation Modal Window
    window.ModalWindowEmailEnrollmentConfirmation = function (options) {
        return ModalWindow.call(this, 'email-enrollment-confirmation', $.extend({}, options));
    };
    $.extend(ModalWindowEmailEnrollmentConfirmation.prototype, ModalWindow.prototype);

    // Custom Modal Window
    window.ModalWindowCustom = function (title, description, content, options) {
        var self = this;

        return ModalWindow.call(this, 'custom', $.extend({}, options), function () {
            $._('window_title', self.window).html(title);
            $._('window_description', self.window).html(description);
            $._('window_content', self.window).html(content);
        });
    };
    $.extend(ModalWindowCustom.prototype, ModalWindow.prototype);

    // Email Capture Modal Window
    window.ModalWindowWelcomeOverlay = function (options) {
        return ModalWindow.call(this, 'welcome-overlay', $.extend({
            gaEventCategory: GA_CATEGORIES.EMAIL_CAPTURE,
            gaEventAction: 'Welcome Overlay',
        }, options));
    };
    $.extend(ModalWindowWelcomeOverlay.prototype, ModalWindow.prototype);

    window.ModalWindowWelcomeOverlayGroupEvents = function (options) {
        return ModalWindow.call(this, 'welcome-overlay-group-events', $.extend({
            validateForms: false,
            gaEventCategory: GA_CATEGORIES.EMAIL_CAPTURE,
            gaEventAction: 'Welcome Overlay - Group Events',
        }, options));
    };
    $.extend(ModalWindowWelcomeOverlayGroupEvents.prototype, ModalWindow.prototype);

    // Email Capture Modal Window - Ad Word Welcome Modal
    window.ModalWindowWelcomeOverlayAdWord = function (options) {
        return ModalWindow.call(this, 'welcome-overlay-ad-word', $.extend({
            gaEventCategory: GA_CATEGORIES.EMAIL_CAPTURE,
            gaEventAction: 'Welcome Overlay',
        }, options));
    };
    $.extend(ModalWindowWelcomeOverlayAdWord.prototype, ModalWindow.prototype);

    window.ModalWindowWelcomeOverlayThankYou = function (options) {
        return ModalWindow.call(this, 'welcome-overlay-thank-you', $.extend({}, options));
    };
    $.extend(ModalWindowWelcomeOverlayThankYou.prototype, ModalWindow.prototype);

    window.ModalWindowWishlistOverlayThankYou = function(options) {
        return ModalWindow.call(this, 'wishlist-overlay-thank-you', $.extend({}, options));
    };
    $.extend(ModalWindowWishlistOverlayThankYou.prototype, ModalWindow.prototype);

    window.ModalWindowWelcomeOverlayThankYouAdWord = function (options) {
        return ModalWindow.call(this, 'welcome-overlay-thank-you-ad-word', $.extend({}, options));
    };
    $.extend(ModalWindowWelcomeOverlayThankYouAdWord.prototype, ModalWindow.prototype);

    // CategoryFollow Modal Window subclass
    window.ModalWindowCategoryFollowSignup = function (options) {
        var self = this;

        return ModalWindow.call(this, 'category-follow-signup', $.extend({
            gaEventCategory: GA_CATEGORIES.EMAIL_CAPTURE,
            gaEventAction: 'Category Follow Signup',
        }, options), function () {
            // Link Login Window
            self.link(ModalManager.get('login'));
        });
    };
    $.extend(ModalWindowCategoryFollowSignup.prototype, ModalWindow.prototype);

    // CategoryPreferences Modal Window subclass
    window.ModalWindowCategoryPreferences = function (options) {
        return ModalWindow.call(this, 'category-preferences', $.extend({
            gaEventCategory: GA_CATEGORIES.CATEGORY_PREFERENCE,
            gaEventAction: 'Category Preferences Captured',
        }, options));
    };
    $.extend(ModalWindowCategoryPreferences.prototype, ModalWindow.prototype);

    // Referral Emails Sent Confirmation Modal Window
    window.ModalWindowReferralConfirmation = function (options) {
        return ModalWindow.call(this, 'referral-confirmation', $.extend({}, options));
    };
    $.extend(ModalWindowReferralConfirmation.prototype, ModalWindow.prototype);

    // Referral Acceptance Modal Window
    window.ModalWindowReferralFriendActivation = function (options) {
        return ModalWindow.call(this, 'referral-friend-activation', $.extend({}, options));
    };
    $.extend(ModalWindowReferralFriendActivation.prototype, ModalWindow.prototype);

    // Review 4 or 5 Stars Referral Modal Window
    window.ModalWindowReviewReferral = function (options) {
        return ModalWindow.call(this, 'review-referral', $.extend({}, options));
    };
    $.extend(ModalWindowReviewReferral.prototype, ModalWindow.prototype);

    // Referral Emails Sent Confirmation Failure Modal Window
    window.ModalWindowReferralConfirmationFailure = function (options) {
        return ModalWindow.call(this, 'referral-confirmation-failure', $.extend({}, options));
    };
    $.extend(ModalWindowReferralConfirmationFailure.prototype, ModalWindow.prototype);

    // Referral Post Purchase Modal
    window.ModalWindowReferral = function (options) {
        return ModalWindow.call(this, 'referral', $.extend({}, options));
    };
    $.extend(ModalWindowReferral.prototype, ModalWindow.prototype);

    // Courses Recommendation
    window.ModalWindowCourseRecommendation = function (options) {
        return ModalWindow.call(this, 'course-recommendation', $.extend({}, options));
    };
    $.extend(ModalWindowCourseRecommendation.prototype, ModalWindow.prototype);

    // Course Alternatives
    window.ModalWindowCourseAlternatives = function (options) {
        return ModalWindow.call(this, 'course-alternatives', $.extend({
            queryParams: {course_id: options.course_id}
        }, options));
    };
    $.extend(ModalWindowCourseAlternatives.prototype, ModalWindow.prototype);

    window.ModalWindowMapPopup = function (options) {
        return ModalWindow.call(this, 'map-popup', $.extend({}, options));
    };
    $.extend(ModalWindowMapPopup.prototype, ModalWindow.prototype);

    // Courses Recommendation
    window.ModalWindowCourseLeavingIntent = function (options) {
        return ModalWindow.call(this, 'course-leaving-intent', $.extend({}, options));
    };
    $.extend(ModalWindowCourseLeavingIntent.prototype, ModalWindow.prototype);

    // Section Interest Capture for courses not available for enrollments
    window.ModalWindowSectionInterestOverlay = function (options) {
        return ModalWindow.call(this, 'section-interest', $.extend({
            gaEventCategory: GA_CATEGORIES.EMAIL_CAPTURE,
            gaEventAction: 'Section Interest Overlay',
        }, options));
    };
    $.extend(ModalWindowSectionInterestOverlay.prototype, ModalWindow.prototype);

    window.ModalWindowUserDetails = function (options) {
        return ModalWindow.call(this, 'user-additional-details', $.extend({}, options));
    };
    $.extend(ModalWindowUserDetails.prototype, ModalWindow.prototype);

    // Wish List Lead Gen test
    window.ModalWindowWishListLeadGen = function (options) {
        return ModalWindow.call(this, 'wish-list-lead-gen', $.extend({}, options));
    };
    $.extend(ModalWindowWishListLeadGen.prototype, ModalWindow.prototype);

    // Register By Email
    window.ModalWindowRegisterEmail = function (options) {
        var self = this;
        return ModalWindow.call(this, 'register-email', $.extend({
            gaEventCategory: GA_CATEGORIES.EMAIL_CAPTURE,
            gaEventAction: 'RegisterEmail',
        }, options), function () {
            self.link(ModalManager.get('login'));
            options.onLoad && typeof options.onLoad === 'function' && options.onLoad(this);
        });
    };
    $.extend(window.ModalWindowRegisterEmail.prototype, ModalWindow.prototype);

});

