define('checkout/Validation',[
    'jquery',
    'semantic',
    'ch_semantic/validation',
], function ($) {
    /**
     * the overall checkout form element
     */
    var $checkoutForm;

    /**
     * the promo code field
     */
    var $promoCodeField;

    /**
     * the gift card field
     */
    var $giftCardCodeField;

    /**
     * Shared instance of the AmountManager
     * which handles pricing and summary updates
     */
    var AmountManager;

    /**
     * Sets up validation for a "code" field such as promo and gift cards
     *
     * On it's own, SemanticUI validation does not support async validation.
     *
     * This helps us make an async request to validate the code, then sets a "isValid" parameter that we can check
     * from a custom SemanticUI validation method.
     *
     * @param codeType      promo|gift
     * @param $codeField    the code field
     *
     * @returns {string}    the name of the validation method for use in SemanticUI validation rules
     */
    function initCodeValidation(codeType, $codeField) {
        var isCodeValid = null;
        var fieldName = $codeField.prop('name');
        var validationMethodName = 'valid' + fieldName;
        var $applyBtn = $codeField.siblings('.js-apply-code');
        var promptMsg;

        // closure to keep track of ajax error response
        function getPrompt(__value) {
            return promptMsg || (codeType === 'promo'
                ? 'Promo code is invalid'
                : 'Gift card is invalid');
        }

        // closure to keep track of ajax code validation status
        function validateCode(code) {
            var promiseToValidate;

            $applyBtn.addClass('loading');

            if (codeType === 'promo') {
                promiseToValidate = AmountManager.validatePromoCode(code);
            } else if (codeType === 'gift') {
                promiseToValidate = AmountManager.validateGiftCard(code);
            }

            promiseToValidate
                .then(function (result) {
                    if (result.status) {
                        isCodeValid = true;
                        promptMsg = undefined;
                        // valid!
                        $applyBtn
                            .removeClass('salmon red')
                            .addClass('green')
                            .html('<i class="ui check icon"></i>');
                    } else {
                        // invalid!
                        isCodeValid = false;
                        $checkoutForm.form('add prompt', fieldName, result.message);
                        promptMsg = result.message;
                        $applyBtn
                            .removeClass('green salmon loading')
                            .addClass('red')
                            .html('<i class="ui cancel icon"></i>');
                    }
                }).always(function () {
                    ga(
                        'send',
                        'event',
                        'Checkout',
                        codeType.charAt(0).toUpperCase() + codeType.slice(1) + ' Code Validation',
                        isCodeValid ? 'success' : 'failure',
                        { nonInteraction: false }
                    );
                    $checkoutForm.form('validate field', fieldName);
                    $applyBtn.removeClass('loading');
                    AmountManager.redrawPrices();
                });
        }

        function codeChangeHandler() {
            var codeValue = $codeField.val();
            if (!codeValue) {
                // empty.. reset button and clear validation errors
                isCodeValid = null;

                $codeField.siblings('.ui.button')
                    .removeClass('red green')
                    .html('Apply');

                if (codeType === 'promo') {
                    AmountManager.resetPromoCode();
                } else if (codeType === 'gift') {
                    AmountManager.resetGiftCard();
                }

                $checkoutForm.form('validate field', $codeField.prop('name'));
            } else if (
                (
                    codeType === 'promo'
                    && AmountManager.getCurrentPromoCode() != codeValue
                )
                || (
                    codeType === 'gift'
                    && AmountManager.getCurrentGiftCardCode() != codeValue
                )
            ) {
                // changed
                validateCode($codeField.val());
            }
        }

        $codeField.on('change', codeChangeHandler);

        $codeField.on('keydown', function (e) {
            if (e.which === 13) {
                e.preventDefault();
                e.stopImmediatePropagation();
                codeChangeHandler();
            }
        });

        $applyBtn.on('click', function (e) {
            e.preventDefault();
            var codeValue = $codeField.val();

            if (isCodeValid === false) {
                // clicking the [x] for an invalid promo code will clear the value
                $codeField
                    .val('')
                    .trigger('change');
                return;
            }

            if (!codeValue.trim()) {
                return;
            }

            validateCode(codeValue);
        });

        // dynamic validation rule
        $.fn.form.settings.rules[validationMethodName] = function () {
            return typeof isCodeValid !== 'boolean' || isCodeValid === true;
        };

        // force validation of codes on load
        if ($codeField.val()) {
            $codeField.trigger('change');
        }

        return {
            validationMethodName: validationMethodName,
            getPrompt: getPrompt,
        };
    }

    /**
     * Set up validation for the checkout form(s)
     *
     * note: semanticUi does not support async validation
     * so we maintain state in isPromoCodeValid, otherwise don't invalidate
     *
     */
    function addValidationRules() {
        var promoCodeValidator = initCodeValidation('promo', $promoCodeField);
        var giftCardCodeValidator = initCodeValidation('gift', $giftCardCodeField);

        var discountFields = {
            promo_code: {
                identifier: 'promo_code',
                rules: [
                    {
                        type: promoCodeValidator.validationMethodName,
                        prompt: promoCodeValidator.getPrompt,
                    },
                ],
            },
            gift_card_code: {
                identifier: 'gift_card_code',
                rules: [
                    {
                        type: giftCardCodeValidator.validationMethodName,
                        prompt: giftCardCodeValidator.getPrompt,
                    },
                ],
            },
        };

        $.fn.form.settings.rules.validateRewardPoints = function (value) {
            return AmountManager.isRewardsPointsValid(value);
        };

        $.fn.form.settings.rules.emailCanPurchase = function (value) {
            // if we don't have a user yet, user can purchase
            return !window.CH || !window.CH.checkout || !window.CH.checkout.user || window.CH.checkout.user.can_purchase;
        };

        var paymentFields = {
            payment_method: {
                identifier: 'payment_method',
                rules: [
                    {
                        type: 'checked',
                        prompt: 'You must select a payment method',
                    },
                ],
            },
            card_name: {
                identifier: 'card_name',
                rules: [
                    {
                        type: 'empty',
                        prompt: 'Payment name is required',
                    },
                    {
                        type: 'minLength[2]',
                        prompt: 'Payment name must be between 2 and 128 characters',
                    },
                    {
                        type: 'nameText',
                        prompt: 'Payment name can only contain letters, numbers and a few special characters: - . , \'',
                    },
                ],
            },
            reward_points_dollars: {
                identifier: 'reward_points_dollars',
                optional: true,
                rules: [
                    {
                        type: 'decimal',
                        prompt: 'Invalid reward points',
                    },
                    {
                        type: 'validateRewardPoints',
                        prompt: 'Invalid reward points',
                    },
                ],
            },
        };

        var attendeeFields = {
            student_first_name_1: {
                identifier: 'student_first_name_1',
                rules: [
                    {
                        type: 'empty',
                        prompt: 'Attendee first name is missing',
                    },
                    {
                        type: 'minLength[2]',
                        prompt: 'First name must be between 2 and 128 characters',
                    },
                    {
                        type: 'nameText',
                        prompt: 'First name can only contain letters, numbers and a few special characters: - . , \'',
                    },
                ],
            },
            student_last_name_1: {
                identifier: 'student_last_name_1',
                rules: [
                    {
                        type: 'empty',
                        prompt: 'Attendee last name is missing',
                    },
                    {
                        type: 'minLength[2]',
                        prompt: 'Last name must be between 2 and 128 characters',
                    },
                    {
                        type: 'nameText',
                        prompt: 'Last name can only contain letters, numbers and a few special characters: - . , \'',
                    },
                ],
            },
            student_email_1: {
                identifier: 'student_email_1',
                rules: [
                    {
                        type: 'empty',
                        prompt: 'Attendee email is missing',
                    },
                    {
                        type: 'email',
                        prompt: 'Attendee email is invalid',
                    },
                ],
            },
            delivery_date: {
                identifier: 'delivery_date',
                optional: true,
                rules: [
                    {
                        type: 'usDateFormat',
                        prompt: 'Delivery date is invalid',
                    },
                ],
            },
        };

        var normalFields = {
            first_name: {
                identifier: 'first_name',
                rules: [
                    {
                        type: 'empty',
                        prompt: 'Your first name is missing',
                    },
                    {
                        type: 'minLength[2]',
                        prompt: 'First name must be between 2 and 128 characters',
                    },
                    {
                        type: 'nameText',
                        prompt: 'First name can only contain letters, numbers and a few special characters: - . , \'',
                    },
                ],
            },
            last_name: {
                identifier: 'last_name',
                rules: [
                    {
                        type: 'empty',
                        prompt: 'Your last name is missing',
                    },
                    {
                        type: 'minLength[2]',
                        prompt: 'Last name must be between 2 and 128 characters',
                    },
                    {
                        type: 'nameText',
                        prompt: 'Last name can only contain letters, numbers and a few special characters: - . , \'',
                    },
                ],
            },
            email: {
                identifier: 'email',
                rules: [
                    {
                        type: 'empty',
                        prompt: 'Your email is missing',
                    },
                    {
                        type: 'email',
                        prompt: 'Your email is invalid',
                    },
                    {
                        type: 'emailCanPurchase',
                        prompt: 'That email cannot be used to purchase',
                    },
                ],
            },
            phone: {
                identifier: 'phone',
                rules: [
                    {
                        type: 'empty',
                        prompt: 'Your phone number is missing',
                    },
                    {
                        type: 'phone',
                        prompt: 'Your phone number is invalid',
                    },
                ],
            },
        };

        var additionalInfoFields = {
            country: {
                identifier: 'country',
                rules: [
                    {
                        type: 'empty',
                        prompt: 'Your country is missing',
                    },
                ],
            },
            address1: {
                identifier: 'address1',
                rules: [
                    {
                        type: 'empty',
                        prompt: 'Your address is missing',
                    },
                    {
                        type: 'minLength[2]',
                        prompt: 'Address line 1 is expected to be between 2 and 128 characters',
                    },
                    {
                        type: 'addressText',
                        prompt: 'Address can only contain letters, numbers and select special characters: - . , ; : \'',
                    },
                ],
            },
            address2: {
                identifier: 'address2',
                optional: true,
                rules: [
                    {
                        type: 'minLength[2]',
                        prompt: 'Address line 2 is expected to be between 2 and 128 characters',
                    },
                    {
                        type: 'addressText',
                        prompt: 'Address can only contain letters, numbers and select special characters: - . , ; : \'',
                    },
                ],
            },
            city: {
                identifier: 'city',
                rules: [
                    {
                        type: 'empty',
                        prompt: 'Your city is missing',
                    },
                    {
                        type: 'minLength[2]',
                        prompt: 'City is expected to be between 2 and 128 characters',
                    },
                    {
                        type: 'addressText',
                        prompt: 'City can only contain letters, numbers and select special characters: - . , ; : \'',
                    },
                ],
            },
            state: {
                identifier: 'state',
                rules: [
                    {
                        type: 'empty',
                        prompt: 'Your state is missing',
                    },
                ],
            },
            zip: {
                identifier: 'zip',
                rules: [
                    {
                        type: 'empty',
                        prompt: 'Your zip is missing',
                    },
                    {
                        type: 'maxLength[5]',
                        prompt: 'We only need your 5 digit zip code',
                    },
                    {
                        type: 'usPostal',
                        prompt: 'Zip code is invalid',
                    },
                ],
            },
            birth_date: {
                identifier: 'birth_date',
                rules: [
                    {
                        type: 'empty',
                        prompt: 'Your birth date is missing',
                    },
                    {
                        type: 'usDateFormat',
                        prompt: 'Birth date must be formatted as MM/DD/YYYY',
                    },
                    {
                        type: 'birthdate',
                        prompt: 'Birth date should be in the past',
                    },
                ],
            },
            gender: {
                identifier: 'gender',
                rules: [
                    {
                        type: 'empty',
                        prompt: 'Your gender is missing',
                    },
                ],
            },
            emergency_name: {
                identifier: 'emergency_name',
                rules: [
                    {
                        type: 'empty',
                        prompt: 'Your emergency contact\'s name is missing',
                    },
                    {
                        type: 'minLength[2]',
                        prompt: 'Emergency contact name is expected to be between 2 and 128 characters',
                    },
                    {
                        type: 'nameText',
                        prompt: 'Emergency name can only contain letters, numbers and a few special characters: - . , \'',
                    },
                ],
            },
            emergency_email: {
                identifier: 'emergency_email',
                rules: [
                    {
                        type: 'empty',
                        prompt: 'Your emergency contact\'s email is missing',
                    },
                    {
                        type: 'email',
                        prompt: 'Your emergency contact\'s email is invalid',
                    },
                ],
            },
            emergency_phone: {
                identifier: 'emergency_phone',
                rules: [
                    {
                        type: 'empty',
                        prompt: 'Your emergency contact\'s phone is missing',
                    },
                    {
                        type: 'phone',
                        prompt: 'Your emergency contact\'s phone is invalid',
                    },
                ],
            },
            emergency_relation: {
                identifier: 'emergency_relation',
                rules: [
                    {
                        type: 'empty',
                        prompt: 'Your emergency contact\'s relation is missing',
                    },
                    {
                        type: 'minLength[2]',
                        prompt: 'Emergency contact relationship is expected to be between 2 and 128 characters',
                    },
                ],
            },
        };

        var providerTermsOfServiceFields = {
            agree_to_provider_terms: {
                identifier: 'agree_to_provider_terms',
                rules: [
                    {
                        type: 'checked',
                        prompt: 'You must agree to the provider’s terms of service',
                    },
                ],
            },
        }

        var validationRules = Object.assign({},
            normalFields,
            additionalInfoFields,
            paymentFields,
            discountFields,
            attendeeFields,
            providerTermsOfServiceFields);

        // note: dynamically added fields that need validating will expect the Semantic Form to be re-initalized
        // otherwise the form api will not recognize new fields (to validate them)
        // so we expose this method so other modules can "re init" the form w/out having to know about the original config
        window.CH.checkout.initializeForm = function () {
            $checkoutForm
                .form({
                    debug: false,
                    verbose: false,
                    inline: true,
                    on: 'blur',
                    fields: validationRules,
                    onInvalid: function (errors) {
                        ga(
                            'send',
                            'event',
                            'Checkout',
                            'Field Invalid - ' + $(this).prop('name'),
                            errors.join(', '),
                            { nonInteraction: false }
                        );
                    },
                });
        };
        window.CH.checkout.initializeForm();
    }

    return {
        init: function validationInit(options) {
            $checkoutForm = options.$form;
            AmountManager = options.AmountManager;
            $giftCardCodeField = options.$giftCardCodeField;
            $promoCodeField = options.$promoCodeField;

            addValidationRules();
        },
    };
});

