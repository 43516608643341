/**
 * ch_grouper
 *
 * controls grouper related lead gen, modals, etc
 *
 * Originally this was geared *just* towards Grouper but eventually
 * Came to be used for generic "group/private" lead gen
 */
define('ch_grouper',[
    'ch_utils',
    'sweetalert', // swal
    'modals',
    'fetching',
], function (utils, swal, modals, fetching) {
    function getCourseInfo() {
        if (window.CH && window.CH.coursePage && window.CH.coursePage.courseId) {
            return {
                id: window.CH.coursePage.courseId,
                name: window.CH.coursePage.courseName,
            };
        }
        return false;
    }

    /**
     * initializes a modal which prompts the user for "request to book" specific questions
     *
     * this is intended for use only on a course page
     *
     * @param triggerElementSelector
     */
    function initializeGrouperRequestToBookModal(triggerElementSelector) {
        const modalElement = document.getElementById('grouper-request-to-book-modal');
        const formElement = modalElement.querySelector('form.ui.form');

        if (!modalElement || !formElement) {
            console.log('Grouper modal not set up');
            return;
        }

        // disable standard submits in favor of a static button we're using

        // set up field behaviors
        // $('.dropdown', $form)
        //     .dropdown();

        let currentCourse = getCourseInfo();
        if (currentCourse) {
            modalElement.querySelector('.js-course-name').innerHTML = currentCourse.name;
            formElement.querySelector('input[name=course_name]').value = currentCourse.name;
            formElement.querySelector('input[name=course_id]').value = currentCourse.id;
        }

        formElement.addEventListener('submit', (e) => {
            e.preventDefault();

            const viewingCourseId = (currentCourse && currentCourse.id) || 'NA';
            const formDataToPost = new FormData(formElement);
            const groupSize = formDataToPost.get('group_size');

            let gaEvent = formElement.dataset.gaAction || 'Not set';
            let gaAction = `${window.location.pathname} - ${viewingCourseId};${groupSize}`;

            formDataToPost.append('url', window.location.pathname + window.location.search);

            fetching
                .json('/user/subscription/grouper-intent', {
                    method: 'POST',
                    body: formDataToPost,
                }).then(() => {
                    ga(
                        'send',
                        'event',
                        'Grouper',
                        gaEvent,
                        gaAction,
                        { nonInteraction: false }
                    );

                    modals.closeModal(modalElement);

                    swal({
                        title: 'Thank you!',
                        text: 'We\'ll be in touch within 2 business hours with pricing and availability information.',
                        showConfirmButton: true,
                        confirmButtonText: 'OK',
                        closeOnEsc: true,
                        allowOutsideClick: true,
                    });
                });
        });

        utils.getElementsAndAddListener(triggerElementSelector, 'click', (e) => {
            e.preventDefault();

            // newer event versus "viewed nav site pop-up"
            triggerEventOnElement(e.currentTarget);

            // passing along the gaEvent value...
            if (e.currentTarget.dataset.gaEvent) {
                formElement.dataset.gaEvent = e.currentTarget.dataset.gaEvent;
            }

            ga(
                'send',
                'event',
                'Grouper',
                'viewed nav site pop-up',
                window.location.pathname,
                { nonInteraction: false }
            );

            modals.showModal(modalElement, {
                closeCallback: function() {
                    ga(
                        'send',
                        'event',
                        'Grouper',
                        'dismissed nav site pop-up',
                        window.location.pathname,
                        { nonInteraction: false }
                    );
                },
            });

            formElement.reset();
        });
    }

    // @todo - re-enable if we ever use this again
    // does not appear to be used ...
    // function initializeGrouperIntentModal(triggerElementSelector) {
    //     const $modal = $('#grouper-intent-modal');
    //
    //     if (!$modal.length) {
    //         return;
    //     }
    //
    //     const $form = $('.ui.form', $modal);
    //
    //     $form.form({
    //         on: 'change',
    //         inline: true,
    //         fields: {
    //             group_size: {
    //                 rules: [
    //                     {
    //                         type: 'empty',
    //                         prompt: "Please enter your group's size.",
    //                     },
    //                     {
    //                         type: 'integer[1..100]',
    //                         prompt: 'Please only enter numbers from 1 to 100',
    //                     },
    //                 ],
    //             },
    //         },
    //         onSubmit: e => e.preventDefault(),
    //     });
    //
    //     $('.dropdown', $form)
    //         .dropdown();
    //
    //     // grouper cta that displays the grouper intent modal
    //     // expects a "data-ga-event" attribute, which is sent to GA when user submits the modal
    //     // and a "data-ga-cta-label" attribute, which is used when the user clicks the CTA that opens the modal
    //     $(triggerElementSelector)
    //         .on('click', (e) => {
    //             e.preventDefault();
    //             // newer event versus "viewed nav site pop-up"
    //             triggerEventOnElement(e.currentTarget);
    //             triggerModal(
    //                 $modal,
    //                 $form,
    //                 e.currentTarget.href,
    //                 e.currentTarget.dataset.gaEvent
    //             );
    //         });
    // }


    function triggerEvent(gaAction, nonInteraction = false) {
        let gaLabel = window.location.pathname;
        let course = getCourseInfo();

        if (course && course.id) {
            gaLabel += ' - ' + course.id;
        }

        ga(
            'send',
            'event',
            'Grouper',
            gaAction,
            gaLabel,
            { nonInteraction: nonInteraction }
        );
    }

    /**
     * Trigger a Grouper CTA GA Event for a given element
     * expects element to define:
     *  data-ga-cta-label
     *
     * Ga Event:
     *  Category: Grouper
     *  Action: CTA ${data-ga-event}
     *  Label: ${URL}-${COURSE_ID_IF_AVAILBLE}
     *
     * @param element
     * @param nonInteraction
     */
    function triggerEventOnElement(element, nonInteraction = false) {
        let gaAction = element.dataset.gaCtaLabel || 'CTA ...';

        triggerEvent(gaAction, nonInteraction);
    }

    return {
        triggerEventOnElement,
        triggerEvent,
        //initializeGrouperIntentModal,
        initializeGrouperRequestToBookModal,
    };
});

