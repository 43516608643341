define('ch_wishlist',[
    "jquery",
    "ch_selectors",
    "ch_load_mask",
    "ch_modal_window",
    "ch_popup"
], function($) {
    $(function() {
        CH.addToWishlist = function(wishlistLink, showCategoryPreferences, showSuccessMessage) {
            var course_id = wishlistLink.attr('data-course-id');
            var showAlert = wishlistLink.attr('data-show-alert');
            if (showAlert) {
                showSuccessMessage = true;
            }
            if (!course_id) return false;
            $.post('/user/wishlist/add-to-wishlist', {course_id: course_id}, function(res) {
                CH.postAddToWishlist(res, wishlistLink, showCategoryPreferences, showSuccessMessage);
            });
        };

        CH.postAddToWishlist = function(res, wishlistLink, showCategoryPreferences, showSuccessMessage) {
            if (!res.success && res.error) {
                new ModalMessage({message: res.error});
                return;
            }

            var course = res.course || {};

            ga('send', 'event', 'Wishlist', 'Add', window.location.pathname + ' ' + course.name, {
                nonInteraction: false,
            });

            var userWishListCount = $._('user-wishlist-count');
            var courseWishListCount = $._('course-wishlist-count');
            userWishListCount.html(parseInt(userWishListCount.html()) + 1);
            courseWishListCount.html(parseInt(courseWishListCount.html()) + 1);

            if (wishlistLink) {
                var postWishlistText = wishlistLink.attr('data-post-wishlist-text');
                wishlistLink.popUp().hide_tooltip();
                wishlistLink.removeClass('add-to-wishlist');
                wishlistLink.addClass('added-to-wishlist');
                wishlistLink.unbind('click');
                var inWishlistText =  postWishlistText || 'Class in Wish List';

                if (wishlistLink.data("js-wishlist-type") == "wishlist_picture_button") {
                    wishlistLink.show();
                }
                else {
                    if (wishlistLink.data('post-wishlist')) {
                        wishlistLink.parent().html('This class isn\'t on the schedule at the moment, but you\'ve added it to your <a href="/wishlist">Wish List</a>, so we\'ll notify you when it comes back.');
                    }
                    else if (wishlistLink.hasClass('pink')) {
                        wishlistLink.html(inWishlistText);
                    }
                    else {
                        wishlistLink.html('<span class="wishlist_heart"></span><span class="wishlist_text">' + inWishlistText + '</span>');
                    }
                }
            }

            if (showCategoryPreferences) {
                CH.showCategoryPreferencesWindow(true);
            }

            if (showSuccessMessage) {
                swal({
                    type: 'success',
                    title: 'Awesome!',
                    text: 'Awesome! ' + course.name + ' has been added to your wish list.',
                });
            }
        }

        // Add to wishlist
        $._('add-to-wishlist').click(function(e, showCategoryPreferences, showSuccessMessage) {
            // ☠?
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }

            var self = $(this);
            if (self.data('dismiss')) {
                self.parents('.modal').hide();
            }

            var showCategoryPreferences = showCategoryPreferences !== undefined ? showCategoryPreferences : true;
            var showSuccessMessage = showSuccessMessage !== undefined ? showSuccessMessage : false;

            if (!CH.getCookie("ch_i", { path: '/' })) {
                var ModalWindowWishlistSignupObj = new ModalWindowWishlistSignup();
                ModalWindowWishlistSignupObj.show({
                    onSuccess: $.proxy(CH.addToWishlist, this, self, showCategoryPreferences, showSuccessMessage),
                    location: self.attr('data-location')
                });
                return;
            }

            CH.addToWishlist(self, showCategoryPreferences, showSuccessMessage);
        });

        $._('remove-from-wishlist').click(function(e) {
            if (e) e.preventDefault();
            var wishlistLink = $(e.currentTarget);
            CH.course_id = wishlistLink.attr('data-course-id');
            if (!CH.course_id) return;

            swal({
                title: 'Please Confirm',
                text: 'Are you sure you want to delete this class from your wishlist?',
                type: 'warning',
                showCancelButton: true
            }, function() {
                $.ajax({
                    type: 'POST',
                    url: '/user/wishlist/remove-from-wishlist',
                    dataType: 'json',
                    data: {
                        course_id: CH.course_id
                    },
                    success: function(res) {
                        if (res.success) {
                            ga('send', 'event', 'Wishlist', 'Remove', window.location.pathname + ' ' + res.course.name, {
                                nonInteraction: false,
                            });

                            var userWishListCount = $._('user-wishlist-count');
                            var courseWishListCount = $._('course-wishlist-count');
                            userWishListCount.html(parseInt(userWishListCount.html()) - 1);
                            courseWishListCount.html(parseInt(courseWishListCount.html()) - 1);
                            window.location.reload();
                        }
                        else {
                            new ModalMessage({
                                message: res.error,
                                callback: function() {
                                    // Since an error message was displayed to user
                                    // lets reload wish-list content
                                    window.location.reload();
                                }
                            });
                        }
                    }
                })
            });
        });

        if (!CH.isMobile && !CH.isNewSemanticStyle) {
            //Popup notifier for the button "Add to Wish List"
            $('[data-js-wishlist-type="wishlist_button"]').popUp({
                type: 'tooltip',
                content: '<div class="dropdown-menu-holder" style="padding: 10px;">Save to your wish list to get class updates and discounts</div>',
                on: 'hover',
                keepAlive: false,
                maxWidth: '140px',
                tooltipTopOffset: -25,
                tooltipLeftOffset: -8,
                cornerLeftOffset: 0,
                closeButton: false,
                closeByMouseout: true,
                closeByClickOutside: true,
                single: true,
                cssClass: 'common-dropdown wishlist-dropdown'
            });

            //Popup notifier for the picture button "Add to Wish List"
            $('[data-js-wishlist-type="wishlist_picture_button"], [data-js-wishlist-type="wishlist_text"]').popUp({
                type: 'tooltip',
                content: '<div class="dropdown-menu-holder" style="padding: 10px;">Save to your wish list to get class updates and discounts</div>',
                on: 'hover',
                keepAlive: false,
                maxWidth: '140px',
                tooltipTopOffset: -12,
                tooltipLeftOffset: 3,
                cornerLeftOffset: 0,
                closeButton: false,
                closeByMouseout: true,
                closeByClickOutside: true,
                single: true,
                cssClass: 'common-dropdown wishlist-dropdown'
            });
        }

        if (CH.courseAddedToWishlist) {
            CH.postAddToWishlist({
                success: true,
                course: {
                    name: CH.courseAddedToWishlist
                }
            }, null, null, true);
        }
    });
});

