define('ch_form_values',[
    "jquery"
], function($) {
    $.fn.formValues = function() {
        var o = {};
        var a = this.serializeArray();
        $.each(a, function() {
            if (o[this.name] == undefined) {
                o[this.name] = this.value || '';
            }
            else if (/\[\]$/.test(this.name)) {
                if (!o[this.name].push) {
                    o[this.name] = [o[this.name]];
                }
                o[this.name].push(this.value || '');
            }
            else {
                o[this.name] = this.value || '';
            }
        });
        return o;
    };
});

