/*
*   This content is licensed according to the W3C Software License at
*   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
*
*   File:   tabs-automatic.js
*
*   Desc:   Tablist widget that implements ARIA Authoring Practices
*/

define('ch_tabs_automatic',[], function () {
    function tabsAutomatic(groupNode) {
        const tablistNode = groupNode;

        const tabs = Array.from(tablistNode.querySelectorAll('[role=tab]'));
        const tabpanels = [];

        let firstTab = null;
        let lastTab = null;

        function setSelectedTab(currentTab, setFocus = true) {
            tabs.forEach((tab, i) => {
                if (tab === currentTab) {
                    tab.setAttribute('aria-selected', 'true');
                    tab.removeAttribute('tabindex');
                    tabpanels[i].removeAttribute('hidden');
                    if (setFocus) {
                        tab.focus();
                    }
                } else {
                    tab.setAttribute('aria-selected', 'false');
                    tab.tabIndex = -1;
                    tabpanels[i].setAttribute('hidden', '');
                }
            });
        }

        function setSelectedToPreviousTab(currentTab) {
            if (currentTab === firstTab) {
                setSelectedTab(lastTab);
            } else {
                const index = tabs.indexOf(currentTab);
                setSelectedTab(tabs[index - 1]);
            }
        }

        function setSelectedToNextTab(currentTab) {
            if (currentTab === lastTab) {
                setSelectedTab(firstTab);
            } else {
                const index = tabs.indexOf(currentTab);
                setSelectedTab(tabs[index + 1]);
            }
        }

        function onKeydown(event) {
            const tgt = event.currentTarget;
            let flag = false;

            switch (event.key) {
            case 'ArrowLeft':
                setSelectedToPreviousTab(tgt);
                flag = true;
                break;
            case 'ArrowRight':
                setSelectedToNextTab(tgt);
                flag = true;
                break;
            case 'Home':
                setSelectedTab(firstTab);
                flag = true;
                break;
            case 'End':
                setSelectedTab(lastTab);
                flag = true;
                break;
            default:
                break;
            }

            if (flag) {
                event.stopPropagation();
                event.preventDefault();
            }
        }

        function onClick(event) {
            setSelectedTab(event.currentTarget);
        }

        tabs.forEach((tab) => {
            const tabpanel = document.getElementById(tab.getAttribute('aria-controls'));

            tab.tabIndex = -1;
            tab.setAttribute('aria-selected', 'false');
            tabpanels.push(tabpanel);

            tab.addEventListener('keydown', onKeydown);
            tab.addEventListener('click', onClick);

            if (!firstTab) {
                firstTab = tab;
            }
            lastTab = tab;
        });

        setSelectedTab(firstTab, false);
    }

    return tabsAutomatic
})

;
