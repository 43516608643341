define('ch_semantic/validation',[
    'jquery',
    'dayjs',
    'semantic',
    'dayjsCustomParseFormat',
], function ($, dayjs) {
    function isValidDateFormat(value) {
        return (/^_{2}\u002F_{2}\u002F_{4}?$/.test(value) || /^\d{2}\u002F\d{2}\u002F\d{4}?$/.test(value));
    }

    $.fn.form.settings.rules.phone = function (value) {
        var copy = value.replace(/[^0-9]/g, '').trim();
        return value.trim().length === 0 || /^\d{10,15}?$/.test(copy);
    };

    $.fn.form.settings.rules.usPostal = function (value) {
        return /^\d{5}(-\d{4})?$/.test(value);
    };

    $.fn.form.settings.rules.usDateFormat = function (value) {
        return dayjs(value).isValid() && isValidDateFormat(value);
    };

    $.fn.form.settings.rules.birthdate = function (value) {
        const date = dayjs(value);
        return date.isValid() && date.isBefore(dayjs());
    };

    $.fn.form.settings.rules.addressText = function (value) {
        return /^[-.,;:'a-z0-9 ]+$/i.test(value);
    };

    $.fn.form.settings.rules.nameText = function (value) {
        return /^[-.,'a-z0-9 ]+$/i.test(value);
    };
});

