define('ch_semantic_wishlist',[
    "jquery",
    "ch_load_mask",
    "ch_modal_window",
    "ch_popup",
    "semantic",
    "sweetalert"
], function($) {
    // Local scope variable(s)
    var $userWishlistCount;

    // Local scope function(s)

    // Checks for any special commands to be executed due to window.location.hash
    var checkHashes = function() {
        var hash = window.location.hash.split('=');
        var action = hash[0];
        var param = hash[1]; // this is undefined if no = (i.e. "#wishlist")
        if (action === '#wishlist') {
            triggerWishlist(param) // it's okay for param to undefined
        }
    }

    // Triggers an add to wishlist request for the course with ID courseId (or first link on page)
    var triggerWishlist = function(courseId) {
        var dataLocation = 'Using #wishlist from being signed out';
        var sourceEventAction = (window.location.pathname === '/wishlist' ? 'Add Wishlist Page Recommendation' : null);

        if (!courseId) {
            courseId = CH.coursePage.courseId; // this is set on semantic-course-page

            if (!courseId) {
                return;  //otherwise do nothing
            }

        }

        // still using attrs on DOM obj, but these are more 'optional' incase DOM obj is gone

        // avoid using .trigger('click') instead using exact abstracted method we want to call
        addToWishlist(courseId, {
            sourceEventAction: sourceEventAction,
            dataLocation: dataLocation, // only used if has no ch_i
            showSuccessMessage: true, // Always display a success message after refresh
        });
    }

    /**
     * postAddToWishlist
     *
     * invoked after a user adds something to their wishlist...
     *
     * @param res
     * @param courseId
     * @param showCategoryPreferences
     * @param showSuccessMessage
     * @param sourceEventAction
     */
    var postAddToWishlist = function(
        res,
        courseId,
        showCategoryPreferences,
        showSuccessMessage,
        sourceEventAction
    ) {
        var eventAction = sourceEventAction || 'Add';
        var isFromWishlistPage = false;
        var course = res.course || {};

        if (window.location.pathname === '/wishlist') {
            isFromWishlistPage = true;
            eventAction = 'Add Wishlist Page Recommendation';
        }

        if (!res.success && res.error) {
            new ModalMessage({message: res.error});
            return;
        }

        CH.wishlistedTitle = window.location.pathname + ' ' + course.name;
        ga('send', 'event', 'Wishlist', eventAction, CH.wishlistedTitle, {
            nonInteraction: false,
        });

        // Update users global wishlist item counter
        $userWishlistCount.html(parseInt($userWishlistCount.html()) + 1);

        $('.course-' + course.id).each(function() {
            // Update wishlist item class
            var $wishlistItem = $(this);

            $wishlistItem
                .removeClass('add-to-wishlist')
                .addClass('added-to-wishlist')
                .unbind('click');

            if ($wishlistItem.data('toggle-inverted')) {
                $wishlistItem.addClass('inverted');
            }

            if ($wishlistItem.data('js-wishlist-type') === 'wishlist_picture_button') {
                $wishlistItem.show();
            } else if ($wishlistItem.data('post-wishlist')) {
                $wishlistItem
                    .parent()
                    .html(
                        'This class isn\'t on the schedule at the moment,'
                        + ' but you\'ve added it to your <a href="/wishlist">Wish List</a>,'
                        + ' so we\'ll notify you when it comes back.'
                    );
            } else if ($wishlistItem.data('post-wishlist-replacement')) {
                $wishlistItem
                    .parent()
                    .html(
                        $wishlistItem.data('post-wishlist-replacement')
                    );
            } else if (
                $wishlistItem.data('js-wishlist-type') === 'wishlist_button' &&
                ($wishlistItem.data('saved-text') || $wishlistItem.data('saved-alert-text'))
            ) {
                if ($wishlistItem.hasClass('js-mobile-wishlist-card')) {
                    $wishlistItem.children('.content').html($wishlistItem.data('saved-alert-text'));
                    $wishlistItem.children('.extra.content').html($wishlistItem.data('saved-action-text'));
                    $wishlistItem.attr('href', $wishlistItem.data('href'));
                } else {
                    $wishlistItem.html($wishlistItem.data('saved-text'));
                }
            } else {
                $wishlistItem.html(
                    '<span class="wishlist_heart"></span>'
                    + '<span class="wishlist_text">Class in Wish List</span>'
                );
            }
        });

        // if price is right AND user has not asked us to stop asking them
        if (
            course.is_inquiry_to_school &&
            CH.getCookie('ch_wllg') != '1'
        ) {
            CH.showWishListLeadGenWindow(
                course,
                showCategoryPreferences,
                showSuccessMessage,
                isFromWishlistPage
            );
        } else {
            wishListNextStep(
                course,
                showCategoryPreferences,
                showSuccessMessage,
                isFromWishlistPage
            );
        }
    }

    var addToWishlist = function(courseId, opts) {
        // Set the options to variables (may be undefined)
        //TODO: ES6 destructuring/spread
        var _opts = opts || {};
        var sourceEventAction = _opts.sourceEventAction;
        var dataLocation = _opts.dataLocation;
        var showCategoryPreferences = _opts.showCategoryPreferences;
        var showSuccessMessage = _opts.showSuccessMessage;
        var addCourseToWishlist = function (courseId, showCategoryPreferences, showSuccessMessage, sourceEventAction) {
            if (!courseId) {
                return false;
            }

            // Finally make the ajax post request to add the course to user wishlist
            $.post('/user/wishlist/add-to-wishlist', {course_id: courseId}, function(res) {
                postAddToWishlist(res, courseId, showCategoryPreferences, showSuccessMessage, sourceEventAction);
            });
        }

        showSuccessMessage = (showSuccessMessage !== undefined) ? showSuccessMessage : false;

        // 1) no identity in the cookie then prompt user to create a new wishlist
        // 2) else just save the course into the existing user wishlist
        if (!CH.getCookie("ch_i", { path: '/' })) {
            showCategoryPreferences = (showCategoryPreferences !== undefined) ? showCategoryPreferences : true;
            var ModalWindowWishlistSignupObj = new ModalWindowWishlistSignup({
                linkedInstanceOptions: {
                    onSuccess: function() {
                        // ignoring the isFromWishlistPage as that will be determined when emulating click
                        window.location.hash = "#wishlist=" + courseId;
                        location.reload();
                    }
                }
            });
            ModalWindowWishlistSignupObj.show({
                onSuccess: addCourseToWishlist.bind(null, courseId, showCategoryPreferences, showSuccessMessage, sourceEventAction),
                location: dataLocation
            });
        } else {
            showCategoryPreferences = (showCategoryPreferences !== undefined) ? showCategoryPreferences : false;
            addCourseToWishlist(courseId, showCategoryPreferences, showSuccessMessage, sourceEventAction);
        }
    }

    // Exposed an entry point for binding wishlist heart icons on demand. This is useful
    // when loading partials i.e. recommendations block.
    CH.initSemanticWishlist = function() {
        // Add click handle for add-to-wishlist
        $('.add-to-wishlist').on('click', function (e, showCategoryPreferences, showSuccessMessage) {

            // Local scope variables
            var $self = $(this);
            var courseId = $self.attr('data-course-id');
            var sourceEventAction = $self.attr('data-source-event-action');
            var dataLocation = $self.attr('data-location');

            if (!courseId) {
                return;
            }

            e.preventDefault();

            if ($self.attr('data-show-alert')) {
                showSuccessMessage = true;
            }

            addToWishlist(courseId, {
                sourceEventAction: sourceEventAction,
                dataLocation: dataLocation,
                showCategoryPreferences: showCategoryPreferences,
                showSuccessMessage: showSuccessMessage,
            });

        });

        // Prompt user for course wishlist removal
        $('.wishlist-page-saved-course').click(function (e) {
            e.preventDefault();

            // Local scope variables
            var $self = $(this);
            var $courseCard = $self.closest('.card');
            var courseId = $self.attr('data-course-id');

            if (!courseId) {
                return;
            }

            swal({
                title: 'Please Confirm',
                text: 'Are you sure you want to remove this class from your wishlist?',
                type: 'warning',
                showCancelButton: true
            }, function() {
                // immediately behave like it succeeded
                $courseCard.fadeOut('slow');
                $userWishlistCount.html(parseInt($userWishlistCount.html()) - 1);

                $.post(
                    '/user/wishlist/remove-from-wishlist',
                    { course_id: courseId },
                    function(res) {
                        if (!res.success && res.error) {
                            new ModalMessage({
                                message: res.error,
                                callback: function() {
                                    window.location.reload();
                                }
                            });
                        }

                        ga('send', 'event', 'Wishlist', 'Remove', window.location.pathname + ' ' + res.course.name, {
                            nonInteraction: false
                        });

                        window.location =  "https://" + window.location.host + window.location.pathname;
                    }
                );
            });

        });
    }

    CH.courseAlternativesOptions = {
        onShow: function () {
            var $window = $(this.window);

            var popupType = CH.isPostWishlist ? 'Post Wishlist' : 'Out of Stock';
            var wishlistedTitle = typeof classData == 'object'
                ? window.location.pathname + ' | ' + classData.providerName + ': ' + classData.courseName
                : CH.wishlistedTitle;
            if (CH.isPostWishlist) {
                $('.out-of-stock-only', $window).hide();
            }

            ga(
                'send',
                'event',
                'Course Out of Stock Course Recommendation Popup',
                'Popup Shown',
                wishlistedTitle + ' | ' + $window.data('category') + ' | ' + popupType,
                { nonInteraction: false }
            );

            $('.rec-course', $window).on('click', function (e) {
                ga(
                    'send',
                    'event',
                    'Course Out of Stock Course Recommendation Popup',
                    'Course Recommendation Clicked',
                    wishlistedTitle + ' | ' + $window.data('category') + ' | ' + popupType,
                    { nonInteraction: false }
                );
            });

            var $addToWishlistBtn = $('.add-to-wishlist', $window);

            if ($('.added-to-wishlist', $window).length > 0) {
                $('.wishlist-text', $window).hide();
                $('.wishlist-button', $window).hide();

            } else if ($addToWishlistBtn.length > 0) {

                $addToWishlistBtn.on('click', function (e) {
                    ga(
                        'send',
                        'event',
                        'Course Out of Stock Course Recommendation Popup',
                        'Add to Wishlist Clicked',
                        wishlistedTitle + ' | ' + $window.data('category') + ' | ' + popupType,
                        { nonInteraction: false }
                    );
                });

            }

        }
    };

    CH.showWishListLeadGenWindow = function(
        course,
        showCategoryPreferences,
        showSuccessMessage,
        isFromWishlistPage
    ) {
        var eventCategory = 'Leadgen';
        var eventLabel = (CH.isUserWithIdentity
                ? 'existing wishlister'
                : 'new wishlister'
            )
            + ' | '
            + (CH.is_filter_page
                ? 'browse'
                : (CH.is_course_page
                    ? 'course'
                    : 'other')
            ) + ' page'
            + ' | '
            + course.name
            + ' | '
            + course.provider.name;

        var ModalWindowWishListLeadGenObj = new ModalWindowWishListLeadGen();
        ModalWindowWishListLeadGenObj.show({
            additionalData: {
                'section_id': course.section && course.section.id
            },
            onSuccess: function (res) {
                ga(
                    'send',
                    'event',
                    eventCategory,
                    'wishlist - clicked get info button',
                    eventLabel,
                    {
                        nonInteraction: false,
                    }
                );
            },
            onShow: function() {
                var self = this;
                ga(
                    'send',
                    'event',
                    eventCategory,
                    'wishlist - viewed form',
                    eventLabel,
                    {
                        nonInteraction: false,
                    }
                );

                // immediately track when user changes the 'don't ask again' field
                var $neverAgainField = $('#never_show_again');
                $neverAgainField.unbind('change').on('change', function(e) {
                    if ($neverAgainField.is(':checked')) {
                        ga(
                            'send',
                            'event',
                            eventCategory,
                            'wishlist - clicked don\'t show me this again',
                            eventLabel,
                            {
                                nonInteraction: false,
                            }
                        );
                        CH.setCookie('ch_wllg', 1);
                    } else {
                        CH.setCookie('ch_wllg', 0);
                    }
                });

                // ability to skip submitting the form; goes to next step of wishlist flow
                $('.skip-wish-list-lead-gen').unbind('click').on('click', function(e) {
                    e.preventDefault();
                    ga(
                        'send',
                        'event',
                        eventCategory,
                        'wishlist - clicked no thanks',
                        eventLabel,
                        {
                            nonInteraction: false,
                        }
                    );
                    ModalWindowWishListLeadGenObj.hide();
                });

                $('.ui.checkbox', self.window).checkbox();
            },
            onHide: function () {
                wishListNextStep(
                    course,
                    showCategoryPreferences,
                    showSuccessMessage,
                    isFromWishlistPage
                );
            }
        });
    };

    var wishListNextStep = function(
        course,
        showCategoryPreferences,
        showSuccessMessage,
        isFromWishlistPage
    ) {
        if (showCategoryPreferences) {
            CH.showCategoryPreferencesWindow(true);
        } else if (showSuccessMessage || isFromWishlistPage) {
            swal({
                type: 'success',
                title: 'Awesome!',
                text: 'Awesome! ' + course.name + ' has been added to your wish list.',
            }, function(isConfirmed) {
                // from wishlist page add we need to refresh
                if (
                    isConfirmed &&
                    isFromWishlistPage
                ) {
                    window.location.reload();
                }
            });
        }
    };

    $userWishlistCount = $('#user-wishlist-count');
    /* Attach event Handlers */

    CH.initSemanticWishlist();

    /* Extra things to check */
    if (CH.courseAddedToWishlist) {
        postAddToWishlist({
            success: true,
            // simulate a course response from "add-to-wishlist" endpoint
            course: {
                name: CH.courseAddedToWishlist
            }
        }, null, null, true, false);
    }

    if (CH.wishlistAlertItemCount) {
        var $wishlistMenu = $(".wishlist-menu");
        var $wishlistAlert = $('.wishlist-alert');
        $wishlistMenu.popup({
            popup : $wishlistAlert,
            position: 'bottom center',
            on: 'manual'
        }).popup('show');

        ga('send', 'event', 'Wishlist', 'Show menu bar alert', CH.wishlistAlertItemCount, {
            nonInteraction: true
        });

        $wishlistAlert.click(function() {
            window.location = '/wishlist';
        });

        $wishlistAlert.find(".popup-close").on('click', function(e) {
            e.stopPropagation();
            $wishlistMenu.popup('hide');
        });
    }

    // Check at end after all handlers have been attached
    checkHashes();
});

