define('ch_semantic_responsive',[], function () {
    let isOpen = false;
    const mobileSidebar = document.getElementById('mobile-sidebar');
    const mobileSidebarMain = document.getElementById('mobile-sidebar-main');
    const mobileSidebarToggle = document.getElementById('js-sidebar-toggle');

    function showSidebar () {
        if (mobileSidebar) {
            mobileSidebar.style.width = '300px';
            mobileSidebarMain.style.marginLeft = '300px';
            mobileSidebarMain.style.position = 'fixed';
            isOpen = true;

            mobileSidebarMain.addEventListener('click', captureOutsideClick, true); // capture
        }
    };

    function hideSidebar () {
        if (mobileSidebar) {
            mobileSidebar.style.width = '0';
            mobileSidebarMain.style.marginLeft = '0';
            mobileSidebarMain.style.position = 'relative';
            isOpen = false;

            mobileSidebarMain.removeEventListener('click', captureOutsideClick, true);
        }
    };


    function captureOutsideClick(e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        hideSidebar();
    }


    const toggleSidebar = function() {
        if (isOpen) {
            hideSidebar();
        } else {
            showSidebar();
        }
    }

    if (mobileSidebar) {
        mobileSidebar.querySelector('.js-close-btn').addEventListener('click', (e) => {
            e.preventDefault();
            hideSidebar();
        });
    }

    if (mobileSidebarToggle) {
        mobileSidebarToggle.addEventListener('click', (e) => {
            e.preventDefault();
            toggleSidebar();
        });
    }

    // exposing these so users of the responsive lib can hide/show the dimmer on demand
    return {
        hideSidebar,
        showSidebar,
    };
});

