define('ch_read_more',[], function () {
  const DOMStrings = {
    container: ".js-read-more-container",
    overlay: ".js-read-more-overlay",
    button: ".js-read-more-button",
  };

  const ReadMore = function (container) {
    this.expanded = false;
    this.collapsedHeight = getCollapsedHeight(container);
    this.contentsHeight = getContentsHeight(container);
    this.expandedHeight = getExpandedHeight(container);

    const overlay = container.querySelector(DOMStrings.overlay);
    const button = overlay.querySelector(DOMStrings.button);
    const buttonTextWrapper = button.querySelector("span");
    const buttonInitialText = buttonTextWrapper.innerText;

    // disable read more container if the contents are as short as the configured collapsed height
    if (this.collapsedHeight.split('px')[0] >= this.contentsHeight) {
      disableReadMore();
      return false;
    }

    button.addEventListener(
      "click",
      function (e) {
        this.expanded = !this.expanded;

        if (this.expanded) {
          container.classList.add("expanded");
          container.style.maxHeight = this.expandedHeight;
          buttonTextWrapper.innerText = "Read less";
        } else if (!this.expanded) {
          container.classList.remove("expanded");
          container.style.maxHeight = this.collapsedHeight;
          buttonTextWrapper.innerText = buttonInitialText;
        }
      }.bind(this)
    );

    function getCollapsedHeight(container) {
      return container.style.maxHeight;
    }

    function getContentsHeight(container) {
      return [].slice
        .call(container.childNodes)
        .filter(function (child) {
          if (child instanceof HTMLElement) {
            return !child.classList.contains(DOMStrings.overlay.slice(0));
          } else return false;
        })
        .reduce(function (totalHeight, child) {
          var style = getComputedStyle(child);
          return (
            totalHeight +
            child.offsetHeight +
            parseInt(style.marginTop) +
            parseInt(style.marginBottom)
          );
        }, 0);
    }

    function getExpandedHeight(container) {
      var style = getComputedStyle(container);
      return `${
        parseInt(this.contentsHeight) +
        parseInt(style.paddingTop) +
        parseInt(style.paddingTop)
      }px`;
    }

    function disableReadMore() {
      overlay.style.display = 'none';
    }
  };

  function initializeReadMore() {
    const containers = [].slice.call(
      document.querySelectorAll(DOMStrings.container)
    );

    if (!containers.length === 0) return;

    containers.forEach((container) => new ReadMore(container));
  }

  return {
    init: initializeReadMore,
  };
});

