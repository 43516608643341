/**
 * filter/CourseRows
 *
 * Responsible for behavior of "browse results" course row elements
 * Should be used in combination with the course-list partial
 */
define('filter/CourseRows',[
    'fetching',
], function (fetching) {
    let levelFilter;
    let neighborhoodFilter;

    /**
     * initializeCourseRows
     *
     * Set up all behavior for each course row in the filter results list
     *
     * - Click tracking
     * - GA Events
     * - Loading "Other schedules" via ajax
     * - Making sure the selected schedule is sent when user clicks "attend"
     *
     * // @todo convert event handlers to be delegated from the rootElement
     *
     */
    function initializeCourseRows(rootElement) {
        const courseRows = rootElement.querySelectorAll('.js-course-row');

        levelFilter = levelFilter || document.getElementById('js-skill-level-filter');
        neighborhoodFilter = neighborhoodFilter || document.getElementById('js-neighborhoods-filters');

        courseRows.forEach((courseRow) => {
            if (courseRow.classList.contains('js-initialized')) {
                console.log('already initialized');
                return;
            }

            const courseRowForm = courseRow.querySelector('form');
            const courseId = courseRow.dataset.courseId;

            courseRow.addEventListener('click', (e) => {
                if (levelFilter && e.target.classList.contains('js-set-course-level')) {
                    e.preventDefault();
                    const cbs = levelFilter.querySelectorAll('input[type="checkbox"]');
                    cbs.forEach((cb) => {
                        cb.checked = cb.value === e.target.dataset.level ? true : false;
                    });
                    cbs.item(0).dispatchEvent(new Event('change', {bubbles: true}));
                }

                if (neighborhoodFilter && e.target.classList.contains('js-set-location')) {
                    e.preventDefault();
                    const cbs = levelFilter.querySelectorAll('input[type="checkbox"]');
                    cbs.forEach((cb) => {
                        cb.checked = cb.value === e.target.dataset.uri ? true : false;
                    });
                    cbs.item(0).dispatchEvent(new Event('change', {bubbles: true}));
                }

                if (e.target.dataset.js === 'go-to-course') {
                    ga(
                        'send',
                        'event',
                        'Filter Click Positions',
                        'Course clicked',
                        courseRow.dataset.clickPosition
                    );
                }

                if (e.target.classList.contains('course-review-link')) {
                    ga(
                        'send',
                        'event',
                        'reviews',
                        'Clicked browse page review stars',
                        e.target.href,
                        { nonInteraction: false }
                    );
                    return;
                }

                if (e.target.classList.contains('js-disabled-link')) {
                    e.preventDefault();
                    return;
                }

                if (e.target.classList.contains('is-popular')) {
                    ga(
                        'send',
                        'event',
                        'Filter Click Positions',
                        'Popular course clicked',
                        e.target.dataset.clickPosition
                    );
                }

                if (e.target.classList.contains('js-attend')) {
                    // "attend" button should trigger form submission when a schedule list is displayed
                    courseRowForm.submit();
                    return;
                }

                if (e.target.classList.contains('js-close-schedules')) {
                    e.preventDefault();

                    const scheduleWrapper = courseRow.querySelector('.js-schedules-wrapper');
                    const trigger = courseRow.querySelector('.js-load-schedules')

                    scheduleWrapper.style.height = 0;
                    trigger.closest('.js-first-section-date').classList.remove('hidden');

                    ga('send', 'event', 'Filter Page', 'Other Dates', 'Closed');
                }

                if (e.target.classList.contains('js-load-schedules')) {
                    e.preventDefault();

                    const timesSection = courseRow.querySelector('.js-course-schedules');
                    const scheduleWrapper = timesSection.querySelector('.js-schedules-wrapper');

                    // avoid re-loading data via ajax
                    if (!timesSection.classList.contains('loaded')) {
                        const loader = timesSection.querySelector('.js-loader');

                        if (loader) {
                            loader.classList.add('active');
                        }

                        fetching.htmlString('/course/course/get-course-active-sections?course_id=' + courseId).then((html) => {
                            timesSection.classList.add('loaded');

                            scheduleWrapper.innerHTML = html;

                            scheduleWrapper.style.height = 'auto';
                            e.target.closest('.js-first-section-date').classList.add('hidden');
                        });
                    } else {
                        scheduleWrapper.style.height = 'auto';
                        e.target.closest('.js-first-section-date').classList.add('hidden');
                    }

                    ga('send', 'event', 'Filter Page', 'Other Dates', 'Opened');
                }
            });

            courseRow.classList.add('js-initialized');
        });
    }

    /**
     * addSnippets
     *
     * Fetch rich snippet events via ajax and add to the DOM so Google can see them
     */
    function loadSnippets(courseIds, providerIds) {
        const data = {
            providerIds: providerIds,
        };

        const courseEventSnippets = document.getElementById('course-event-snippets');

        // If the event snippets were already loaded on page load, don't load via ajax
        if (!courseEventSnippets) {
            data.courseIds = courseIds;
        }

        // If we have nothing to load, don't request
        if (
            (!data.providerIds || data.providerIds.length <= 0)
            &&
            (!data.courseIds || data.courseIds.length <= 0)
        ) {
            return;
        }

        let url = '/course/filter/get-rich-snippets?';
        if (data.courseIds) {
            data.courseIds.forEach((id) => url += 'courseIds[]=' + id + '&');
        }
        if (data.providerIds) {
            data.providerIds.forEach((id) => url += 'providerIds[]=' + id + '&');
        }

        const scriptTag = document.createElement('script');
        scriptTag.type = 'application/ld+json';

        // Get rich snippets for the page
        fetching.htmlString(url).then((html) => {
            scriptTag.innerHTML = html;
            document.head.appendChild(scriptTag);
        });
    }

    return {
        initRows: initializeCourseRows,
        loadSnippets: loadSnippets,
    };
});

