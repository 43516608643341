define('modals',[
    'fetching',
    'ch_semantic_responsive',
    'ch_tabs_automatic',
    'ch_dialog',
    'ch_site_notification',
], function (fetching, semanticResponsive, tabsAutomatic, A11yDialog) {
    const modalBackground = document.getElementById('modal-background');
    let currentlyOpenModal;
    let isFbLoaded = false;
    let welcomeModalTimer;
    const initializedModals = {
        welcomeModal: {},
        userAuthModal: {},
    };

    function hideAll() {
        if (currentlyOpenModal) {
            closeModal(currentlyOpenModal);
        }
    }

    // gets the element otherwise fetches it and inserts into the page
    // returns a promise
    async function getModalElement(id, endpoint) {
        const element = document.getElementById(id);

        if (element) {
            return element;
        }

        return fetching.html(`/window/${endpoint}`).then((html) => {
            const innerModal = html.querySelector('.ui.modal');

            modalBackground.appendChild(innerModal);

            return innerModal;
        });
    }

    function loadFacebookOnTheFly() {
        if (!isFbLoaded) {
            let fbScript = document.createElement('script');
            fbScript.setAttribute('src', 'https://connect.facebook.net/en_US/sdk.js');
            fbScript.setAttribute('crossorigin', true);

            document.body.appendChild(fbScript);
            isFbLoaded = true;

            return new Promise((resolve) => {
                fbScript.addEventListener('load', (e) => {
                    resolve(e);
                }, false);
            });
        }
        return Promise.resolve();
    }

    function enableFacebookLoginButtons() {
        loadFacebookOnTheFly();
        const facebookLoginButtons = document.querySelectorAll('[data-js="facebook-button"]');
        facebookLoginButtons.forEach((el) => {
            el.addEventListener('click', (e) => {
                e.preventDefault();

                if (window.FB) {
                    window.FB.login(function (response) {
                        if (response.authResponse) {
                            var accessToken = response.authResponse.accessToken;

                            fetching(
                                '/user/authentication/login-facebook',
                                {
                                    method: 'POST',
                                    body: JSON.stringify({ token: accessToken }),
                                },
                            ).then(() => {
                                window.location.reload();
                            });
                        }
                    }, { scope: 'email' });
                }
            });
        });
    }

    function closeModal(modalElement) {
        modalBackground.classList.remove('active');
        modalBackground.style.display = 'none';
        modalBackground.style.opacity = 0;

        modalElement.classList.remove('active');
        modalElement.style.display = 'none';
        modalElement.style.opacity = 0;

        document.body.classList.remove('dimmed', 'dimmable', 'scrolling');

        currentlyOpenModal = undefined;
    }

    function showModal(modalElement, { closeCallback, enableFacebook = false } = {}) {
        const closeModalHandler = (e) => {
            if (e.target === modalBackground || e.target.classList.contains('js-close-modal')) {
                closeModal(modalElement);

                modalBackground.removeEventListener('click', closeModalHandler);
            }
            if (typeof closeCallback === 'function') {
                closeCallback();
            }
        };

        if (currentlyOpenModal) {
            closeModal(currentlyOpenModal);
        }

        if (enableFacebook) {
            enableFacebookLoginButtons();
        }

        currentlyOpenModal = modalElement;
        semanticResponsive.hideSidebar();

        document.body.classList.add('dimmed', 'dimmable');

        if (modalElement.classList.contains('scrolling')) {
            document.body.classList.add('scrolling');
        }

        if (modalBackground) {
            modalBackground.addEventListener('click', closeModalHandler);
            modalBackground.style.display = 'flex';
            modalBackground.style.opacity = 0;
        }

        modalElement.style.display = 'block';
        modalElement.style.opacity = 0;

        window.requestAnimationFrame(() => {
            if (modalBackground) {
                modalBackground.style.opacity = 1;
                modalBackground.classList.add('active');
            }

            modalElement.style.opacity = 1;
            modalElement.classList.add('active');
        });
    }

    // specific modals
    //
    //

    function initUserAuthModal() {
        const modalElement = document.getElementById('user-auth-window');

        const showUserAuthModal = (modal) => {
            console.log(modal)
            
            modal.show()

            if (welcomeModalTimer) {
                clearTimeout(welcomeModalTimer);
            }

            ga('send', 'event', 'Login', 'Login', 'Window Opened - ' + window.location.pathname, {
                nonInteraction: false,
            });
        }

        
        if (modalElement) {
            const modal = A11yDialog(modalElement);
            const loginForm = modalElement.querySelector('#form_Login');
            const registerForm = modalElement.querySelector('#form_RegisterUser');
            const authTabs = modalElement.querySelector('.js-auth-tabs')
            const loginButtons = document.querySelectorAll('.js-login');

            initializedModals.userAuthModal.show = showUserAuthModal.bind(this, modal);

            loginButtons.forEach((el) => {
                el.addEventListener('click', (e) => {
                    e.preventDefault();
                    
                    showUserAuthModal(modal);
                });
            });

            if (authTabs) {
                tabsAutomatic(authTabs)
            }

            loginForm.addEventListener('submit', function (e) {
                handleAuthFormSubmit.call(this, e, 'LOGIN')
            })

            registerForm.addEventListener('submit', function (e) {
                handleAuthFormSubmit.call(this, e, 'REGISTER')
            })

        }
    }


    function handleAuthFormSubmit(submitEvent, formType) {
        submitEvent.preventDefault();
        const errorMessage = this.querySelector('.ui.error.message');

        // Reset error
        if (errorMessage) {
            errorMessage.style.display = 'none';
            errorMessage.innerHTML = '';
        }

        fetching.json(
            this.action,
            {
                method: 'POST',
                body: new FormData(this),
            },
        ).then((data) => {
            if (data.success) {
                if (data.redirect) {
                    window.location.replace(data.redirect);
                } else {
                    window.location.reload();
                }
            } else {
                console.error(data);
                if (errorMessage) {
                    errorMessage.style.display = 'block';

                    switch (formType) {
                    case "LOGIN":
                        errorMessage.innerHTML = `There was a problem signing in, please check your details and try again.`;
                        break;
                            
                    case "REGISTER":
                        errorMessage.innerHTML = `There was a problem registering your account, please contact us for support.`;
                        break;
                            
                    default:
                        errorMessage.innerHTML = `There was an error, please contact us for support.`;
                        break;
                    }
                }
            }
        });
    }

    function initWelcomeModal() {

        const welcomeModal = document.getElementById('welcome-overlay-window');
        const showWelcomeModal = (sourceElement) => {
            showModal(welcomeModal, {
                closeCallback: () => {
                    CH.setCookie('ch_wo', 1);
                },
            });

            const gaAction = sourceElement && sourceElement.dataset.gaAction ?
                sourceElement.dataset.gaAction :
                'Welcome Overlay';

            ga('send', 'event', 'Email Capture', gaAction, 'Window Opened - ' + window.location.pathname, {
                nonInteraction: true,
            });
        };

        initializedModals.welcomeModal.show = showWelcomeModal;

        if (
            // we have the element
            welcomeModal
        ) {
            if (// the view says it can be opened
                CH.welcomeOverlay &&
                // it has not been submitted
                !CH.getCookie('ch_wo')
            ) {
                welcomeModalTimer = setTimeout(() => {
                    showWelcomeModal();
                }, 10000);
            }

            const welcomeOverlayButtons = document.querySelectorAll('.js-open-welcome-modal');
            welcomeOverlayButtons.forEach((el) => {
                el.addEventListener('click', (e) => {
                    e.preventDefault();

                    showWelcomeModal(el);
                });
            });

            const formElement = welcomeModal.querySelector('form');
            let errorMessage = welcomeModal.querySelector('.ui.warning.message');
            const resetError = () => {
                if (errorMessage) {
                    errorMessage.style.display = 'none';
                    errorMessage.innerHTML = '';
                }
            };

            const showError = (msg) => {
                if (errorMessage) {
                    errorMessage.style.display = 'block';
                    errorMessage.innerHTML = `There was a problem submitting your details, please check your details and try again: ${msg}`;
                }
            };

            formElement.addEventListener('submit', (e) => {
                e.preventDefault();
                resetError();

                fetching.json(
                    formElement.action,
                    {
                        method: 'POST',
                        body: new FormData(formElement),
                    },
                ).then((data) => {
                    if (data.success) {
                        getModalElement('welcome-overlay-thank-you-window', 'welcome-overlay-thank-you-window-new-semantic')
                            .then((modalEl) => {
                                showModal(modalEl);
                            });
                        // @todo - add category prefs
                        // fetch('/window/').then((response) => {
                        //     if (response.ok) {
                        //         return response.text();
                        //     }
                        // }).then((data) => {
                        //     const parser = new DOMParser();
                        //     const doc = parser.parseFromString(data, 'text/html');
                        //     const innerModal = doc.querySelector('.ui.modal');
                        //     welcomeModal.innerHTML = innerModal.innerHTML;
                        //
                        //     const closeLinks = welcomeModal.querySelectorAll('.js-close-modal');
                        //     closeLinks.forEach((el) => {
                        //         el.addEventListener('click', (e) => {
                        //             e.preventDefault();
                        //             closeModal();
                        //         });
                        //     });
                        // });
                    } else {
                        showError(data.error);
                        console.error(data);
                    }
                });
            });
        }
    }

    return {
        showModal,
        closeModal,
        initWelcomeModal,
        initUserAuthModal,
        getModalElement,
        initializedModals,
        hideAll,
    }
});

