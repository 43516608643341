define('ch_referral',[
    "jquery",
    "ch_form_validation",
    "ch_selectors",
    "ch_modal_window"
], function($) {
    var confirmReferral = function(modal, ga_page_name) {
        ga(
            'send',
            'event',
            'Email Capture',
            'Referral Program - Overlay',
            'Email Captured - ' + ga_page_name
        );
        $("#invite-button").click(function(e) {
            modal.hide();
            CH.referral.show();
        });
    };

    var referralSuccess = function(res, ga_page_name) {
        if (res.success) {
            var emails = $._('emails').val().trim();
            $._('emails').val("");
            $('#first_name').hide();
            if(!res.sent_emails) {
                var ModalWindowReferralConfirmationFailureObj = new ModalWindowReferralConfirmationFailure();
                ModalWindowReferralConfirmationFailureObj.show({
                    onShow: function () {
                        confirmReferral(this, ga_page_name);
                        var confirmationWindow = ModalWindowReferralConfirmationFailureObj.window;
                        confirmationWindow.find('.friends').html(res.friends);
                        if(res.pre_existing_emails_count < 2) {
                            confirmationWindow.find('#know').html('knows');
                            confirmationWindow.find('#have').html('has');
                        } else {
                            confirmationWindow.find('#know').html('know');
                            confirmationWindow.find('#have').html('have');
                        }
                    },
                    autofocus: false
                });
            }
            else {
                var ModalWindowReferralConfirmationObj = new ModalWindowReferralConfirmation ();
                ModalWindowReferralConfirmationObj.show (
                    {
                        onShow: function () {
                            confirmReferral(this, ga_page_name);
                            var confirmationWindow = ModalWindowReferralConfirmationObj.window;
                            confirmationWindow.find('#friends').html(res.friends);
                            if (res.pre_existing_emails)
                                confirmationWindow.find('#preExistingEmails').html(res.pre_existing_emails);
                            else
                                confirmationWindow.find('#preExistingEmails').html('');
                        },
                        autofocus: false
                    });
            }
        } else {
            $._('window_error', '#' + this.id).html(res.error).show();
        }
    };

    CH.referral = {
        show: function (ga_page_name) {
            var ModalWindowReferralObj = new ModalWindowReferral();
            ModalWindowReferralObj.show({
                onSuccess: function (res) {
                    referralSuccess(res, ga_page_name);
                },
                onShow: function() {
                    ga(
                        'send',
                        'event',
                        'Email Capture',
                        'Referral Program - Overlay',
                        'Window Opened - ' + ga_page_name
                    );

                    var socialCall = function(e) {
                        $.ajax({
                            url: '/user/Account/social-share',
                            type: 'POST',
                            async: false,
                            dataType: 'json'
                        });
                        ga(
                            'send',
                            'event',
                            'Social ' + e.data.type + ' Referral',
                            'Referral social share clicked',
                            null
                        );
                    };

                    $._('facebook_icon').on('click', { type: 'facebook' }, socialCall);
                    $._('twitter_icon').on('click', { type: 'twitter' }, socialCall);
                    $._('pinterest_icon').on('click', { type: 'pinterest' }, socialCall);

                    $._('copy').val('Select');
                    $._('copy').on('click', function(e) {
                        $._('referral_link').select();
                        document.execCommand('copy');
                    });
                }
            });
        }
    };
});

