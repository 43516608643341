define('fetching',[], function() {
    function getOptions(options = {}) {
        const headers = options.headers || {};
        return Object.assign({}, options, {
            headers: Object.assign({}, headers, {
                'X-Requested-With': 'XMLHttpRequest',
            }),
        });
    }

    function fetchJson(url, options = {}) {
        return fetch(
            url,
            getOptions(options)
        ).then((response) => {
            if (response.ok) {
                return response.json();
            }
        });
    }

    function fetchHtml(url, options = {}) {
        return fetch(
            url,
            getOptions(options)
        ).then((response) => {
            if (response.ok) {
                return response.text();
            }
        }).then((data) => {
            return (new DOMParser()).parseFromString(data, 'text/html');
        });
    }

    function fetchHtmlString(url, options = {}) {
        return fetch(
            url,
            getOptions(options)
        ).then((response) => {
            if (response.ok) {
                return response.text();
            }
        });
    }

    return {
        json: fetchJson,
        html: fetchHtml,
        htmlString: fetchHtmlString,
    };
});

