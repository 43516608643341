define('ch_section_interest',[
    'modals',
    'fetching',
    'sweetalert',
], function(modals, fetching, swal) {
    CH.showSectionInterestModal = function(
        sectionId,
        courseName,
        providerName
    ) {
        ga(
            'send',
            'event',
            'Learn More',
            'Click to Learn More',
            providerName + ': ' + courseName,
            { nonInteraction: false }
        );

        const formDataToPost = new FormData();
        formDataToPost.append('schedule', sectionId);

        // If user already gave us their email, submit the request and show a swal.
        // Otherwise show user section interest modal.
        if (CH.getCookie('ch_i')) {
            formDataToPost.append('hasIdentity', true);

            fetching.json('/user/subscription/section-interest-post', {
                method: 'POST',
                body: formDataToPost,
            }).then((data) => {
                if (data.success) {
                    interestSubmissionSuccess(providerName, courseName);
                } else if (!data.success && data.error) {
                    swal(
                        'Oh dear! There seems to be a problem.',
                        data.error,
                        'error'
                    );
                }
            });
        } else {
            // sectionInterestWindowNewSemanticAction
            modals.getModalElement('section-interest', 'section-interest-window-new-semantic').then((modalEl) => {
                const form = modalEl.querySelector('form');

                form.action = form.action + '&schedule=' + sectionId;

                form.onsubmit = function(e) {
                    e.preventDefault();

                    const formElement = e.target;
                    const errorMessage = formElement.querySelector('.ui.warning.message');

                    if (errorMessage) {
                        errorMessage.style.display = 'none';
                        errorMessage.innerHTML = '';
                    }

                    fetching.json(formElement.action, {
                        method: 'POST',
                        body: new FormData(formElement),
                    }).then((data) => {
                        if (data.success) {
                            interestSubmissionSuccess(providerName, courseName);
                            modals.closeModal(modalEl);
                        } else {
                            if (data.error && errorMessage) {
                                errorMessage.style.display = 'block';
                                errorMessage.innerHTML = data.error;
                            }
                            console.error(data);
                        }
                    });
                }

                modals.showModal(modalEl);
            });
        }
    };

    function interestSubmissionSuccess(providerName, courseName) {
        ga(
            'send',
            'event',
            'Learn More',
            'Submit Email',
            providerName + ': ' + courseName,
            { nonInteraction: false }
        );

        swal({
            type: 'success',
            title: 'Thank you!',
            text: "You'll hear from us soon!",
        });
    }
});

