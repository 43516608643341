define('checkout/AttendeeManager',['jquery'], function ($) {
    /**
     * keep track of attendees for the current purchase
     * @type {{}}
     */
    var attendees = attendees || {};

    // cached selections
    var $checkoutForm;
    var $productIdField;
    var $buyingForSomeoneElseField;
    var $summaryQuantityValue;
    var $deliveryDate;
    var $deliveryDateInput;
    var $studentSection;

    var attendeeFieldsTemplateHtml;

    $(function () {
        // select stuff...
        $checkoutForm = $('#form-checkout-semantic');
        $productIdField = $('#product_id');
        $buyingForSomeoneElseField = $('#buying_for_someone_else');
        $summaryQuantityValue = $('.js-checkout-summary-quantity-value');
        $quantityAdditionalLine = $('.js-checkout-quantity-additional-line');
        $deliveryDate = $('.js-delivery-date-field');
        $deliveryDateInput = $('#delivery_date');
        $studentSection = $('.js-attendee-fields');

        attendeeFieldsTemplateHtml = $('#js-tmpl-attendee')
            .detach()
            .html();
    });

    var AttendeeManager = {
        appendAttendees: function (newQuantity) {
            if ($studentSection.length === 0) {
                return;
            }

            let attendeesPerEnrollment = CH.checkout.attendeesPerEnrollment;

            $summaryQuantityValue.html(
                newQuantity +
                ((newQuantity > 1) ? ' enrollments' : ' enrollment') +
                ((attendeesPerEnrollment > 1) ? ' (includes ' + (attendeesPerEnrollment * newQuantity) + ' attendees)' : '')
            );

            $quantityAdditionalLine.html(
                '(includes ' + (attendeesPerEnrollment * newQuantity) + ' attendees)'
            );

            var newAttendees = {};
            var currentAttendeeCount = $('.js-attendee-field-set').length;

            // get default validation rules
            var firstNameValidationRules = $checkoutForm.form('get validation', $('#student_first_name_1'));
            var lastNameValidationRules = $checkoutForm.form('get validation', $('#student_last_name_1'));
            var emailValidationRules = $checkoutForm.form('get validation', $('#student_email_1'));

            var validationRules = {};
            var firstNameLabel;
            var lastNameLabel;
            var emailLabel;
            var attendeeFieldExists = false;
            var isAnExcessAttendee = false;
            var $attendeeFields;

            // for each attendee in the form, or for the new quantity, whichever is more...
            for (let i = 1; i <= Math.max(newQuantity, currentAttendeeCount); i++) {
                firstNameLabel = 'student_first_name_' + i;
                lastNameLabel = 'student_last_name_' + i;
                emailLabel = 'student_email_' + i;

                attendeeFieldExists = $('#' + firstNameLabel).length > 0;
                isAnExcessAttendee = i > newQuantity;

                if (attendeeFieldExists) {
                    if (isAnExcessAttendee) {
                        $('#student_section_' + i).remove();
                    }
                    newAttendees[i] = {
                        student_first_name: $('#' + firstNameLabel).val(),
                        student_last_name: $('#' + lastNameLabel).val(),
                        student_email: $('#' + emailLabel).val(),
                    };
                    continue;
                }

                // Disable adding attendee names and emails
                if (false) {
                    $attendeeFields = $(attendeeFieldsTemplateHtml);
                    $attendeeFields.attr('id', 'student_section_' + i);
                    $attendeeFields.find('.js-attendee-number').html(i);
                    $attendeeFields.find('input[name=student_first_name]')
                        .attr('id', firstNameLabel)
                        .attr('name', firstNameLabel);
                    $attendeeFields.find('input[name=student_last_name]')
                        .attr('id', lastNameLabel)
                        .attr('name', lastNameLabel);
                    $attendeeFields.find('input[name=student_email]')
                        .attr('id', emailLabel)
                        .attr('name', emailLabel);
                    $attendeeFields.find('[name=is_gift]')
                        .attr('name', 'is_gift_' + i);
    
                    $studentSection.append($attendeeFields);
                }

                newAttendees[i] = {
                    student_first_name: $('#' + firstNameLabel).val(),
                    student_last_name: $('#' + lastNameLabel).val(),
                    student_email: $('#' + emailLabel).val(),
                };

                validationRules[firstNameLabel] = Object.assign({}, firstNameValidationRules, { identifier: firstNameLabel });
                validationRules[lastNameLabel] = Object.assign({}, lastNameValidationRules, { identifier: lastNameLabel });
                validationRules[emailLabel] = Object.assign({}, emailValidationRules, { identifier: emailLabel });
            }

            // we re-initialize the SemanticUI form so that dynamically added fields can support validation rules
            window.CH.checkout.initializeForm();
            $checkoutForm.form('add fields', validationRules);

            attendees = newAttendees;
            AttendeeManager.toggleAttendeeEmails();

            $('.popup', $checkoutForm).popup();
        },

        toggleGiftCheckboxChange: function () {
            AttendeeManager.toggleAttendeeEmails();
            AttendeeManager.checkIsGift();
        },

        toggleAttendeeEmails: function () {
            var currentClass = window.CH.checkout.classList[$productIdField.val()];
            var isFree = parseFloat(currentClass.price) == 0;
            var isBuyerAttending = $buyingForSomeoneElseField.is(':not(:checked)');

            $.each(attendees, function (i) {
                var isFirstAttendee = (i === 1);
                var isBuyer = isFirstAttendee && isBuyerAttending;
                var isGift = $('input[name=is_gift_' + i + ']')
                    .parent('.checkbox')
                    .checkbox('is checked');
                var $studentEmail = $('#student_email_' + i);

                // reset email field
                $studentEmail
                    .disable()
                    .hide();

                // remove prior validation issues
                $studentEmail
                    .parent('.field.error')
                    .removeClass('error')
                    .find('.red.pointing.prompt')
                    .remove();

                // require email when
                // purchase is free
                // or this attendee is a gift
                // or this attendee is the buyer
                if (
                    isFree
                    || isGift
                    || isBuyer
                ) {
                    $studentEmail
                        .enable();
                }

                // show email when
                // this attendee is a gift
                // or purchase is free and it's not the buyer's attendee field
                if (
                    isGift
                    || (isFree && !isBuyer)
                ) {
                    $studentEmail.show();
                }
            });
        },

        fillFirstAttendee: function () {
            var user = window.CH.checkout.user;

            var $attendeeFirstName = $('#student_first_name_1', $checkoutForm);
            var $attendeeLastName = $('#student_last_name_1', $checkoutForm);
            var $attendeeEmail = $('#student_email_1', $checkoutForm);
            var $buyerFirstName = $('#first_name', $checkoutForm);
            var $buyerLastName = $('#last_name', $checkoutForm);
            var $buyerEmail = $('input[name=email]', $checkoutForm);

            if ($buyingForSomeoneElseField.is(':not(:checked)')) {
                // auto-set and validate buyer's name in first attendee name fields
                $attendeeFirstName.val(user.first_name || $buyerFirstName.val());
                $attendeeLastName.val(user.last_name || $buyerLastName.val());
                $attendeeEmail
                    .val(user.email || $buyerEmail.val())
                    .enable()
                    .hide();
            } else {
                // clear out first attendee name if it's the same as the buyer name
                if (
                    (
                        $attendeeFirstName.val() == user.first_name
                        || $attendeeFirstName.val() == $buyerFirstName.val()
                    )
                    && (
                        $attendeeLastName.val() == user.last_name
                        || $attendeeLastName.val() == $buyerLastName.val()
                    )
                ) {
                    $attendeeFirstName.val('');
                    $attendeeLastName.val('');
                }
                $attendeeEmail.val('');
            }

            // trigger any behavior after we changed the values...
            $attendeeFirstName.trigger('change');
            $attendeeLastName.trigger('change');
            $attendeeEmail.trigger('change');
        },

        /**
         *
         */
        checkIsGift: function () {
            // intentionally re-select any new checkbox fields
            const $isGiftFields = $('.is-gift-checkbox');
            let isGift = false;

            for (let i = 0; i < $isGiftFields.length; i += 1) {
                let isChecked = $isGiftFields
                    .eq(i)
                    .checkbox('is checked');

                // If the first attendee is a gift, then disable buyer attendee checkbox
                if (i === 0) {
                    if (isChecked) {
                        $buyingForSomeoneElseField
                            .prop('checked', true)
                            .trigger('change')
                            .disable();
                    } else {
                        $buyingForSomeoneElseField.enable();
                    }
                }

                if (isChecked) {
                    isGift = true;
                    break;
                }
            }

            if (!isGift) {
                $deliveryDate.hide();
                $deliveryDateInput.disable();
            } else {
                $deliveryDate.show();
                $deliveryDateInput.enable();
            }
        },
    };

    return AttendeeManager;
});

