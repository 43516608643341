define('ch_sailthru',['sailthru'], function(Sailthru) {
    if (typeof Sailthru === 'undefined') return;

    Sailthru.init({
      customerId: CH.Sailthru.customerId,
      excludeContent: false, // Determines whether Sailthru should spider the page on which the Javascript appears. Set this to true to prevent spidering.
      autoTrackPageview: true, // Specify whether pageviews should be tracked automatically on page-load, attributed to the current URL. You can track pageviews manually using the trackPageView function, which is useful when a single URL may display an array of content items. (isCustom must be set to true in order for this parameter’s “false” value to take effect.)
      useStoredTags: true, // Specify which interest tags to assign to the user based on the item; if true, use existing tags in your Content Library (whether added by the Personalize JS spider or Content API calls); if false, use tags sent in the trackPageView function.
    });
});

