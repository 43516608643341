define('ch_accordion',[], function() {
    let accordions;

    return {
        init: function () {
            accordions = accordions || document.querySelectorAll('.accordion-mobile');

            accordions.forEach((el) => {
                const header = el.querySelector('.accordion-header');
                const content = el.querySelector('.accordion-content');

                let dropdown = header.querySelector('.icon.dropdown');
                if (!dropdown) {
                    dropdown = document.createElement('i');
                    dropdown.classList.add('ui');
                    dropdown.classList.add('icon');
                    dropdown.classList.add('dropdown');
                    header.appendChild(dropdown);
                }

                let isActive = el.classList.contains('accordion-mobile-active');

                header.addEventListener('click', function(e) {
                    if (isActive) {
                        isActive = false;
                        content.style.display = 'none';
                    } else {
                        isActive = true;
                        content.style.display = 'block';
                    }
                });

                if (isActive) {
                    content.style.display = 'block';
                } else {
                    content.style.display = 'none';
                }
            });
        },
    };
});

