define('ch_site_notification',[], function() {
    const notificationBar = document.getElementById('notification-container');

    if (notificationBar) {
        const closeNotification = notificationBar
            ? notificationBar.querySelector('.js-close-notification')
            : null;

        closeNotification && closeNotification.addEventListener('click', (e) => {
            e.preventDefault();
            notificationBar.style.display = 'none';

            const date = new Date();

            date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
            document.cookie = CH.cookieName + "_status=0;" + '; expires=' + date.toGMTString() + "; path=/";
        });
    }
});

