// DEPRECATED, replaced by ch_filters.js

define('ch_simple_filters',[
    'jquery',
    'sweetalert',
    'fetching',
    'dayjs',
    'semantic',
    'ch_utils',
], function ($, swal, fetching, dayjs) {

    /**
     * user defined options
     */
    var options = {
        resultsCb: () => {},
        popStateCb: () => {},
    };

    /**
     * the filter form
     */
    var $form;

    let formElement;

    /**
     * The filter element
     */
    var $filtersContainer;

    /**
     * debounce timer
     */
    var simpleFilterSubmitTimer;

    /**
     * target element where search results are loaded into via ajax
     */
    var $resultsContainer;

    /**
     * column (or wrapping container) for search results used for dimming
     */
    var $resultsColumn;

    /**
     * container element for all results and the subheader
     */
    var $browseContainer;

    var $filterPageContainer;

    var $formResetButtons;

    /**
     * Reference to the "sticky" filter bar
     * undefined when the bar is not set as sticky
     */
    var $stickyFilters;

    /**
     * Reference to the "sticky" map
     */
    var $stickyMap;

    /**
     * Element containing a message about there being no results
     */
    var $noResultsMessage;

    var $neighborhoodFilters;

    var $daysAndTimesFilters;

    /**
     * the serialized form cache
     */
    var formSerialized;

    var $loader;

    var $isNeighborhoodsLoaded = false;

    function checkSimpleFilterSubmit($form, eventOptions) {
        $form.data('is-dirty', true);

        if (simpleFilterSubmitTimer) {
            clearTimeout(simpleFilterSubmitTimer);
        }

        simpleFilterSubmitTimer = setTimeout(function () {
            $form.trigger('submit', eventOptions);
        }, 1000);
    }

    function postFetchCleanup() {
        $loader.addClass('hidden');
        $resultsContainer.removeClass('loading');

        if (!isFiltersAtDefault()) {
            $formResetButtons.removeClass('hidden');
        }

        // form is no longer dirty
        $form.data('is-dirty', false);
    }

    /**
     * serializeForm
     *
     * Digests the simple filters form and returns a serialized representation of it
     * Used for submission and tracking state change
     *
     * @param jQuerySelector $form - the simple filters form
     * @return string
     */
    function serializeForm($form) {
        convertCbGroupsToStrings([
            '.js-days-of-the-week-filter input[type="checkbox"]:not(:disabled)',
            '#js-skill-level-filter input[type="checkbox"]:not(:disabled)',
            '.js-age-filter input[type="checkbox"]:not(:disabled)',
            '#js-neighborhoods-filters input[type="checkbox"]:not(:disabled)',
            '.js-frequency-filter input[type="checkbox"]:not(:disabled)',
            '.js-grouper-filter input[type="checkbox"]:not(:disabled)',
        ], $form);

        // submit only:
        // - inputs with values (includes radios)
        // - select lists
        return $(':input[value!=""][data-serialize!="false"]', $form)
            .not('input[type="checkbox"]')
            .serialize();
    }

    /**
     * submitSimpleFilters
     *
     * @param $form                     the form
     * @param $resultsColumn            the results container (to refresh)
     * @param recordHistory boolean     true to record the filter submission in browser history stack
     */
    function submitSimpleFilters($form, $resultsColumn, recordHistory) {
        var formData = serializeForm($form);
        var recordHistory = recordHistory || false;
        var parsedUrl = parseUri(window.location.href);
        var baseUrl;

        $loader = $('.js-filter-loading');

        $loader.removeClass('hidden');
        $resultsContainer.addClass('loading');
        $formResetButtons.addClass('hidden');

        formSerialized = serializeForm($form);

        // set baseUrl based on any selected categories, otherwise current url
        baseUrl = parsedUrl.protocol
            + '://'
            + parsedUrl.host
            + parsedUrl.path;

        // store form data as object
        if (recordHistory) {
            history.pushState(
                formData,
                'Filtering',
                baseUrl + (formSerialized ? '?' + formSerialized : '')
            );
        }

        fetching.htmlString(baseUrl + (formSerialized ? '?' + formSerialized : '')).then((htmlString) => {

            var $paginationControls;
            var totalResults;
            var sellableResults;

            // go to the top of the page
            window.scroll({
                top:  0,
                left: 0,
                behavior: 'smooth',
            });

            // update results first...
            $resultsContainer.html(htmlString);

            // run user-specified callback
            options.resultsCb.call(this);

            // then fetch latest counts
            $paginationControls = $resultsColumn.find('.pagination');
            totalResults = $paginationControls.data('total-results') || 0;

            $noResultsMessage.toggleClass('active', totalResults <= 0);

            sellableResults = $paginationControls.data('sellable-results') || 0;

            // and update counts
            $('.js-result-count').html(
                totalResults + ' ' + (totalResults === 0 || totalResults > 1 ? 'classes' : 'class')
            );
            $('.js-filter-results-sellable-count').html(sellableResults.toLocaleString('en-US') || 'Unfortunately, no');
            $('.js-filter-results-phrase-separator').html(sellableResults ? 'and' : 'but');

            CH.initSemanticWishlist();
            postFetchCleanup();
        }).catch(function (error) {
            console.error(error);
            swal({
                title: 'Something went wrong', // @todo - placeholder copy
                type: 'error',
                confirmButtonText: 'Retry',
                closeOnConfirm: true,
            }, function () {
                $form.submit();
            });
            postFetchCleanup();
        });
    }

    function emitGAFilterEvent(filterName) {
        if (filterName) {
            // ditch any [] chars
            filterName = filterName.replace(/\[\]/g, '');
            // first char uppercase for pretty times
            filterName = filterName.charAt(0).toUpperCase() + filterName.slice(1);

            ga(
                'send',
                'event',
                'Filter Simple', // category
                'Field Changed',
                filterName,
                { nonInteraction: false }
            );
        }
    }

    function trackSimpleFilterFieldChange() {
        emitGAFilterEvent($(this).attr('name'));
    }

    function handleSidebarShow() {
        // Load neighborhood filters
        if (!$isNeighborhoodsLoaded) {
            const params = new URLSearchParams(window.location.search);
            params.set('city_uri', CH.Hub.uri);
            fetching.htmlString('/course/filter/get-neighborhood-filters?' + params.toString())
                .then((result) => {
                    $neighborhoodFilters.html(result);
                    $isNeighborhoodsLoaded = true;
                });
        }
    }

    /**
     * initializeSimpleFilter
     *
     * Note: do not use semanticUi .checkbox() on these form fields, it's slow... and we have A LOT of checkboxes
     *
     * @param resultsCb     callback to trigger after filter results are loaded
     */
    function initializeSimpleFilter(userOptions) {
        $filtersContainer = $('.js-filters-container');

        if (!$filtersContainer.length) {
            return;
        }

        options = Object.assign(options, userOptions);

        // set shared $element vars
        $form = $('#filter-simple-form');

        formElement = document.getElementById('filter-simple-form');

        $resultsColumn = $('.js-filter-results-column.ui.column', '#filter-results');
        $resultsContainer = $resultsColumn.find('.js-filter-results-container');
        $browseContainer = $('.browse-results-container');
        $filterPageContainer = $('#filter-page-container');
        $formResetButtons = $('.js-form-reset');
        $noResultsMessage = $('.js-no-results');
        $neighborhoodFilters = $('#js-neighborhoods-filters');
        $daysAndTimesFilters = $('#js-days-and-times-filters');
        $sortSelect = $('.js-sort-select');
        $sortInput = $('#sort');
        $toggleButton = $('.js-toggle-filters');
        $filtersSidebar = $('.js-filters-sidebar');

        // Connect the sort select (which is outside the form) to the hidden sort input (which is inside the form)
        $sortSelect.dropdown({
            onChange: function(value) {
                $sortInput.val(value);
                $sortInput.trigger('change');
            },
        });

        // Set up sidebar toggler
        if ($toggleButton.length && $filtersSidebar.length) {
            $filtersSidebar.first().sidebar('setting', {
                transition: 'overlay',
                mobileTransition: 'overlay',
                onShow: handleSidebarShow,
            });
            $filtersSidebar.first().sidebar('attach events', $toggleButton);
        }

        const timeField = formElement.querySelector('input[name=time]');
        const dateField = formElement.querySelector('input[name=date]');

        var $priceField = $('input[name=price]', $form);
        // var $priceMinText = $('#js-price-filters .price.start', $form);
        // var $priceMaxText = $('#js-price-filters .price.end', $form);
        // var priceSlider = document.getElementById('js-slider');
        // var $seatsField = $('select[name=available_seats]', $form);
        var $applyFilters = $('.js-apply-filters-button', $form);

        $applyFilters.hide();

        /**
         * submit the form
         *
         * @param e         event
         * @param options   {recordHistory: boolean}
         */
        function submitForm(e, eventOptions) {
            trackSimpleFilterFieldChange.call(this);
            checkSimpleFilterSubmit($form, eventOptions);

            toggleApplyFiltersButton();
        }

        // when changing fields, submit the form
        $form.on('change', 'select, input', submitForm);

        // initialize form submission
        $form.on('submit', function (e, options) {
            options = Object.assign({
                recordHistory: true,
            }, options);

            submitSimpleFilters(
                $form,
                $resultsColumn,
                options.recordHistory
            );

            return false;
        });

        // reset all form fields to empty or defaults
        function resetForm(e, options) {
            options = Object.assign({
                submitForm: true,
            }, options);

            // by default form.reset does not clear hidden fields
            // reset specific hidden fields
            timeField.value = '';
            startTimeField.value = '';
            endTimeField.value = '';

            dateField.value = '';
            startDateField.value = '';
            endDateField.value = '';

            $priceField.val('');

            //setTimeFields(undefined, false);
            //setDateFields($dateField.val(), false);

            //priceSlider.noUiSlider.reset(false);

            // Ensure that the available_seats form field is reset to nothing
            // It is then displayed as 1 Person, which is the default, but it's not
            // to be included in the query params
            // This seems like it shouldn't be necessary, but to be sure we're setting the hidden formfield
            // to empty string as well
            // $seatsField[0].value = '';

            // reset all checkboxes
            $('input[type="checkbox"]', $form)
                .prop('checked', false)
                .removeAttr('checked'); // @todo - this shouldn't be necessary

            $('.expand.active', $form).removeClass('active');

            $sortSelect.dropdown('restore defaults');

            if (options.submitForm) {
                submitForm();
            }

            $formResetButtons.addClass('hidden');

            $('.ui.button.active', $form).removeClass('active');
        }

        $form.on('reset', resetForm);

        var $commonTimeFilters = $('.js-common-time-buttons').children('.button');

        const startTimeElement = document.getElementById('start-time');
        const endTimeElement = document.getElementById('end-time');
        const startTimeField = startTimeElement.querySelector('input');
        const endTimeField = endTimeElement.querySelector('input');

        const startDateElement = document.getElementById('start-date');
        const endDateElement = document.getElementById('end-date');
        const startDateField = startDateElement.querySelector('input');
        const endDateField = endDateElement.querySelector('input');

        $formResetButtons.on('click', function() {
            resetForm();
            $noResultsMessage.removeClass('active');
        });

        $commonTimeFilters.on('click', function () {
            var $this = $(this);
            var dayType = $this.data('value');
            var active = false;
            // The week starts on a Sunday in Day.js by default
            let now = dayjs();

            $this
                .toggleClass('active')
                .siblings('.button')
                .removeClass('active');

            active = $this.hasClass('active');

            if (dayType === 'weeknights') {
                $('input[data-day-type=weekday]').prop('checked', active);
                $('input[data-day-type=weekend]').prop('checked', false);

                startDateField.value = '';
                endDateField.value = '';

                if (active) {
                    startTimeField.value = '18:00';
                    endTimeField.value = '23:59';
                } else {
                    startTimeField.value = '';
                    endTimeField.value = '';
                }
            }

            if (dayType === 'weekdays') {
                $('input[data-day-type=weekday]').prop('checked', active);
                $('input[data-day-type=weekend]').prop('checked', false);

                startDateField.value = '';
                endDateField.value = '';

                if (active) {
                    startTimeField.value = '06:00';
                    endTimeField.value = '18:00';
                } else {
                    startTimeField.value = '';
                    endTimeField.value = '';
                }
            }

            if (dayType === 'weekends') {
                $('input[data-day-type=weekend]').prop('checked', active);
                $('input[data-day-type=weekday]').prop('checked', false);

                startDateField.value = '';
                endDateField.value = '';
                startTimeField.value = '';
                endTimeField.value = '';
            }

            if (dayType === 'next-seven-days') {
                $('input[data-day-type=weekday]').prop('checked', false);
                $('input[data-day-type=weekend]').prop('checked', false);
                startTimeField.value = '';
                endTimeField.value = '';

                // Next 7 days including today
                startDateField.value = now.format('YYYY-MM-DD');
                endDateField.value = now.add(7, 'day').format('YYYY-MM-DD');
                endDateField.dispatchEvent(new Event('change', { bubbles: true }));
            }

            if (dayType === 'this-weekend') {
                $('input[data-day-type=weekend]').prop('checked', active);
                $('input[data-day-type=weekday]').prop('checked', false);
                startTimeField.value = '';
                endTimeField.value = '';

                // If now is a weekend
                // The week starts on a Sunday in Day.js by default
                if ([0, 6].includes(now.day())) {
                    startDateField.value = now.format('YYYY-MM-DD');
                    endDateField.value = now.add(1, 'day').startOf('week').format('YYYY-MM-DD');
                } else {
                    startDateField.value = now.endOf('week').format('YYYY-MM-DD');
                    endDateField.value = now.add(1, 'week').startOf('week').format('YYYY-MM-DD');
                }

                endDateField.dispatchEvent(new Event('change', { bubbles: true }));
            }

            if (dayType === 'next-thirty-days') {
                $('input[data-day-type=weekday]').prop('checked', false);
                $('input[data-day-type=weekend]').prop('checked', false);
                startTimeField.value = '';
                endTimeField.value = '';
                startDateField.value = now.format('YYYY-MM-DD');
                endDateField.value = now.add(30, 'day').format('YYYY-MM-DD');
                endDateField.dispatchEvent(new Event('change', { bubbles: true }));
            }

            endTimeField.dispatchEvent(new Event('change', { bubbles: true }));
        });

        function setTimeFields(timeRangeValue, triggerChange = true) {
            // set start & end times
            if (timeRangeValue) {
                timeRangeValue = timeRangeValue.split('-');

                if (timeRangeValue.length === 2) {
                    let hours = ('0' + parseInt(timeRangeValue[0] / 60, 10)).slice(-2);
                    let min = ('0' + (timeRangeValue[0] % 60)).slice(-2);
                    startTimeField.value = hours + ':' + min;

                    hours = ('0' + parseInt(timeRangeValue[1] / 60, 10)).slice(-2);
                    min = ('0' + (timeRangeValue[1] % 60)).slice(-2);

                    endTimeField.value = hours + ':' + min;
                }
            } else {
                startTimeField.value = '';
                endTimeField.value = '';
            }

            if (triggerChange) {
                endTimeField.dispatchEvent(new Event('change', { bubbles: true }));
            }
        }

        function startEndTimeChangeHandler(e) {
            if (
                startTimeField.value &&
                endTimeField.value &&
                startTimeField.value != endTimeField.value
            ) {
                // set overall time field filter value
                const starts = startTimeField.value.split(':');
                const ends = endTimeField.value.split(':');

                const startMinutes = (starts[0] * 60) + (starts[1] * 1);
                const endMinutes = (ends[0] * 60) + (ends[1] * 1);

                timeField.value = `${startMinutes}-${endMinutes}`;

            } else {
                timeField.value = ''
            }
        }

        endTimeField.addEventListener('change', startEndTimeChangeHandler);
        startTimeField.addEventListener('change', startEndTimeChangeHandler);

        function startEndDateChangeHandler(e) {
            // clear end date if we are changing start...
            if (e.target === startDateField) {
                endDateField.value = '';
                dateField.value = '';
            }

            if (
                startDateField.value &&
                endDateField.value &&
                startDateField.value <= endDateField.value
            ) {
                // date elements give us:
                // YYYY-MM-DD
                // and we want to set as
                // MMDDYYY-MMDDYYYY
                const starts = startDateField.value.split('-');
                const ends = endDateField.value.split('-');

                dateField.value = `${starts[1]}${starts[2]}${starts[0]}-${ends[1]}${ends[2]}${ends[0]}`;
            } else {
                dateField.value = '';
            }
        }

        endDateField.addEventListener('change', startEndDateChangeHandler);
        startDateField.addEventListener('change', startEndDateChangeHandler);

        //setTimeFields(timeField.value, false);

        function filterDateRange(start, end) {
            if (start && end && start != end) {
                // trigger a filter when both start and end date fields are provided
                var formattedStart = formatDateForFilter(start);
                var formattedEnd = formatDateForFilter(end);

                dateField.value = formattedStart + '-' + formattedEnd;
                // @todo
                //  .trigger('change');
            } else if (!start && !end && dateField.value) {
                // trigger a filter when both start and end date fields are emptied
                dateField.value = '';
                // .trigger('change');
            }
        }

        // @todo - handle date begin/end limitations

        // convert MMDDYYYY-MMDDYYYY to each of the date fields YYYY-MM-DD
        function setDateFields(dateRangeValue, triggerChange) {
            triggerChange = typeof triggerChange !== 'undefined' ? triggerChange : true;
            if (dateRangeValue) {
                dateRangeValue = dateRangeValue.split('-');
                if (dateRangeValue.length === 2) {
                    startDateField.value = dateRangeValue[0].substring(4, 8) + '-' + dateRangeValue[0].substring(0, 2) + '-' + dateRangeValue[0].substring(2, 4);
                    endDateField.value = dateRangeValue[1].substring(4, 8) + '-' + dateRangeValue[1].substring(0, 2) + '-' + dateRangeValue[1].substring(2, 4);
                }
            } else {
                startDateField.value = '';
                endDateField.value = '';
            }
            // @todo triggerChange?
        }

        // set default start & end times
        setDateFields(dateField.value, false);

        function formatDateForFilter(date) {
            // Javascript Months are 0 Indexed
            var month = (date.getMonth() + 1).toString();
            var day = date.getDate().toString();
            var year = date.getFullYear().toString();

            if (month.length === 1) {
                // Gotta left pad it
                month = '0' + month;
            }

            if (day.length === 1) {
                // Gotta left pad it
                day = '0' + day;
            }

            return month + day + year;
        }

        // when user goes back to a different filter request
        // then update the filter form and re-submit it
        $(window).on('popstate', function (e) {
            var formData = e.originalEvent.state;
            var $inputField;

            // clear form
            $form.trigger('reset', [{
                submitForm: false,
            }]);

            // clear hidden fields, which form reset does not touch
            $form
                .find('input[type=hidden]')
                .val('');

            if (formData && $.isArray(formData)) {
                // find the form control and update it
                $.each(formData, function (i, val) {
                    if (val.name.indexOf('[') >= 0) {
                        // checkbox groups (need escaping)
                        if (val.value.length > 0) {
                            $inputField = $('[name^="' + val.name.replace(/\[\]/g, '') + '"][value="' + val.value + '"]', $form);

                            $inputField
                                .prop('checked', true);

                            if ($inputField.prop('name') === 'neighborhood[]') {
                                updateExpanderForHoods($inputField);
                            }
                        }
                    } else {
                        // all text (visible and hidden) input controls
                        // do not "re set" values of radios and checkboxes here
                        $('input:text[name=' + val.name + '], input[name=' + val.name + '][type=hidden]', $form)
                            .val(val.value);

                        // radio buttons...
                        $('input[type=radio][name=' + val.name + '][value="' + val.value + '"]')
                            .prop('checked', true);

                        if (val.name === 'time') {
                            setTimeFields(timeField.value, false);
                        } else if (val.name === 'date') {
                            setDateFields(dateField.value, false);
                        }
                    }
                });
            }

            // trigger user-defined callback
            options.popStateCb();

            // re-submit the form with empty or updated filters
            // do not record history
            submitSimpleFilters(
                $form,
                $resultsColumn,
                false
            );
        });

        // Cache the value of the serialized form so we can react to changes properly on mobile
        formSerialized = serializeForm($form);

        if (!isFiltersAtDefault()) {
            $formResetButtons.removeClass('hidden');
        }

        initPriceFilter();
    }

    function initPriceFilter() {
        // make price "options" (the radio buttons) set the hidden price field
        var $priceOptionFields = $('input[name="price-options"]', $form);
        var $priceField = $('input[name=price]', $form);

        $priceOptionFields.on('change', function () {
            $priceField.val($(this).val()).trigger('change');
        });
    }

    function toggleApplyFiltersButton() {
        const applyButton = document.querySelector('.js-apply-filters-button');
        applyButton.classList.toggle('disabled', (formSerialized === serializeForm($form)));
    }

    /**
     * convertCbGroupsToStrings
     *
     * copy the value of a checkbbox group inputs over to a comma-delimited string in an input
     *
     * for example, copy the value in "day[]" (saturday and sunday are checked)
     * to the matching input with a name "day" and the value "saturday,sunday"
     *
     * auto-creates the input value if it is not present
     *
     * @param array cbGroups      array of checkbox group selectors for jquery
     */
    // @TODO: Move/refactor to ch_simple_filters.js once that's the canonical filtering module
    function convertCbGroupsToStrings (cbGroups, $form) {
        $.each(cbGroups, function (i, selector) {
            // get all inputs of that selection
            var $selectedInputs = $(selector, $form);
            var valueString;
            var newInputName;
            var $stringInput;

            if ($selectedInputs) {
                // map values of checked inputs into comma-delim string
                valueString = $selectedInputs.filter(':checked').map(function () {
                    return this.value;
                }).get().join(',');

                // find the previous or prepare a new input field
                // for storing and submitting the comma-delim string
                newInputName = $selectedInputs
                    .first()
                    .attr('name');

                // if we have a value and name to work with
                if (newInputName) {
                    $stringInput = $('input[name=' + newInputName.replace(/\[\]/g, '') + ']');

                    if (!$stringInput.length) {
                        $stringInput = $('<input type="hidden">')
                            .attr('name', newInputName.replace(/\[\]/g, ''))
                            .appendTo($form);
                    }

                    $stringInput.val(valueString);
                }
            }
        });
    }

    function isFiltersAtDefault() {
        if (formSerialized) {
            let params = new URLSearchParams(formSerialized);
            if (params.size > 1 || (params.size === 1 && !params.has('sort', 'most-relevant'))) {
                return false;
            }
        }

        return true;
    }

    return {
        init: initializeSimpleFilter,
    };
});

