/**
 * StepManager
 *
 * Manage "steps" throughout a form, submitting the form on the final step
 *
 * Each step will trigger the following events:
 *  StepManager.Step.hide
 *      Triggered when the step's element is hidden during transition between steps
 *  StepManager.Step.show
 *      Triggered when the step's element is displayed during transition between steps
 */
define('checkout/StepManager',[
    'jquery'
], function ($) {
    var steps = [];
    var _isFormSubmitting = false;
    var currentStepIndex = 0;
    var $form;
    var $progress;
    var $progressBar;
    var $stepLabels;

    var Step = function (id, $element) {
        this.$element = $element;
        this.id = id;
        this.isComplete = false;
        this.completionConditions = [];
    };

    Step.prototype = {
        show: function () {
            this.$element.show();
        },
        hide: function () {
            this.$element.hide();
        },
    };

    /**
     * Moves the progress bar to Step #
     *
     * @param stepIndex
     */
    function updateProgressToStep(stepIndex) {
        var percentComplete = stepIndex && (100 * (stepIndex / (steps.length - 1)));

        // prevent semantic's progress bar from hitting 100% and turning green
        if (percentComplete >= 100) {
            percentComplete = 99;
        }

        $progressBar.progress({
            percent: percentComplete,
        });

        // make all (#) labels appear active
        $stepLabels.removeClass('salmon');
        for (var i = 0; i <= stepIndex; i++) {
            $stepLabels.eq(i).addClass('salmon');
        }
    }

    /**
     * switch to a step
     * - does not validate
     * - (optional) records step in history
     * - animates
     *
     * @param stepIndex
     * @param recordHistory
     */
    function goTo(stepIndex, recordHistory, emitEvent) {
        var currentStep;
        var nextStep;
        var directionIn;
        var directionOut;

        recordHistory = typeof recordHistory === 'undefined';
        emitEvent = typeof emitEvent === 'boolean' ? emitEvent : true;
        currentStep = steps[currentStepIndex] || null;
        nextStep = steps[stepIndex] || null;
        directionIn = stepIndex > currentStepIndex ? 'left' : 'right';
        directionOut = stepIndex > currentStepIndex ? 'right' : 'left';

        // same page, ignore
        if (currentStepIndex === stepIndex) {
            return;
        }

        // scroll back to the top of the page
        CH.scrollTo(0);

        // "last step" that does not exist, lets submit the form
        if (isLastStep(stepIndex - 1) && _isFormSubmitting === false) {
            submitForm();
            return;
        }

        currentStep.$element.dimmer('show');

        if (nextStep) {
            if (recordHistory) {
                history.pushState(
                    { step: stepIndex },
                    'Checkout > Step ' + (stepIndex + 1)
                );
            }

            if (emitEvent) {
                currentStep.$element.trigger('StepManager.Step.hide', {
                    step: stepIndex,
                    isFirst: stepIndex === 0,
                    isLast: isLastStep(stepIndex),
                });
            }

            // then fade out current step
            currentStep.$element.transition('stop all');
            nextStep.$element.transition('stop all');

            currentStep.$element.transition('fade ' + directionOut + ' out', 100, function () {
                currentStep.$element.dimmer('hide');
                if (currentStepIndex === stepIndex) {
                    if (emitEvent) {
                        nextStep.$element.trigger('StepManager.Step.show', {
                            step: stepIndex,
                            isFirst: stepIndex === 0,
                            isLast: isLastStep(stepIndex),
                        });
                    }
                    // prevent queuing "show" when we rapidly go through more than one steps in sequence
                    // @todo return a promise and avoid this queue issue
                    nextStep.$element.transition('fade ' + directionIn + ' in', 500);
                }
                updateProgressToStep(stepIndex);
            });
        }

        // finally, set the new state
        currentStepIndex = parseInt(stepIndex);
    }

    /**
     * Hide all steps
     */
    function hideAllSteps() {
        steps.forEach(function (step) {
            step.hide();
        });
    }

    /**
     * actually submit the form
     */
    function submitForm() {
        $('body').dimmer('show');
        _isFormSubmitting = true;
        $form
            .find('input[type=submit]')
            .disable();
        // we are intentionally bypassing submission event handler
        // because we use a handler to catch triggered submissions and "go to" next step
        // and on the final step we'll invoke submission through this function, submitForm()
        $form[0].submit();
    }

    /**
     * Validate a step, displaying all errors
     * Returns true when valid
     *
     * @returns {boolean}
     */
    function validate($stepElement) {
        $stepElement
            .find('input, select')
            .trigger('blur');

        return !$stepElement
            .find('.field.error')
            .length;
    }

    /**
     * select ui elements for module usage
     */
    function selectModuleElements() {
        $progress = $('#step-progress');
        $progressBar = $progress.find('.ui.progress');
        $stepLabels = $progress.find('.ui.label');
    }

    /**
     * Initialize all steps
     * Main entry point for the module
     *
     * Given an array of steps, wire up back/forth behavior
     * ex:
     *  init(['#step-1', '#step-2', '#summary']);
     *
     * Steps should have some shared HTML structure, see below for details
     *  - a button with a class '.js-next-step' to go to the next step
     *
     * @param {array} stepsToInitialize
     */
    function init(stepsToInitialize, formSelector) {
        selectModuleElements();
        updateProgressToStep(0);

        // set up flow between steps
        steps = stepsToInitialize
            .map(function (elSelector, index) {
                var $stepElement = $(elSelector);

                if (!$stepElement.length) {
                    return false;
                }

                return new Step(
                    elSelector,
                    $stepElement
                );
            }).filter(function (el) {
                return el !== false;
            });

        steps.forEach(function (step, index) {
            var $dimmer = $('<div/>')
                .addClass('ui dimmer inverted');
            var $loader = $('<div/>')
                .addClass('ui loader');
            $dimmer.append($loader);
            step.$element.append($dimmer);

            // when you click a "next" button on a step...
            var $controls = $('.js-next-step', step.$element);
            $controls.on('click', function (e) {
                e.preventDefault();
                var $nextStepButton = $(this);
                $nextStepButton.addClass('disabled');

                // @todo is this safe? or should I complete(i)?
                completeStep(index).then(function () {
                    goTo(index + 1);
                }).always(function () {
                    $nextStepButton.removeClass('disabled');
                });
            });
        });

        hideAllSteps();
        steps[0].show();

        $progress.on('click', '.ui.label', function (e) {
            var stepElementId = $(this).attr('data-step');
            var stepIndex = getIndexBySelector(stepElementId);

            // allow a user to go directly to a step if...
            if (
                // ...it is complete
                steps[stepIndex].isComplete
                // ...or if it the step they have yet-to-complete (right after the last completed step)
                || (
                    steps[stepIndex - 1]
                    && steps[stepIndex - 1].isComplete
                )
            ) {
                goTo(stepIndex);
            }
        });

        // select the form for usaged throughout module
        if (formSelector) {
            $form = $(formSelector).eq(0);
        } else {
            $form = steps[0].$element.parents('form').eq(0);
        }

        // capture rogue "submission" triggers, ie: from SemanticUI validation
        // we will manually run form.submit elsewhere
        $form.on('submit', function (e) {
            e.preventDefault();
            e.stopImmediatePropagation();
        });

        // capture "enter" presses and attempt to submit the step instead of the form
        $form.on('keydown', function (e) {
            if (e.which === 13) {
                e.preventDefault();
                e.stopImmediatePropagation();
                completeStep(currentStepIndex).then(function () {
                    goTo(currentStepIndex + 1);
                });
            }
        });

        $(window).on('popstate', handleBackClick);
    }

    function runThroughToLastCompletedStep() {
        // for every step remaining
        var remainingSteps = steps.length - 1 - currentStepIndex;
        // complete first step, creating a promises
        var promise = completeCurrentStep().then(function () {
            next();
        });

        // then prepare to complete all remaining steps
        while (remainingSteps > 0) {
            promise = promise.then(function () {
                if (isLastStep()) {
                    throw new Error('done');
                }
                return completeCurrentStep().then(function () {
                    next();
                });
            });
            remainingSteps--;
        }

    }

    /**
     * Attempt to complete a step
     * Runs any completion conditions
     * Then marks a step as "completed" if the conditions pass
     *
     * If valid, marks step as complete and goes to next step
     */
    function completeStep(stepIndex) {
        // @todo - switch to a native Promise
        var promiseMaker = $.Deferred();
        var isValid = validate(steps[stepIndex].$element);

        if (isValid) {
            // if there is are completion conditions
            // a completion condition should return true to pass
            // or false to fail completion
            // it can return a promise for async functionality
            var promises = steps[stepIndex].completionConditions.map(function (fn) {
                return fn();
            });

            // @todo Promise.all
            $.when(...promises).then(function () {
                var results = Array.from(arguments);

                // @todo polyfill Array.from
                if (typeof results === 'undefined' || results.every(function (el) {
                    return !!el;
                })) {
                    markComplete(stepIndex);
                    promiseMaker.resolve(true);
                } else {
                    promiseMaker.reject('Failed completion conditions');
                }
            }, function (err) {
                promiseMaker.reject('Failed promise');
            });
        } else {
            promiseMaker.reject('Failed validation');
        }

        return promiseMaker.promise();
    }

    function completeCurrentStep() {
        return completeStep(currentStepIndex);
    }

    /**
     * Mark a step as "complete"
     *
     * @param stepIndex
     */
    function markComplete(stepIndex) {
        steps[stepIndex].isComplete = true;
    }

    /**
     * Handler fn for when a user clicks their "back" button
     * @param e
     */
    function handleBackClick(e) {
        goTo(e.originalEvent.state && e.originalEvent.state.step || 0, false);
    }

    function prev() {
        goTo(currentStepIndex - 1);
    }

    function next() {
        goTo(currentStepIndex + 1);
    }

    function getIndexBySelector(stepSelector) {
        return steps.indexOf(steps.find(function (el) {
            return el.id === stepSelector;
        }));
    }

    /**
     * Add a completion "condition" to a step
     * Expects a Function that returns a Promise
     * If the promise is fulfilled successfully and returns a truthy value, then the step can be completed
     *
     * @param stepSelector
     * @param fn
     */
    function addCompletionCondition(stepSelector, fn) {
        var stepIndex = getIndexBySelector(stepSelector);
        steps[stepIndex].completionConditions.push(fn);
    }

    /**
     * go to the last step
     * optionally "validate" each step before going to the last step
     * @param validate
     */
    function last(validate) {
        validate = typeof validate === 'boolean' ? validate : true;

        if (validate) {
            steps.slice(currentStepIndex).reduce(function (prom, step, i) {
                if (i === steps.length - 1) {
                    // last step, dont attempt
                    return prom;
                }

                return prom.then(function () {
                    next();
                    return completeCurrentStep();
                }, function () {
                    // step failed, probably validation, throw an error to kill the other promises
                    throw new Error('Bad times in promise high');
                });
            }, completeStep(currentStepIndex));
        } else {
            goTo(steps.length - 1);
        }
    }

    function isLastStep(stepIndex) {
        stepIndex = typeof stepIndex !== 'number' ? currentStepIndex : stepIndex;
        return stepIndex === steps.length - 1;
    }

    /**
     * Public interface for the module
     */
    return {
        init: init,
        next: next,
        prev: prev,
        last: last,
        isLastStep: isLastStep,
        completeCurrentStep: completeCurrentStep,
        addCompletionCondition: addCompletionCondition,
        runThroughToLastCompletedStep: runThroughToLastCompletedStep,
    };
});

