require([
    "jquery",
    "services",
    //"smoothscroll-polyfill"
], function($) {
    /**
     * default top offset
     *
     * @type {number}
     */
    var defaultOffset = 0;

    /**
     * When in a mobile device we should adjust the scroll (top) by the header bar
     *
     * @type {number}
     */
    var mobileOffset = 0;

    /**
     * get the base offset for all scrollTo animations
     *
     * @returns {number}
     */
    function getOffset() {
        var offset = defaultOffset;

        // give a little extra bump on mobile
        if (CH && CH.isMobile) {
            offset += mobileOffset;
        }

        return offset;
    }

    /**
     * scroll to a vertical position in the page
     *
     * @param {number} top
     */
    CH.scrollTo = function (top) {
        window.scroll({
            top:  parseInt(top) - getOffset(),
            left: 0,
            behavior: 'smooth'
        });
    };

    /**
     * Scroll viewport to an element in the page
     *
     */
    CH.scrollToElement = function (element) {
        if (element && element.scrollIntoView) {
            element.scrollIntoView({
                block: 'start',
                behavior: 'smooth'
            });
        } else if (typeof element.get === 'function') {
            // backfill jquery nonsense
            element.get(0).scrollIntoView({
                block: 'start',
                behavior: 'smooth'
            });
        }
    }
});

define("ch_scrollto", function(){});

