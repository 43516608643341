define('ch_email_wishlist',[
    "jquery",
    "ch_selectors",
	"ch_modal_window"
], function($) {
    $(function() {
        $._('email-wish-list').click(function(e) {
            if(e) e.preventDefault();
            var shareType = $(this).attr('data-js-share-type');

            if (shareType == 'course') {
                var course_id = $(this).attr('data-js-courseId');
                var course_url = wishlist.courses[course_id];
            }
            else if(shareType == 'wishlist') {
                var ModalWindowShareWishListObj = new ModalWindowShareWishList();
                ModalWindowShareWishListObj.hide();
            }

            var ModalWindowEmailWishListObj = new ModalWindowEmailWishList();
            ModalWindowEmailWishListObj.show({
                onShow: function () {
                    var emailWishListWindow = ModalWindowEmailWishListObj.window;

                    $._('type').val(shareType);
                    if (shareType == 'course') {
                        $._('title', emailWishListWindow).val('The more the merrier - share this class with your friends');
                        $._('message', emailWishListWindow).val('Hey, I picked out a class on CourseHorse I plan on taking sometime soon. Care to join in? ' + location.protocol + '//' + CH.host.web + course_url);
                    }
                    else if(shareType == 'wishlist') {
                        $._('title', emailWishListWindow).val('Email your wishlist to a friend');
                        $._('message', emailWishListWindow).val('Hey, I picked out a few classes on CourseHorse I plan on taking sometime soon. Care to join in? ' + location.protocol + '//' + CH.host.web + '/wishlist?identity=' + wishlist.identity);
                    }
                }
            });
        });
    });
});

