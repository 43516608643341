// In order to load Google Maps API asynchronously we need to specify a callback function to the script loader (see app.js)
CH.initMaps = function() {
    require([
        "jquery",
        'modals',
        "ch_selectors",
        "ch_popup"
    ], function($, modals) {
        function renderMap(containerCanvas) {
            var marker;
            var point = new google.maps.LatLng(CH.map.latitude, CH.map.longitude);
            var mapOptions = {
                zoom: CH.map.zoom,
                center: point,
                mapTypeControl: false,
                mapTypeId: google.maps.MapTypeId.ROADMAP
            };
            var map = new google.maps.Map(containerCanvas.get(0), mapOptions);

            if (!CH.map.isDefault) {
                marker = new google.maps.Marker({
                    position: point,
                    title: CH.map.title
                });
                marker.setMap(map);
            }
        }
        $(function () {
            if (CH.isNewSemanticStyle) {
                const mapElements = document.querySelectorAll('.js-google-map');
                mapElements.forEach((el) => {
                    el.addEventListener('click', (e) => {
                        //var ModalWindowMapPopupObj = new ModalWindowMapPopup();
                        // ModalWindowMapPopupObj.show({
                        //     onShow: function() {
                        //         renderMap($('.container' + ' #map-canvas').addClass('loading'))
                        //     }
                        // });
                        modals.getModalElement('map-popup', 'wish-list-signup-window-new-semantic').then((modalEl) => {
                            const form = modalEl.querySelector('form');

                            form.onsubmit = function(e) {
                                wishlistSignupFormHandler(e, courseId);
                                modals.closeModal(modalEl);
                            }

                            modals.showModal(modalEl, {
                                enableFacebook: true
                            });

                        });
                    });
                });
            } else {
                $._('google_map').popUp({
                    type: 'tooltip',
                    title: 'Class Location',
                    content: '<div id="map-canvas" style="width: 496px; height: 486px;"></div>',
                    position:'center',
                    on: 'click',
                    keepAlive: true,
                    cssClass: 'map-tooltip',
                    showCorner: false,
                    maxWidth: 540,
                    modal: true,
                    single: true,
                    tooltipTopOffset: 100,
                    closeByClickOutside: true,
                    afterRender: function(targetElement) {
                        renderMap($('#' + targetElement.attr('popup_id') + ' #map-canvas').addClass('loading'))
                    }
                });
            }
        });
    });
};

define('ch_maps',["optional!google_maps"], function() {});

