define('ch_search',['ch_utils'], function ({ debounce }) {
    // initialize the view
    if (!window.Filters) {
        window.Filters = {};
    }

    // @todo make them ids
    const searchForm = document.querySelector('.js-search-form');
    const searchField = document.querySelector('.js-search-by-keyword input');
    const resultsElement = document.getElementById('header-search-results');
    const maxResult = 10;

    const mobileSearchButton = document.getElementById('js-mobile-search');
    const mobileSearchForm = document.getElementById('mobile-search-section');

    const removedSearchTermsExpr = new RegExp([
        'class',
        'classes',
        'course',
        'courses',
        'training',
        'workshop',
    ].map((word) => `\\b${word}\\b`).join('|'), 'ig');

    const debouncedRenderSuggestions = debounce(renderSuggestions, 200);

    // the "exact match" is a shortcut to link directly to a category
    // instead of submitting a keyword search upon submission
    let exactCategoryMatch;

    // deal with displaying the mobile search field as well...
    if (mobileSearchButton) {
        mobileSearchButton.addEventListener('click', () => {
            mobileSearchForm.toggleAttribute('hidden');
        });
    }

    // set defaults
    if (searchField) {
        searchField.value = Filters.kw || '';

        // @todo debounce
        // @todo fetch polyfill
        searchField.addEventListener('input', function (e) {
            const value = searchField.value.replace(removedSearchTermsExpr, '');

            exactCategoryMatch = null;

            debouncedRenderSuggestions(value);
        });
    }

    if (searchForm) {
        // when a user submits their search term in full
        searchForm.addEventListener('submit', function (e) {
            if (exactCategoryMatch && exactCategoryMatch.url) {
                e.preventDefault();
                window.location = exactCategoryMatch.url;
                return;
            }
            else {
                e.preventDefault();
                const value = searchField.value.replace(removedSearchTermsExpr, '');

                // Try one more last before submitting:
                renderSuggestions(value).then(() => {
                    if (exactCategoryMatch && exactCategoryMatch.url) {
                        window.location = exactCategoryMatch.url;
                        return;
                    } else {
                        searchForm.submit();
                    }
                })
            }

            ga(
                'send',
                'event',
                'Search Tracking',
                'Keyword Submitted',
                searchField.value,
                { nonInteraction: false },
            );
        });

        // when a user clicks a thing in the result list
        searchForm.addEventListener('click', function (e) {
            if (e.target.classList.contains('ui-menu-item-wrapper')) {
                ga(
                    'send',
                    'event',
                    'Search Tracking',
                    'Suggestion Selected',
                    e.target.dataset ? e.target.dataset.type : 'unknown',
                    { nonInteraction: false },
                );
            }
        });

        searchForm.addEventListener('focus', function (e) {
            if (target.matches('input')) {
                ga(
                    'send',
                    'event',
                    'Search Tracking',
                    'Search Field Focused',
                    null,
                    { nonInteraction: false },
                );
            }
        });
    }

    async function renderSuggestions(value) {
        if (value && value.trim().length > 2) {
            const data = await fetchSearchSuggestions(value)

            ga('send', 'event', 'Search Tracking', 'Autocomplete Results Returned', `${data.data.length} Results`, { nonInteraction: true });

            const valueHasSpaces = !!value.match(/\s/g);
            const valueEndsInS = value.toLowerCase().endsWith('s');
            const valueHasAndWord = !!value.match(/\sand\s/g);
            resultsElement.innerHTML = '';
            let result = '';

            const categories = data.data.filter((el) => el.section === 'Category');
            const classes = data.data.filter((el) => el.section === 'Course');
            const schools = data.data.filter((el) => el.section === 'School');

            // Try search suggestion request without spaces if no results are returned. I.e. try "sketchup" if the user types "sketch up" with no results.
            if (valueHasSpaces && data.data.length === 0) {
                return await renderSuggestions(value.replace(/\s/g, ''));
            }

            // Try substituting " and " with " & " if there are no categories
            if (valueHasAndWord && categories.length === 0) {
                return await renderSuggestions(value.replace(/\sand\s/g, ' & '));
            }

            // Try again without last s if no categories ("singular" version of search term)
            if (valueEndsInS && categories.length === 0) {
                return await renderSuggestions(value.slice(0, -1));
            }

            if (data.data.length === 0) {
                result += 'Press "enter" to search by keyword.';
            } else {
                result += '<ul class="ui-menu ui-widget ui-widget-content ui-autocomplete ui-front">';

                data.data.splice(maxResult);


                if (categories.length) {
                    result += '<li class="ui-autocomplete-section">Categories</li>';
                    result += categories.reduce((prev, el) => `${prev}<li class="ui-menu-item">
                        <a class="ui-menu-item-wrapper result" data-type="Category" href="${el.url}">
                            ${el.label}
                        </a>
                    </li>`, '');

                    exactCategoryMatch = categories.find((category) => category.label.toLowerCase() === value.trim().toLowerCase());


                    // Try again with "plural" version of search term (yeah, yeah, I know many plurals just add an 's')
                    if (!exactCategoryMatch) {
                        exactCategoryMatch = categories
                            .find((category) => category.label.toLowerCase() === value.trim().toLowerCase() + 's');
                    }
                } else {
                    exactCategoryMatch = null;
                }

                if (classes.length) {
                    result += '<li class="ui-autocomplete-section">Courses</li>';
                    result += classes.reduce((prev, el) => `${prev}<li class="ui-menu-item">
                        <a class="ui-menu-item-wrapper result" data-type="Course" href="${el.url}">
                            ${el.label}
                        </a>
                    </li>`, '')
                }

                if (schools.length) {
                    result += '<li class="ui-autocomplete-section">Schools</li>';
                    result += schools.reduce((prev, el) => `${prev}<li class="ui-menu-item">
                        <a class="ui-menu-item-wrapper result" data-type="School" href="${el.url}">
                            ${el.label}
                        </a>
                    </li>`, '');
                }

                result += '</ul>';
            }

            resultsElement.innerHTML = result;
            resultsElement.style.display = 'block';
        }
    }


    async function fetchSearchSuggestions(value) {
        return await fetch(`/course/filter/search-suggestions?search=${encodeURIComponent(value)}`)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then((data) => {
                if (data.success) {
                    return data
                } else {
                    return []
                }
            });
    }
});
